/** Allows any key of type `K` to be null in `T`: `T[K]` can be `null` */
export type Nullable<T, K extends keyof T = keyof T> = Omit<T, K> & { [P in K]: T[P] | null };

export type WithTypename<T extends { __typename?: unknown }> = T & Required<Pick<T, '__typename'>>;

export function isValue<T>(value: T | null | undefined): value is T {
	return value !== null && value !== undefined;
}

/** Enforce the `__typename` attribute to exist */
export function enforceTypename<T extends { __typename?: unknown }>(
	value: T
): value is WithTypename<T> {
	return enforceAttribute<T>('__typename')(value);
}

/**
 * Build and return a type predicate that checks
 * if `__typename` equals the value passed to `typenameValue`.
 *
 * @example isTypename('User')({ __typename: 'User' }) => true
 * @example isTypename('User')({ __typename: 'Post' }) => false
 */
export function enforceTypenameValue<
	T extends { __typename: string },
	Typename extends T['__typename']
>(typenameValue: Typename) {
	return (value: T): value is T & { __typename: Typename } => value.__typename === typenameValue;
}

/** Like the `Required<T>` utility type, but to disallow Null values. */
export type RequiredNotNull<T> = {
	[K in keyof T]: NonNullable<T[K]>;
};

/** Enforce an attribute `key` to be non-nullish */
export function enforceAttribute<T, K extends keyof T = keyof T>(key: K) {
	return (value: T): value is T & RequiredNotNull<Required<Pick<T, K>>> => {
		return !!value && isValue(value) && isValue(value[key]);
	};
}
