import type { InternalStructure } from '$lib/graphql/generated';
import { isValue } from '$lib/helpers';
import { InMemoryCache, type StoreObject } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { HttpLink } from '@apollo/client/link/http';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { sha256 } from 'crypto-hash';
import { API_BASE_URL } from './vite-constants';

const httpLink = new HttpLink({
	uri: API_BASE_URL,
	credentials: 'include',
});
const linkChain = createPersistedQueryLink({ sha256, useGETForHashedQueries: true }).concat(
	httpLink
);

const apolloCache = new InMemoryCache({
	canonizeResults: true,
	typePolicies: {
		Category: {
			keyFields: ['categoryGroupId', 'payoffId'],
		},
		UserMe: {
			keyFields: ['userId'],
			merge: true,
		},
		SquareBookingOrderBook: {
			keyFields: ['squareBookingId'],
		},
		SquareBookingStructure: {
			keyFields: ['squareBookingId'],
		},
		InternalStructure: {
			keyFields(structure) {
				if (!canBuildInternalStructureId(structure)) {
					return undefined;
				}
				let maturityString = 'null';
				if (structure.maturity && 'expirationDate' in structure.maturity) {
					maturityString = structure.maturity.expirationDate;
				} else if (structure.maturity) {
					maturityString = `${structure.maturity.expirationMonths}m`;
					if (isValue(structure.cap)) maturityString += `-${structure.cap}`;
				}
				return `InternalStructure:${structure.id}_${maturityString}`;
			},
			merge: true,
			fields: {
				squareBookingStructures: {
					merge: false,
				},
			},
		},
		LiveViewStructure: {
			keyFields: ['dateTimeRange', 'structureIdentifier'],
		},
		Price: { merge: true },
		StructureIdentifier: {
			keyFields: ['internalId', 'maturityDate', 'maturityMonths', 'cap'],
		},
		UnderlyingBasket: {
			fields: {
				interests: {
					keyArgs: [
						'categoryIds',
						'currencies',
						'strikes',
						'maturityDate',
						'maturityRelativeMonths',
					],
				},
			},
		},
		// Interest Book Pagination
		Query: {
			fields: {
				interests: offsetLimitPagination(),
				tradeGroups: offsetLimitPagination(),
				awayTradeGroups: offsetLimitPagination(),
				priceOuts: offsetLimitPagination(),
			},
		},
	},
});

function canBuildInternalStructureId(
	o: Readonly<StoreObject>
): o is Readonly<StoreObject & Pick<InternalStructure, 'id' | 'maturity' | 'cap'>> {
	return (
		typeof o === 'object' &&
		'id' in o &&
		(!('cap' in o) || !isValue(o.cap) || typeof o.cap === 'number')
	);
}

export const client = new ApolloClient({
	link: linkChain,
	cache: apolloCache,
});

export type ClientT = typeof client;

export default client;
