import type { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import client from '$lib/globals/client';
import type {
	ApolloQueryResult,
	ObservableQuery,
	WatchQueryOptions,
	QueryOptions,
	MutationOptions,
} from '@apollo/client';
import { readable } from 'svelte/store';
import type { Readable } from 'svelte/store';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	Date: string;
	DateTime: string;
	EmailAddress: string;
	JSONObject: Record<string | number, unknown>;
	NonEmptySmallString: string;
	NonNegativeFloat: number;
	OptionalNonEmptySmallString: string;
	OptionalNonEmptyText: string;
	OptionalPasswordString: string;
	OptionalSmallString: string;
	OptionalUInt16: number;
	UInt16: number;
	UInt24: number;
	UInt32: number;
};

export type Activity = {
	__typename?: 'Activity';
	dateTimeRange: DateTimeRange;
	/** Structures that were edited on the given date. */
	structureIdentifiers?: Maybe<Array<StructureIdentifier>>;
};

export type AwayBroker = {
	__typename?: 'AwayBroker';
	displayName: Scalars['String'];
	id: Scalars['Int'];
};

export type AwayTrade = {
	__typename?: 'AwayTrade';
	/** The Away Trade Group to which this away trade belongs */
	awayTradeGroup?: Maybe<AwayTradeGroup>;
	buyer?: Maybe<Client>;
	buyerBank?: Maybe<Bank>;
	comments?: Maybe<Scalars['String']>;
	id: Scalars['Int'];
	price?: Maybe<Scalars['Float']>;
	seller?: Maybe<Client>;
	sellerBank?: Maybe<Bank>;
	size?: Maybe<Scalars['Float']>;
	tradedAt: Scalars['DateTime'];
};

export type AwayTradeGroup = {
	__typename?: 'AwayTradeGroup';
	/** When known, the away broker that made the trade */
	awayBroker?: Maybe<AwayBroker>;
	awayTrades?: Maybe<Array<AwayTrade>>;
	/** Comments on the trade group. */
	comments?: Maybe<Scalars['String']>;
	createdAt: Scalars['DateTime'];
	creator?: Maybe<User>;
	id: Scalars['Int'];
	price?: Maybe<Scalars['Float']>;
	size?: Maybe<Scalars['Float']>;
	/** The structure to which this away trade group relates */
	structure?: Maybe<InternalStructure>;
};

export type AwayTradeGroupUpdate = {
	/**
	 * The ID of the broker that made the interest.
	 * Leave unspecified to leave unchanged, or set to null to clear.
	 */
	awayBrokerDisplayName?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	/** The ID of the Away Trade Group to update. */
	awayTradeGroupId: Scalars['Int'];
	comments?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	/** Info to match structure to which this interest relates. Keep null to leave unchanged. */
	newStructureCompositeIndex?: InputMaybe<StructureIndexInput>;
	/**
	 * Leave unspecified to leave unchanged.
	 * Set to null to clear.
	 * Will not be recomputed from the updated away trades.
	 */
	totalPrice?: InputMaybe<Scalars['Float']>;
	/**
	 * Leave unspecified to leave unchanged.
	 * Set to null to clear.
	 * Will not be recomputed from the updated away trades.
	 */
	totalSize?: InputMaybe<Scalars['Float']>;
	/**
	 * The away trades to update with this group. Keep null to leave unchanged.
	 * If set, any trade IDs not in this list will be deleted.
	 */
	updatedAwayTrades?: InputMaybe<Array<AwayTradeUpdate>>;
};

/**
 * Data to patch an away trade.
 * To create a new away trade, leave the ID field blank and match the NewAwayTrade interface.
 *
 * Any unspecified field will be left unchanged.
 * Setting a field to null will delete it if the field is optional.
 * Setting a required field to null is the same as leaving it unspecified.
 */
export type AwayTradeUpdate = {
	buyerBankId?: InputMaybe<Scalars['Int']>;
	buyerClientId?: InputMaybe<Scalars['Int']>;
	comments?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	/** If provided, the ID of the trade to update. If not provided, the trade will be created. */
	id?: InputMaybe<Scalars['Int']>;
	price?: InputMaybe<Scalars['Float']>;
	sellerBankId?: InputMaybe<Scalars['Int']>;
	sellerClientId?: InputMaybe<Scalars['Int']>;
	size?: InputMaybe<Scalars['Float']>;
	/** The date at which this trade happened. Keep null to leave unchanged. */
	tradedAt?: InputMaybe<Scalars['DateTime']>;
};

export type Bank = {
	__typename?: 'Bank';
	clients?: Maybe<Array<Client>>;
	displayName: Scalars['String'];
	id: Scalars['Int'];
	legalEntities?: Maybe<Array<BankLegalEntity>>;
};

export type BankLegalEntity = {
	__typename?: 'BankLegalEntity';
	bank?: Maybe<Bank>;
	country?: Maybe<Country>;
	id: Scalars['Int'];
	legalName?: Maybe<Scalars['String']>;
};

export type Category = {
	__typename?: 'Category';
	/** If true, this category can be sent as a price out to Blast Lists. */
	canBeSentAsEmail: Scalars['Boolean'];
	categoryGroup?: Maybe<CategoryGroup>;
	categoryGroupId: Scalars['Int'];
	/** If true, this category shouldn't be available for selection in Square Broking */
	disabled: Scalars['Boolean'];
	/**
	 * Specifies how structures from the category should be formatted.
	 * Available placeholders are:
	 * - {maturity_date} - The absolute maturity with the day as a two digit number,
	 *                     the month as a three-letter month abbreviation with title case (e.g. Jan, Feb,... Dec),
	 *                     and the year as a two digit year.
	 * - {maturity_date_short} - Like {maturity_date}, but without the day.
	 * - {maturity_rel} - The relative maturity as a number months + years (e.g. 6m5y).
	 * - {underlyings_parens} - A comma-separated list of the underlying names between parenthesis.
	 * - {underlyings_dashed} - A dash-separated list of the underlying names.
	 * - {underlyings_dashed_q_currency} - Equivalent to {underlyings_dashed}q{currency}, but easier to parse.
	 * - {currency} - The currency of the structure, in uppercase.
	 * - {strikes} - The strikes of the structure, in percent (i.e. 80 rather than 0.8),
	 *               separated by slashes if multi-strikes or cap. Equal to ATM if strike == 100%.
	 */
	formatHint: Scalars['String'];
	/**
	 * A list of maturities that are known to be used on at least one structure in this category.
	 * @deprecated Use knownUnderlyingDates instead.
	 */
	knownMaturities?: Maybe<KnownMaturities>;
	knownUnderlyingDates?: Maybe<Array<KnownUnderlyingDates>>;
	payoff?: Maybe<Payoff>;
	payoffId: Scalars['Int'];
};

export type CategoryGroup = {
	__typename?: 'CategoryGroup';
	/** Categories that are part of this category group. */
	categories?: Maybe<Array<Category>>;
	/** A name to describe the category group. */
	displayName: Scalars['String'];
	id: Scalars['Int'];
};

export type CategoryNotification = {
	__typename?: 'CategoryNotification';
	categoryGroupId: Scalars['Int'];
	payoffId: Scalars['Int'];
	/** The Firestore collection path to recalls of that category. */
	recallsCollectionPath?: Maybe<Scalars['String']>;
	/** The Firestore collection path to updates for this category. */
	updatesCollectionPath?: Maybe<Scalars['String']>;
};

/** A Square client, working at a bank. Imported from Square Booking. */
export type Client = {
	__typename?: 'Client';
	backOfficeEmail?: Maybe<Scalars['String']>;
	/** The bank where this client works. */
	bank?: Maybe<Bank>;
	/** The date of birth of this client, if known. */
	birthDate?: Maybe<Scalars['Date']>;
	/** General comments about this client. */
	comments?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
	/** When the client was first imported to Square Broking. */
	firstImportedAt: Scalars['DateTime'];
	firstName?: Maybe<Scalars['String']>;
	id: Scalars['Int'];
	/** When true, this client shouldn't be used anymore. */
	isDisabled: Scalars['Boolean'];
	lastName?: Maybe<Scalars['String']>;
	middleOfficeEmail?: Maybe<Scalars['String']>;
	/** When the client was created in Square Booking. */
	squareBookingCreatedAt: Scalars['DateTime'];
	/** When the client was last updated in Square Booking. */
	squareBookingUpdatedAt: Scalars['DateTime'];
};

export type ClientUpdate = {
	newBackOfficeEmail?: InputMaybe<Scalars['EmailAddress']>;
	newBirthDate?: InputMaybe<Scalars['Date']>;
	newComments?: InputMaybe<Scalars['OptionalNonEmptyText']>;
	newFirstName?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	newLastName?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	newMiddleOfficeEmail?: InputMaybe<Scalars['EmailAddress']>;
	/**
	 * Set to true to mark the client as disabled.
	 * Set to false to mark the client as enabled.
	 * Leave empty to leave the client's status unchanged.
	 */
	setDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Country = {
	__typename?: 'Country';
	displayName: Scalars['String'];
	id: Scalars['Int'];
	shortName: Scalars['String'];
};

export enum Currency {
	Adp = 'ADP',
	Aed = 'AED',
	Afn = 'AFN',
	All = 'ALL',
	Amd = 'AMD',
	Ang = 'ANG',
	Aoa = 'AOA',
	Ars = 'ARS',
	Ats = 'ATS',
	Aud = 'AUD',
	Awg = 'AWG',
	Azm = 'AZM',
	Azn = 'AZN',
	Bam = 'BAM',
	Bbd = 'BBD',
	Bdt = 'BDT',
	Bef = 'BEF',
	Bgn = 'BGN',
	Bhd = 'BHD',
	Bif = 'BIF',
	Bmd = 'BMD',
	Bnd = 'BND',
	Bob = 'BOB',
	Brl = 'BRL',
	Bsd = 'BSD',
	Btn = 'BTN',
	Bwp = 'BWP',
	Byn = 'BYN',
	Byr = 'BYR',
	Bzd = 'BZD',
	Cad = 'CAD',
	Cdf = 'CDF',
	Cer = 'CER',
	Chf = 'CHF',
	Clf = 'CLF',
	Clp = 'CLP',
	Cnh = 'CNH',
	Cnt = 'CNT',
	Cny = 'CNY',
	Cop = 'COP',
	Cou = 'COU',
	Crc = 'CRC',
	Crs = 'CRS',
	Cup = 'CUP',
	Cve = 'CVE',
	Cyp = 'CYP',
	Czk = 'CZK',
	Dem = 'DEM',
	Djf = 'DJF',
	Dkk = 'DKK',
	Dop = 'DOP',
	Dzd = 'DZD',
	Ecs = 'ECS',
	Eek = 'EEK',
	Egd = 'EGD',
	Egp = 'EGP',
	Ern = 'ERN',
	Esp = 'ESP',
	Etb = 'ETB',
	Eua = 'EUA',
	Eur = 'EUR',
	Fim = 'FIM',
	Fjd = 'FJD',
	Fkp = 'FKP',
	Frf = 'FRF',
	Gbp = 'GBP',
	Gel = 'GEL',
	Ghc = 'GHC',
	Ghs = 'GHS',
	Gip = 'GIP',
	Gld = 'GLD',
	Gmd = 'GMD',
	Gnf = 'GNF',
	Grd = 'GRD',
	Gtq = 'GTQ',
	Gyd = 'GYD',
	Hkd = 'HKD',
	Hnl = 'HNL',
	Hrk = 'HRK',
	Htg = 'HTG',
	Huf = 'HUF',
	Idr = 'IDR',
	Iep = 'IEP',
	Ils = 'ILS',
	Inr = 'INR',
	Iqd = 'IQD',
	Irr = 'IRR',
	Isk = 'ISK',
	Itl = 'ITL',
	Jep = 'JEP',
	Jmd = 'JMD',
	Jod = 'JOD',
	Jpy = 'JPY',
	Kes = 'KES',
	Kgs = 'KGS',
	Khr = 'KHR',
	Kmf = 'KMF',
	Kpw = 'KPW',
	Krw = 'KRW',
	Kwd = 'KWD',
	Kyd = 'KYD',
	Kzt = 'KZT',
	Lak = 'LAK',
	Lbp = 'LBP',
	Lkr = 'LKR',
	Lrd = 'LRD',
	Lsl = 'LSL',
	Ltl = 'LTL',
	Luf = 'LUF',
	Lvl = 'LVL',
	Lyd = 'LYD',
	Mad = 'MAD',
	Mdl = 'MDL',
	Mga = 'MGA',
	Mgf = 'MGF',
	Mkd = 'MKD',
	Mmk = 'MMK',
	Mnt = 'MNT',
	Mop = 'MOP',
	Mro = 'MRO',
	Mru = 'MRU',
	Mtl = 'MTL',
	Mur = 'MUR',
	Mvr = 'MVR',
	Mwk = 'MWK',
	Mxn = 'MXN',
	Myr = 'MYR',
	Mzm = 'MZM',
	Mzn = 'MZN',
	Nad = 'NAD',
	Ngn = 'NGN',
	Nid = 'NID',
	Nio = 'NIO',
	Nlg = 'NLG',
	Nok = 'NOK',
	Npr = 'NPR',
	Nzd = 'NZD',
	Omr = 'OMR',
	Pab = 'PAB',
	Pen = 'PEN',
	Pgk = 'PGK',
	Php = 'PHP',
	Pkr = 'PKR',
	Pln = 'PLN',
	Pte = 'PTE',
	Pyg = 'PYG',
	Qar = 'QAR',
	Rol = 'ROL',
	Ron = 'RON',
	Rsd = 'RSD',
	Rub = 'RUB',
	Rwf = 'RWF',
	Sar = 'SAR',
	Sbd = 'SBD',
	Scr = 'SCR',
	Sdd = 'SDD',
	Sdg = 'SDG',
	Sdp = 'SDP',
	Sdr = 'SDR',
	Sek = 'SEK',
	Sgd = 'SGD',
	Sit = 'SIT',
	Skk = 'SKK',
	Sll = 'SLL',
	Slv = 'SLV',
	Sos = 'SOS',
	Spl = 'SPL',
	Srd = 'SRD',
	Srg = 'SRG',
	Ssp = 'SSP',
	Std = 'STD',
	Stn = 'STN',
	Svc = 'SVC',
	Syp = 'SYP',
	Szl = 'SZL',
	Thb = 'THB',
	Tho = 'THO',
	Tjs = 'TJS',
	Tmm = 'TMM',
	Tmt = 'TMT',
	Tnd = 'TND',
	Top = 'TOP',
	Tpe = 'TPE',
	Trl = 'TRL',
	Try = 'TRY',
	Ttd = 'TTD',
	Tvd = 'TVD',
	Twd = 'TWD',
	Tzs = 'TZS',
	Uah = 'UAH',
	Udi = 'UDI',
	Ugx = 'UGX',
	Usd = 'USD',
	Uvr = 'UVR',
	Uyi = 'UYI',
	Uyu = 'UYU',
	Uyw = 'UYW',
	Uzs = 'UZS',
	Veb = 'VEB',
	Vee = 'VEE',
	Vef = 'VEF',
	Ves = 'VES',
	Vnd = 'VND',
	Vuv = 'VUV',
	Wst = 'WST',
	X0S = 'X0S',
	X1S = 'X1S',
	X2S = 'X2S',
	X3S = 'X3S',
	X4S = 'X4S',
	X5S = 'X5S',
	X6S = 'X6S',
	X7S = 'X7S',
	X8S = 'X8S',
	X9S = 'X9S',
	Xad = 'XAD',
	Xaf = 'XAF',
	Xag = 'XAG',
	Xal = 'XAL',
	Xao = 'XAO',
	Xas = 'XAS',
	Xau = 'XAU',
	Xav = 'XAV',
	Xbi = 'XBI',
	Xbn = 'XBN',
	Xbs = 'XBS',
	Xbt = 'XBT',
	Xbw = 'XBW',
	Xcd = 'XCD',
	Xcs = 'XCS',
	Xcu = 'XCU',
	Xdg = 'XDG',
	Xdh = 'XDH',
	Xdo = 'XDO',
	Xdr = 'XDR',
	Xdt = 'XDT',
	Xeo = 'XEO',
	Xet = 'XET',
	Xeu = 'XEU',
	Xlc = 'XLC',
	Xli = 'XLI',
	Xlu = 'XLU',
	Xma = 'XMA',
	Xmr = 'XMR',
	Xni = 'XNI',
	Xof = 'XOF',
	Xpb = 'XPB',
	Xpd = 'XPD',
	Xpf = 'XPF',
	Xpt = 'XPT',
	Xra = 'XRA',
	Xrh = 'XRH',
	Xri = 'XRI',
	Xrp = 'XRP',
	Xru = 'XRU',
	Xsn = 'XSN',
	Xso = 'XSO',
	Xsu = 'XSU',
	Xth = 'XTH',
	Xuc = 'XUC',
	Xun = 'XUN',
	Xzc = 'XZC',
	Xzi = 'XZI',
	Yer = 'YER',
	Zar = 'ZAR',
	Zmk = 'ZMK',
	Zmw = 'ZMW',
	Zwd = 'ZWD',
	Zwf = 'ZWF',
	Zwl = 'ZWL',
	Zwn = 'ZWN',
	Zwr = 'ZWR',
}

export type CurrencyInfo = {
	__typename?: 'CurrencyInfo';
	alphabeticCode: Currency | `${Currency}`;
	enabled: Scalars['Boolean'];
};

export type DateTimeRange = {
	__typename?: 'DateTimeRange';
	end: Scalars['DateTime'];
	start: Scalars['DateTime'];
};

export type EmailBlastList = {
	__typename?: 'EmailBlastList';
	displayName: Scalars['String'];
	emailGroups?: Maybe<Array<EmailGroup>>;
	id: Scalars['Int'];
};

export type EmailGroup = {
	__typename?: 'EmailGroup';
	bank?: Maybe<Bank>;
	createdAt: Scalars['DateTime'];
	emails?: Maybe<Array<KnownEmail>>;
	groupName: Scalars['String'];
	id: Scalars['Int'];
	updatedAt: Scalars['DateTime'];
};

export type ExportData = {
	__typename?: 'ExportData';
	awayTradeGroups?: Maybe<Array<AwayTradeGroup>>;
	interests?: Maybe<Array<Interest>>;
	tradeGroups?: Maybe<Array<TradeGroup>>;
};

export type ExportDataAwayTradeGroupsArgs = {
	end?: InputMaybe<Scalars['DateTime']>;
	limit?: Scalars['Int'];
	start?: InputMaybe<Scalars['DateTime']>;
};

export type ExportDataInterestsArgs = {
	end?: InputMaybe<Scalars['DateTime']>;
	limit?: Scalars['Int'];
	start?: InputMaybe<Scalars['DateTime']>;
};

export type ExportDataTradeGroupsArgs = {
	end?: InputMaybe<Scalars['DateTime']>;
	limit?: Scalars['Int'];
	start?: InputMaybe<Scalars['DateTime']>;
};

export type FirebaseCustomTokenResponse = {
	__typename?: 'FirebaseCustomTokenResponse';
	/** A list of authorized notifications per category. */
	categoryNotifications?: Maybe<Array<CategoryNotification>>;
	/** The custom token. */
	customToken: Scalars['String'];
	/** Describes the Firebase environment. */
	environment: Scalars['String'];
	/**
	 * A list of authorized notification paths.
	 * @deprecated Use the 'categoryNotifications' field instead.
	 */
	notificationPaths: Array<Scalars['String']>;
};

export type FixedStrikeGroup = {
	__typename?: 'FixedStrikeGroup';
	/** The ID of the Strike Group. */
	groupId: Scalars['Int'];
	/** The actual group of strikes. */
	strikes: Array<Scalars['Float']>;
};

export type Interest = {
	__typename?: 'Interest';
	ask?: Maybe<Price>;
	awayBroker?: Maybe<AwayBroker>;
	bid?: Maybe<Price>;
	comments?: Maybe<Scalars['String']>;
	createdAt: Scalars['DateTime'];
	/** The user that created this interest. */
	creator?: Maybe<User>;
	/** If true, this interest has been soft-deleted. */
	disabled: Scalars['Boolean'];
	id: Scalars['Int'];
	/** The bank that manifested the interest */
	initiatorBank?: Maybe<Bank>;
	/** The client that manifested the interest (belongs to the bank at the time of manifestation) */
	initiatorClient?: Maybe<Client>;
	interestDate: Scalars['Date'];
	isAway: Scalars['Boolean'];
	/** The medium through which the interest was manifested */
	providerMedia?: Maybe<Scalars['String']>;
	/** The source of the interest */
	providerSource?: Maybe<Scalars['String']>;
	/** The structure to which this interest relates */
	structure?: Maybe<InternalStructure>;
	updatedAt: Scalars['DateTime'];
};

/** An interest book column to sort by */
export enum InterestBookColumnOrder {
	AskPrice = 'ASK_PRICE',
	AskSize = 'ASK_SIZE',
	BankName = 'BANK_NAME',
	BidPrice = 'BID_PRICE',
	BidSize = 'BID_SIZE',
	BlastListName = 'BLAST_LIST_NAME',
	BrokerName = 'BROKER_NAME',
	CategoryGroupName = 'CATEGORY_GROUP_NAME',
	ClientName = 'CLIENT_NAME',
	CreatedAt = 'CREATED_AT',
	CreatorName = 'CREATOR_NAME',
	Currency = 'CURRENCY',
	InterestDate = 'INTEREST_DATE',
	Maturity = 'MATURITY',
	PayoffName = 'PAYOFF_NAME',
	Strike = 'STRIKE',
	/**
	 * A special sorting option that sorts, in order, by
	 * Category, Maturity, Strike, Cap, and finally by Currency.
	 */
	Structure = 'STRUCTURE',
	TradePrice = 'TRADE_PRICE',
	TradeSize = 'TRADE_SIZE',
	UpdatedAt = 'UPDATED_AT',
}

export type InterestBookFiltering = {
	/** Filter by structure */
	structureIndex?: InputMaybe<StructureIndexInput>;
};

/** Describes a column and a direction to sort by */
export type InterestBookOrdering = {
	column: InterestBookColumnOrder | `${InterestBookColumnOrder}`;
	direction: OrderingDirection | `${OrderingDirection}`;
};

export type InterestBookSearchParams = {
	/** If set, only the elements matching the filtering will be returned. */
	filtering?: InputMaybe<InterestBookFiltering>;
	/** If not empty, a list of orderings to apply to the results. */
	ordering?: Array<InterestBookOrdering>;
	/**
	 * If not null and not empty, only the elements matching this search string will be returned.
	 * The fields that will be matched against are at the discretion of the resolver.
	 */
	searchString?: InputMaybe<Scalars['String']>;
};

/**
 * Data to patch an interest.
 *
 * Any unspecified field will be left unchanged in the interest.
 * Setting a field to null will delete it if the field is optional.
 * Setting a required field to null is the same as leaving it unspecified.
 */
export type InterestUpdate = {
	/** If set, indicates the initiator has seller interest (eventually with a price and a size) */
	ask?: InputMaybe<NewPrice>;
	/**
	 * The name (ID) of the broker that made the interest
	 * (only considered if 'isAway' is set and true).
	 * If the name is unknown, it will be created.
	 */
	awayBrokerDisplayName?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	/** If set, indicates the initiator has buyer interest (eventually with a price and a size) */
	bid?: InputMaybe<NewPrice>;
	comments?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	/** The ID of the bank that manifested the interest. Optional on away interests. */
	initiatorBankId?: InputMaybe<Scalars['Int']>;
	/** The ID of the client that manifested the interest. Should belong to the bank (but not checked). */
	initiatorClientId?: InputMaybe<Scalars['Int']>;
	/** The date to which this interest should be dated. */
	interestDate?: InputMaybe<Scalars['Date']>;
	/** The ID of the interest to update. */
	interestId: Scalars['Int'];
	/** Set to true to indicate that the interest is away, set to false to mark the interest as not-away. */
	isAway?: InputMaybe<Scalars['Boolean']>;
	providerMedia?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	providerSource?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	/** Info to match structure to which this interest relates. Leave null to keep unchanged. */
	structureCompositeIndex?: InputMaybe<StructureIndexInput>;
};

/**
 * An internal Square Broking Structure.
 * This structure is known in the database.
 */
export type InternalStructure = {
	__typename?: 'InternalStructure';
	/** The Away Trades that relate to this structure. */
	awayTradeGroups?: Maybe<Array<AwayTradeGroup>>;
	/** The cap of this structure, if any. */
	cap?: Maybe<Scalars['Float']>;
	/** The category this structure belongs to. */
	category?: Maybe<Category>;
	/** The date of creation of the structure, if it is known. */
	createdAt: Scalars['DateTime'];
	/** Returns all dates on which there is one price out or one interest for this structure. */
	datesOfInterest?: Maybe<Array<Scalars['Date']>>;
	id: Scalars['Int'];
	/**
	 * Returns all dates on which there is one interest for this structure.
	 * @deprecated Use datesOfInterest instead.
	 */
	interestDates?: Maybe<Array<Scalars['Date']>>;
	/** Interests that relate to this structure. */
	interests?: Maybe<Array<Interest>>;
	/** The maturity of this structure, if any. */
	maturity?: Maybe<Maturity>;
	/** The currency of this structure. */
	notionalCurrency: Currency | `${Currency}`;
	/** Price outs that relate to this structure. */
	priceOuts?: Maybe<Array<PriceOut>>;
	/** A list of Square Booking structures that match this structure. */
	squareBookingStructures?: Maybe<Array<SquareBookingStructure>>;
	/** The unordered strikes of this structure, or empty if no strike. */
	strikes?: Maybe<Array<Scalars['Float']>>;
	/** The underlying basket of this structure. */
	underlyingBasket?: Maybe<UnderlyingBasket>;
	/** The date of last update of the structure, if it is known. */
	updatedAt: Scalars['DateTime'];
};

/**
 * An internal Square Broking Structure.
 * This structure is known in the database.
 */
export type InternalStructureAwayTradeGroupsArgs = {
	beforeDate?: InputMaybe<Scalars['Date']>;
	limitLatest?: Scalars['Int'];
};

/**
 * An internal Square Broking Structure.
 * This structure is known in the database.
 */
export type InternalStructureDatesOfInterestArgs = {
	before?: InputMaybe<Scalars['Date']>;
	includeDisabled?: Scalars['Boolean'];
	includeNonPricedInterests?: Scalars['Boolean'];
	limit?: Scalars['Int'];
};

/**
 * An internal Square Broking Structure.
 * This structure is known in the database.
 */
export type InternalStructureInterestDatesArgs = {
	before?: InputMaybe<Scalars['Date']>;
	limit?: Scalars['Int'];
};

/**
 * An internal Square Broking Structure.
 * This structure is known in the database.
 */
export type InternalStructureInterestsArgs = {
	beforeDate?: InputMaybe<Scalars['Date']>;
	date?: InputMaybe<Scalars['Date']>;
	excludeNonPriced?: Scalars['Boolean'];
	excludePriced?: Scalars['Boolean'];
	includeDisabled?: Scalars['Boolean'];
	limitLatest?: Scalars['Int'];
};

/**
 * An internal Square Broking Structure.
 * This structure is known in the database.
 */
export type InternalStructurePriceOutsArgs = {
	dateTimeMidnight: Scalars['DateTime'];
	limitLatest?: Scalars['Int'];
};

export type KnownAbsoluteMaturities = {
	__typename?: 'KnownAbsoluteMaturities';
	knownDates?: Maybe<Array<Scalars['Date']>>;
};

export type KnownEmail = {
	__typename?: 'KnownEmail';
	/** A list of clients that use the same email address. */
	clientsUsingThisAddress?: Maybe<Array<Client>>;
	countOfClientsUsingThisAddress?: Maybe<Scalars['Int']>;
	email: Scalars['EmailAddress'];
	id: Scalars['Int'];
};

export type KnownMaturities = KnownAbsoluteMaturities | KnownRelativeMaturities;

export type KnownRelativeMaturities = {
	__typename?: 'KnownRelativeMaturities';
	knownMonthExpirations?: Maybe<Array<Scalars['Int']>>;
};

export type KnownUnderlyingDates = {
	__typename?: 'KnownUnderlyingDates';
	dates?: Maybe<Array<Scalars['Date']>>;
	/** The ID of the underlying basket that has these known maturities. */
	underlyingBasketId: Scalars['Int'];
};

export type LastPrint = AwayTrade | Trade;

export type LiveViewStructure = {
	__typename?: 'LiveViewStructure';
	/** Date Time range that was considered to build this Live View. */
	dateTimeRange: DateTimeRange;
	/** Banks that have manifested buyer interest. */
	interestedBuyers?: Maybe<Array<Bank>>;
	/** Banks that have manifested seller interest. */
	interestedSellers?: Maybe<Array<Bank>>;
	/** Banks that have manifested an interest, but with no specific way. */
	interestedWithoutWay?: Maybe<Array<Bank>>;
	/**
	 * The interest with the lowest Ask price on the last day with an Ask
	 * in the current Live View time range.
	 *
	 * If away is set to true, will consider Away interests instead.
	 */
	lastDayBestAskInterest?: Maybe<Interest>;
	/**
	 * The interest with the highest Bid price on the last day with a Bid
	 * in the current Live View time range.
	 *
	 * If away is set to true, will consider Away interests instead.
	 */
	lastDayBestBidInterest?: Maybe<Interest>;
	lastPrints?: Maybe<Array<LastPrint>>;
	/** The identifier of the structure to which this Live View corresponds. */
	structureIdentifier: StructureIdentifier;
};

export type LiveViewStructureLastDayBestAskInterestArgs = {
	away?: Scalars['Boolean'];
};

export type LiveViewStructureLastDayBestBidInterestArgs = {
	away?: Scalars['Boolean'];
};

export type LiveViewStructureLastPrintsArgs = {
	count?: Scalars['Int'];
};

/** A Maturity Date or a number of months to expiration. */
export type Maturity = MaturityDate | MaturityRelative;

export type MaturityDate = {
	__typename?: 'MaturityDate';
	expirationDate: Scalars['Date'];
};

export type MaturityRelative = {
	__typename?: 'MaturityRelative';
	expirationMonths: Scalars['Int'];
};

export enum MaturityType {
	Date = 'DATE',
	Duration = 'DURATION',
}

export type Mutation = {
	__typename?: 'Mutation';
	/** This endpoint removes all notifications from the Firestore. */
	cleanUpFirestore?: Maybe<Scalars['Boolean']>;
	/** Create a new away trade group. Returns the trade group if successful. */
	createAwayTradeGroup: AwayTradeGroup;
	/** Create a batch of away trade groups. Returns the trade groups that were successfully inserted, and nulls on errors. */
	createAwayTradeGroups: Array<Maybe<AwayTradeGroup>>;
	createBlastList: EmailBlastList;
	/** Create a new email group, optionally appending it to an existing blast list. */
	createEmailGroup: EmailGroup;
	/** Create a new interest. Returns the interest if successful. */
	createInterest: Interest;
	/** Create a batch of interests. Returns the interests that were successfully inserted, and nulls on errors. */
	createInterests: Array<Maybe<Interest>>;
	/** Create a Price Out, optionally sending it simultaneously. */
	createPriceOut: PriceOut;
	/** Create a new trade group. Returns the trade group if successful. */
	createTradeGroup: TradeGroup;
	/** Create a new user. Returns the user if successful. */
	createUser: User;
	/** Delete an away trade group. Returns true if successful. */
	deleteAwayTradeGroup: Scalars['Boolean'];
	deleteBlastList?: Maybe<Scalars['Boolean']>;
	deleteEmailGroup?: Maybe<Scalars['Boolean']>;
	/** Delete an interest. Returns true if successful. */
	deleteInterest: Scalars['Boolean'];
	/** Delete a Price Out and all associated records. */
	deletePriceOut: Scalars['Boolean'];
	/** Delete a trade group. Returns true if successful. */
	deleteTradeGroup: Scalars['Boolean'];
	/** Delete a user. Returns the deleted user ID if successful. */
	deleteUser?: Maybe<Scalars['Int']>;
	/**
	 * This mutation disables all interests before a given date (defaults to now).
	 *
	 * offsetDisableDateBy is the number of days to add to the interest Date to set the disable date.
	 * Can be negative.
	 */
	disablePastInterests: Scalars['Boolean'];
	/** Disable a user (meaning the user will not be able to log-in anymore). Returns the disabled user ID if successful. */
	disableUser?: Maybe<Scalars['Int']>;
	/** Mark a user as active. Returns the enabled user ID if successful. */
	enableUser?: Maybe<Scalars['Int']>;
	/** Perform Cookie-based authentication. */
	logIn: User;
	/** Log out from all authenticated sessions. Returns true if the user was logged in. */
	logOut?: Maybe<Scalars['Boolean']>;
	/**
	 * This mutation merges together all groups of underlyings
	 * that use the same underlyings (even if their order is different).
	 *
	 * Returns the number of underlyings that were merged,
	 * or null if there was an error.
	 * @deprecated This mutation is only a temporary patch-solution and will be removed in an upcoming release.
	 */
	mergeDuplicateUnderlyingGroups?: Maybe<Scalars['Int']>;
	/** Post an existing interest to Square Booking. Returns the interest. */
	postInterestToSquareBooking: Interest;
	/**
	 * Post an existing Trade Group to Square Booking. Returns the trade group.
	 *
	 * Defaults to registering the trade from the buy side.
	 * Use registerTradeFromSellSide to register the trade from the sell side.
	 */
	postTradeGroupToSquareBooking: TradeGroup;
	/** Manually trigger a notification on the given structure. */
	recallStructure?: Maybe<Scalars['Boolean']>;
	/**
	 * Replace a user's scopes.
	 * If userId is omitted, replaces the current user scopes.
	 * Returns true.
	 */
	replaceUserScopes?: Maybe<Scalars['Boolean']>;
	/**
	 * Request a password reset email for the given user.
	 * If ID is not provided, the current user will be used instead.
	 *
	 * Return a String of the email used for the reset.
	 * If the user does not exist, the request will be ignored.
	 */
	requestPasswordReset?: Maybe<Scalars['EmailAddress']>;
	/** Send a Price Out. */
	sendPriceOut: PriceOut;
	/** Get a JWT signature to access the Dashboard. User must have Dashboard rights. */
	signDashboardAuth: Scalars['String'];
	/** Get a Firebase auth token to access notifications. User must have Middleoffice rights. */
	signFirebaseAuth: FirebaseCustomTokenResponse;
	/** Replaces stored settings for the current user, or deletes them with 'null'. */
	storeNewSettings?: Maybe<Scalars['Boolean']>;
	/**
	 * Using a user email and a token, reset the user's password.
	 *
	 * Return true if the change was successful.
	 */
	submitNewPassword?: Maybe<Scalars['Boolean']>;
	/** Synchronize banks, countries, and legal entities. Returns true if successful. */
	syncBanks?: Maybe<Scalars['Boolean']>;
	/** Synchronize Bank clients. Returns true if successful. */
	syncClients?: Maybe<Scalars['Boolean']>;
	/**
	 * Synchronize structures from Square Booking. Returns true if successful.
	 *
	 * If 'forceAll' is set to true, all structures will be synchronized.
	 * Otherwise, only structures that have been updated on Square Booking
	 * since the last synchronization will be synchronized.
	 */
	syncStructures?: Maybe<Scalars['Boolean']>;
	/**
	 * Synchronize Underlyings from Square Booking. Returns true if successful.
	 *
	 * If 'forceAll' is set to true, all Underlyings will be synchronized.
	 * Otherwise, only Underlyings that have been updated since the last synchronization will be synchronized.
	 */
	syncUnderlyings?: Maybe<Scalars['Boolean']>;
	/** Mark an interest as disabled or enabled. Returns the interest. */
	toggleInterestDisabledStatus: Interest;
	/** Update an away trade group. Returns the updated trade group. */
	updateAwayTradeGroup: AwayTradeGroup;
	/** Update a bank client. Returns the updated client. */
	updateBankClient: Client;
	updateBlastList: EmailBlastList;
	updateEmailGroup: EmailGroup;
	/** Update an interest. Returns the updated interest if successful. */
	updateInterest: Interest;
	/** Update a Price Out. Returns the updated Price Out. */
	updatePriceOut: PriceOut;
	/** Update a trade group. Returns the updated trade group. */
	updateTradeGroup: TradeGroup;
	/** Update data about a user. Returns the updated user ID if successful. */
	updateUser?: Maybe<Scalars['Int']>;
};

export type MutationCreateAwayTradeGroupArgs = {
	newAwayTradeGroup: NewAwayTradeGroup;
};

export type MutationCreateAwayTradeGroupsArgs = {
	newAwayTradeGroups: Array<NewAwayTradeGroup>;
};

export type MutationCreateBlastListArgs = {
	name: Scalars['String'];
};

export type MutationCreateEmailGroupArgs = {
	appendToBlastListId?: InputMaybe<Scalars['Int']>;
	bankId?: InputMaybe<Scalars['Int']>;
	emails?: InputMaybe<Array<Scalars['EmailAddress']>>;
	groupName: Scalars['String'];
};

export type MutationCreateInterestArgs = {
	newInterest: NewInterest;
};

export type MutationCreateInterestsArgs = {
	newInterests: Array<NewInterest>;
};

export type MutationCreatePriceOutArgs = {
	newPriceOut: NewPriceOut;
	sending?: InputMaybe<SendPriceOut>;
};

export type MutationCreateTradeGroupArgs = {
	newTradeGroup: NewTradeGroup;
};

export type MutationCreateUserArgs = {
	newUser: NewUser;
};

export type MutationDeleteAwayTradeGroupArgs = {
	awayTradeGroupId: Scalars['Int'];
};

export type MutationDeleteBlastListArgs = {
	id: Scalars['Int'];
};

export type MutationDeleteEmailGroupArgs = {
	id: Scalars['Int'];
};

export type MutationDeleteInterestArgs = {
	interestId: Scalars['Int'];
};

export type MutationDeletePriceOutArgs = {
	priceOutId: Scalars['Int'];
};

export type MutationDeleteTradeGroupArgs = {
	tradeGroupId: Scalars['Int'];
};

export type MutationDeleteUserArgs = {
	userId: Scalars['Int'];
};

export type MutationDisablePastInterestsArgs = {
	beforeDate?: InputMaybe<Scalars['DateTime']>;
	offsetDisableDateBy?: Scalars['Int'];
};

export type MutationDisableUserArgs = {
	userId: Scalars['Int'];
};

export type MutationEnableUserArgs = {
	userId: Scalars['Int'];
};

export type MutationLogInArgs = {
	email: Scalars['EmailAddress'];
	password: Scalars['String'];
};

export type MutationPostInterestToSquareBookingArgs = {
	interestId: Scalars['Int'];
	legalEntityId: Scalars['Int'];
	squareBookingStructureId: Scalars['Int'];
};

export type MutationPostTradeGroupToSquareBookingArgs = {
	registerTradeFromSellSide?: Scalars['Boolean'];
	tradeGroupId: Scalars['Int'];
};

export type MutationRecallStructureArgs = {
	structureIdentifier: StructureIdentifierInput;
};

export type MutationReplaceUserScopesArgs = {
	newScopes: Array<NewScopeWithExtra>;
	userId?: InputMaybe<Scalars['Int']>;
};

export type MutationRequestPasswordResetArgs = {
	userIdToReset?: InputMaybe<Scalars['Int']>;
};

export type MutationSendPriceOutArgs = {
	priceOutId: Scalars['Int'];
	sending: SendPriceOut;
};

export type MutationStoreNewSettingsArgs = {
	newWebFrontendSettings?: InputMaybe<Scalars['JSONObject']>;
};

export type MutationSubmitNewPasswordArgs = {
	email: Scalars['String'];
	newPassword: Scalars['String'];
	token: Scalars['String'];
};

export type MutationSyncBanksArgs = {
	forceAll?: Scalars['Boolean'];
};

export type MutationSyncClientsArgs = {
	forceAll?: Scalars['Boolean'];
};

export type MutationSyncStructuresArgs = {
	forceAll?: Scalars['Boolean'];
};

export type MutationSyncUnderlyingsArgs = {
	forceAll?: Scalars['Boolean'];
};

export type MutationToggleInterestDisabledStatusArgs = {
	disable: Scalars['Boolean'];
	interestId: Scalars['Int'];
};

export type MutationUpdateAwayTradeGroupArgs = {
	update: AwayTradeGroupUpdate;
};

export type MutationUpdateBankClientArgs = {
	clientId: Scalars['Int'];
	update: ClientUpdate;
};

export type MutationUpdateBlastListArgs = {
	id: Scalars['Int'];
	newEmailGroupIds?: InputMaybe<Array<Scalars['Int']>>;
	newName?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateEmailGroupArgs = {
	id: Scalars['Int'];
	newBankId?: InputMaybe<Scalars['Int']>;
	newEmails?: InputMaybe<Array<Scalars['EmailAddress']>>;
	newGroupName?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateInterestArgs = {
	update: InterestUpdate;
};

export type MutationUpdatePriceOutArgs = {
	update: PriceOutUpdate;
};

export type MutationUpdateTradeGroupArgs = {
	update: TradeGroupUpdate;
};

export type MutationUpdateUserArgs = {
	newDisplayName?: InputMaybe<Scalars['String']>;
	newEmail?: InputMaybe<Scalars['String']>;
	userId: Scalars['Int'];
};

export type NewAwayTrade = {
	buyerBankId?: InputMaybe<Scalars['Int']>;
	buyerClientId?: InputMaybe<Scalars['Int']>;
	comments?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	price?: InputMaybe<Scalars['Float']>;
	sellerBankId?: InputMaybe<Scalars['Int']>;
	sellerClientId?: InputMaybe<Scalars['Int']>;
	size?: InputMaybe<Scalars['Float']>;
	/** The date at which this trade happened. Keep null to use server time. */
	tradedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NewAwayTradeGroup = {
	/** The ID (name) of the broker that performed the trade, if known. */
	awayBrokerDisplayName?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	comments?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	/** The date at which this away trade group was created. Keep null to use server time. */
	createdAt?: InputMaybe<Scalars['DateTime']>;
	/** The away trades to create with this group */
	newTrades: Array<NewAwayTrade>;
	/** Info to match structure to which this interest relates. */
	structureCompositeIndex: StructureIndexInput;
	/** Keep null to let the backend try to compute it from the away trades */
	totalPrice?: InputMaybe<Scalars['Float']>;
	/** Keep null to let the backend try to compute it from the away trades */
	totalSize?: InputMaybe<Scalars['Float']>;
};

export type NewInterest = {
	/** If set, indicates the initiator has seller interest (eventually with a price and a size) */
	ask?: InputMaybe<NewPrice>;
	/**
	 * The name (ID) of the broker that made the interest (only considered if 'isAway' is true).
	 * If the name is unknown, it will be created.
	 */
	awayBrokerDisplayName?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	/** If set, indicates the initiator has buyer interest (eventually with a price and a size) */
	bid?: InputMaybe<NewPrice>;
	comments?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	/** The ID of the bank that manifested the interest. Optional on away interests. */
	initiatorBankId?: InputMaybe<Scalars['Int']>;
	/** The ID of the client that manifested the interest. Should belong to the bank (but not checked). */
	initiatorClientId?: InputMaybe<Scalars['Int']>;
	/** The date to which this interest should be dated. Keep null to use server time. */
	interestDate?: InputMaybe<Scalars['Date']>;
	/** Set to true to indicate that the interest is away. */
	isAway?: Scalars['Boolean'];
	providerMedia?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	providerSource?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	/** Info to match structure to which this interest relates. */
	structureCompositeIndex: StructureIndexInput;
};

export type NewPrice = {
	price?: InputMaybe<Scalars['Float']>;
	size?: InputMaybe<Scalars['NonNegativeFloat']>;
};

export type NewPriceOut = {
	askPrice?: InputMaybe<Scalars['Float']>;
	askSize?: InputMaybe<Scalars['NonNegativeFloat']>;
	bidPrice?: InputMaybe<Scalars['Float']>;
	bidSize?: InputMaybe<Scalars['NonNegativeFloat']>;
	comments?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	/** Info to match structure to which this price out relates. */
	structureCompositeIndex: StructureIndexInput;
};

/** A scope to assign to a user */
export type NewScopeWithExtra = {
	extra?: InputMaybe<Scalars['OptionalSmallString']>;
	scopeType: ScopeType | `${ScopeType}`;
};

export type NewTrade = {
	buyerBankEntityId: Scalars['Int'];
	buyerClientId: Scalars['Int'];
	comments?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	price: Scalars['Float'];
	sellerBankEntityId: Scalars['Int'];
	sellerClientId: Scalars['Int'];
	size: Scalars['Float'];
	/** The date at which this trade happened. Keep null to use server time. */
	tradedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NewTradeGroup = {
	comments?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	/** The date at which this trade group was created. Keep null to use server time. */
	createdAt?: InputMaybe<Scalars['DateTime']>;
	/** The trades to create with this group */
	newTrades: Array<NewTrade>;
	/** The ID of the Square Booking structure to which this group of trades applies. */
	squareBookingStructureId: Scalars['Int'];
	/** Keep null to let the backend compute it from the trades */
	totalPrice?: InputMaybe<Scalars['Float']>;
	/** Keep null to let the backend compute it from the trades */
	totalSize?: InputMaybe<Scalars['Float']>;
};

/** Input object to create a new user. */
export type NewUser = {
	/** A display name for the user. Shown in the UI. Must be unique. */
	displayName: Scalars['NonEmptySmallString'];
	/** The user's email address. Will be used for password reset. */
	email: Scalars['EmailAddress'];
	/** The user's password. If not defined, a password reset will be triggered. */
	password?: InputMaybe<Scalars['OptionalPasswordString']>;
	/** The new user's scopes. Defaults to no scope. */
	scopes?: Array<NewScopeWithExtra>;
};

export enum OrderingDirection {
	Asc = 'ASC',
	Desc = 'DESC',
}

export type Payoff = {
	__typename?: 'Payoff';
	/** When true, the Cap attribute should be enabled on inputs. */
	capEnabled: Scalars['Boolean'];
	/** A name to identify the payoff. */
	displayName: Scalars['String'];
	/** The fixed strike to use with all structures of this payoff, if any. */
	fixedStrikeGroup?: Maybe<FixedStrikeGroup>;
	id: Scalars['Int'];
	/** The type of maturity to use with this payoff. */
	maturityType: MaturityType | `${MaturityType}`;
	/** The number of strikes structures with this payoff should use. */
	numberOfStrikes: Scalars['Int'];
	/** A short version of the name to identify the payoff. */
	shortDisplayName: Scalars['String'];
	/** The type of underlying group categories with this payoff should use. */
	underlyingGroupType: UnderlyingGroupType | `${UnderlyingGroupType}`;
};

export type Price = {
	__typename?: 'Price';
	price?: Maybe<Scalars['Float']>;
	size?: Maybe<Scalars['Float']>;
	/** When set, the Square Booking ID of the order that was linked in Square Booking. */
	squareBookingOrderId?: Maybe<Scalars['Int']>;
};

export type PriceOut = {
	__typename?: 'PriceOut';
	ask: PriceOutPrice;
	bid: PriceOutPrice;
	comments?: Maybe<Scalars['String']>;
	createdAt: Scalars['DateTime'];
	creator?: Maybe<User>;
	/** The blast list that is associated with this price out, if any. */
	emailBlastList?: Maybe<EmailBlastList>;
	id: Scalars['Int'];
	/** The send-status of this price out to email groups of the associated blast list. */
	priceOuts?: Maybe<Array<PriceOutSendingResult>>;
	/** The structure to which this price out relates */
	structure?: Maybe<InternalStructure>;
};

export type PriceOutPriceOutsArgs = {
	includeUnsent?: Scalars['Boolean'];
};

export type PriceOutPrice = {
	__typename?: 'PriceOutPrice';
	price?: Maybe<Scalars['Float']>;
	size?: Maybe<Scalars['Float']>;
};

export type PriceOutSendingResult = {
	/** An email group to which a price out was sent or not. */
	emailGroup: EmailGroup;
};

export type PriceOutSent = PriceOutSendingResult & {
	__typename?: 'PriceOutSent';
	/** The email group to which the price out was sent. */
	emailGroup: EmailGroup;
	id: Scalars['Int'];
	sentAt: Scalars['DateTime'];
};

export type PriceOutUnsent = PriceOutSendingResult & {
	__typename?: 'PriceOutUnsent';
	/** The email group to which the price out was not sent. */
	emailGroup: EmailGroup;
};

export type PriceOutUpdate = {
	askPrice?: InputMaybe<Scalars['Float']>;
	askSize?: InputMaybe<Scalars['NonNegativeFloat']>;
	bidPrice?: InputMaybe<Scalars['Float']>;
	bidSize?: InputMaybe<Scalars['NonNegativeFloat']>;
	comments?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	/** The ID of the price out to update. */
	priceOutId: Scalars['Int'];
};

export type Query = {
	__typename?: 'Query';
	/**
	 * Get activity on a given date.
	 * The date is a date-time describing midnight in the timezone of the user.
	 * For instance, if the user is in UTC+1 and the date is 2020-03-15, the
	 * dateTimeMidnight parameter would be 2020-03-15T01:00:00.000Z.
	 */
	activityOnDate: Activity;
	/** Get all away brokers accessible to the user. Must have a Middle Office Desk Scope. */
	awayBrokers?: Maybe<Array<AwayBroker>>;
	/** Get a specific away trade group (must exist and be accessible to the user). */
	awayTradeGroup?: Maybe<AwayTradeGroup>;
	awayTradeGroups?: Maybe<Array<AwayTradeGroup>>;
	/** Get a bank by ID. */
	bank?: Maybe<Bank>;
	/** Get all legal entities accessible to the user. */
	bankLegalEntities?: Maybe<Array<BankLegalEntity>>;
	/**
	 * Get all banks accessible to the user.
	 * Must have the Middle Office Scope.
	 */
	banks?: Maybe<Array<Bank>>;
	/** Get all categories. */
	categories?: Maybe<Array<Category>>;
	/** Get all category groups. */
	categoryGroups?: Maybe<Array<CategoryGroup>>;
	/** Get a bank client by ID. */
	client?: Maybe<Client>;
	/**
	 * Get all bank clients accessible to the user.
	 * Must have the Middle Office Scope.
	 */
	clients?: Maybe<Array<Client>>;
	/** Get a list of valid currencies accepted by the backend. */
	currencies?: Maybe<Array<CurrencyInfo>>;
	emailBlastList: EmailBlastList;
	emailBlastLists?: Maybe<Array<EmailBlastList>>;
	emailGroup: EmailGroup;
	emailGroups?: Maybe<Array<EmailGroup>>;
	export: ExportData;
	/** Get a specific interest (must exist and be accessible to the user). */
	interest?: Maybe<Interest>;
	/** Search for interests matching the given search parameters. */
	interests?: Maybe<Array<Interest>>;
	/** Get all known base structures (without variables like maturity/cap/etc...) accessible to the user. */
	internalBaseStructures?: Maybe<Array<InternalStructure>>;
	/** Get a specific, known, structure (must exist and be accessible to the user). */
	internalStructure?: Maybe<InternalStructure>;
	knownEmail: KnownEmail;
	/** Get all known email addresses. */
	knownEmailAddresses: Array<KnownEmail>;
	/** Get the structures that were edited recently. */
	liveViewStructures?: Maybe<Array<LiveViewStructure>>;
	/** Returns the number of times a maturity passed in parameters is used. */
	maturityDateUsage: Scalars['Int'];
	/** Return the currently logged-in user, or null if not logged in. */
	me?: Maybe<UserMe>;
	/** Get a specific price out (must exist and be accessible to the user). */
	priceOut?: Maybe<PriceOut>;
	priceOuts?: Maybe<Array<PriceOut>>;
	serverInfo: ServerInfo;
	/** Get all known Square Booking structures. */
	squareBookingStructures?: Maybe<Array<SquareBookingStructure>>;
	/** Get a structure from its attributes (must be accessible to the user, will be created if it does not exist). */
	structure?: Maybe<InternalStructure>;
	/** Get a specific trade group (must exist and be accessible to the user). */
	tradeGroup?: Maybe<TradeGroup>;
	tradeGroups?: Maybe<Array<TradeGroup>>;
	/** Get a specific underlying basket (must exist and be accessible to the user). */
	underlyingBasket?: Maybe<UnderlyingBasket>;
	/** Get all underlying baskets. */
	underlyingBaskets?: Maybe<Array<UnderlyingBasket>>;
	/** Get a user from its ID */
	user?: Maybe<User>;
	/** Get all users */
	users?: Maybe<Array<User>>;
};

export type QueryActivityOnDateArgs = {
	dateTimeMidnight: Scalars['DateTime'];
};

export type QueryAwayTradeGroupArgs = {
	id: Scalars['Int'];
};

export type QueryAwayTradeGroupsArgs = {
	limit?: Scalars['Int'];
	offset?: Scalars['Int'];
	searchParams: InterestBookSearchParams;
};

export type QueryBankArgs = {
	bankId: Scalars['Int'];
};

export type QueryCategoriesArgs = {
	excludeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type QueryClientArgs = {
	clientId: Scalars['Int'];
};

export type QueryClientsArgs = {
	excludeDisabled?: Scalars['Boolean'];
};

export type QueryEmailBlastListArgs = {
	id: Scalars['Int'];
};

export type QueryEmailGroupArgs = {
	id: Scalars['Int'];
};

export type QueryInterestArgs = {
	id: Scalars['Int'];
};

export type QueryInterestsArgs = {
	includeDisabled?: Scalars['Boolean'];
	limit?: Scalars['Int'];
	offset?: Scalars['Int'];
	searchParams: InterestBookSearchParams;
};

export type QueryInternalStructureArgs = {
	identifier: StructureIdentifierInput;
};

export type QueryKnownEmailArgs = {
	email: Scalars['EmailAddress'];
};

export type QueryLiveViewStructuresArgs = {
	since?: InputMaybe<Scalars['DateTime']>;
};

export type QueryMaturityDateUsageArgs = {
	maturityDate: Scalars['Date'];
};

export type QueryPriceOutArgs = {
	id: Scalars['Int'];
};

export type QueryPriceOutsArgs = {
	limit?: Scalars['Int'];
	offset?: Scalars['Int'];
	searchParams: InterestBookSearchParams;
};

export type QueryStructureArgs = {
	compositeIndex: StructureIndexInput;
};

export type QueryTradeGroupArgs = {
	id: Scalars['Int'];
};

export type QueryTradeGroupsArgs = {
	limit?: Scalars['Int'];
	offset?: Scalars['Int'];
	searchParams: InterestBookSearchParams;
};

export type QueryUnderlyingBasketArgs = {
	id: Scalars['Int'];
};

export type QueryUserArgs = {
	userId: Scalars['Int'];
};

export enum ScopeType {
	Dashboard = 'DASHBOARD',
	DataExportMiddleoffice = 'DATA_EXPORT_MIDDLEOFFICE',
	LiveView = 'LIVE_VIEW',
	MiddleofficeDesk = 'MIDDLEOFFICE_DESK',
	UserAdmin = 'USER_ADMIN',
}

export type SendPriceOut = {
	/** The ID of the blast list to use to send this price out. */
	blastListId: Scalars['UInt24'];
	/**
	 * Within the blast list, the IDs of the email groups
	 * to which this price out should be sent.
	 */
	emailGroupIdsToSendTo: Array<Scalars['Int']>;
};

export type ServerInfo = {
	__typename?: 'ServerInfo';
	/** True if the server is running in production mode, false otherwise. */
	isProductionEnvironment: Scalars['Boolean'];
	/** The version of the server, from package.json, null if not available. */
	version?: Maybe<Scalars['String']>;
};

/** An orderbook that was imported from Square Booking. */
export type SquareBookingOrderBook = {
	__typename?: 'SquareBookingOrderBook';
	/** The Square Booking ID of the orderbook. */
	squareBookingId: Scalars['Int'];
};

/** A structure that was imported from Square Booking. */
export type SquareBookingStructure = {
	__typename?: 'SquareBookingStructure';
	/** The Creation time from Square Booking. */
	createdAt: Scalars['DateTime'];
	/** The date of the first time this structure was imported from Square Booking. */
	firstImportedAt: Scalars['DateTime'];
	/** The name of the structure from Square Booking. */
	importedName: Scalars['String'];
	/** The corresponding structure from Square Broking. */
	internalStructure?: Maybe<InternalStructure>;
	/** An ISIN that identifies this structure. */
	isin?: Maybe<Scalars['String']>;
	/** The date of the last time this structure was imported from Square Booking. */
	lastImportedAt: Scalars['DateTime'];
	/** The last Square Booking orderbook related to that structure. */
	lastOrderBook?: Maybe<SquareBookingOrderBook>;
	/** The Square Booking ID of the Square Booking category associated to this structure. */
	squareBookingCategoryId: Scalars['Int'];
	/** The Square Booking ID of the structure. */
	squareBookingId: Scalars['Int'];
	tradeGroups?: Maybe<Array<TradeGroup>>;
	/** The Update time from Square Booking. */
	updatedAt: Scalars['DateTime'];
};

/** A structure that was imported from Square Booking. */
export type SquareBookingStructureTradeGroupsArgs = {
	beforeDate?: InputMaybe<Scalars['Date']>;
	limitLatest?: InputMaybe<Scalars['Int']>;
};

/** An internal structure identifier. */
export type StructureIdentifier = {
	__typename?: 'StructureIdentifier';
	/** If the interest has a cap, set it here. */
	cap?: Maybe<Scalars['Float']>;
	/** The internal ID of the base structure */
	internalId: Scalars['Int'];
	/** The associated internal structure. */
	internalStructure?: Maybe<InternalStructure>;
	/** The absolute maturity date of this structure, if any. */
	maturityDate?: Maybe<Scalars['Date']>;
	/** The relative maturity in months of this structure, if any. */
	maturityMonths?: Maybe<Scalars['Int']>;
};

/** An internal structure identifier. Should be compatible with StructureIdentifier. */
export type StructureIdentifierInput = {
	/** If the interest has a cap, set it here. */
	cap?: InputMaybe<Scalars['Float']>;
	/** The internal ID of the base structure */
	internalId: Scalars['Int'];
	/** The absolute maturity date of this structure, if any. */
	maturityDate?: InputMaybe<Scalars['Date']>;
	/** The relative maturity in months of this structure, if any. */
	maturityMonths?: InputMaybe<Scalars['OptionalUInt16']>;
};

/** A container for Structures composite index. */
export type StructureIndexInput = {
	/** If the interest has a cap, set it here. */
	cap?: InputMaybe<Scalars['Float']>;
	/** The ID of the categoryGroup to which this structure belongs. */
	categoryGroupId: Scalars['UInt16'];
	/** The absolute maturity date of this structure, if any. */
	maturityDate?: InputMaybe<Scalars['Date']>;
	/** The relative maturity in months of this structure, if any. */
	maturityMonths?: InputMaybe<Scalars['OptionalUInt16']>;
	/** The currency of this structure. */
	notionalCurrency: Currency | `${Currency}`;
	/** The ID of the payoff to which this structure belongs. */
	payoffId: Scalars['UInt16'];
	/** The strikes of this structure, or empty if no strike. */
	strikes: Array<Scalars['NonNegativeFloat']>;
	/** The ID of the underlying basket to which this interest applies. */
	underlyingGroupId: Scalars['UInt32'];
};

export type Trade = {
	__typename?: 'Trade';
	buyer?: Maybe<Client>;
	buyerBankEntity?: Maybe<BankLegalEntity>;
	comments?: Maybe<Scalars['String']>;
	id: Scalars['Int'];
	price: Scalars['Float'];
	seller?: Maybe<Client>;
	sellerBankEntity?: Maybe<BankLegalEntity>;
	size: Scalars['Float'];
	/** The ID of the Square Booking buy order, if any. */
	squareBookingBuyOrderId?: Maybe<Scalars['Int']>;
	/** The ID of the Square Booking sell order, if any. */
	squareBookingSellOrderId?: Maybe<Scalars['Int']>;
	tradedAt: Scalars['DateTime'];
};

export type TradeGroup = {
	__typename?: 'TradeGroup';
	comments?: Maybe<Scalars['String']>;
	createdAt: Scalars['DateTime'];
	creator?: Maybe<User>;
	id: Scalars['Int'];
	price: Scalars['Float'];
	size: Scalars['Float'];
	squareBookingStructure?: Maybe<SquareBookingStructure>;
	structure?: Maybe<InternalStructure>;
	trades?: Maybe<Array<Trade>>;
};

export type TradeGroupUpdate = {
	comments?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	/**
	 * The new ID of the Square Booking structure to which this group of trades applies.
	 * Keep null to leave unchanged.
	 */
	newSquareBookingStructureId?: InputMaybe<Scalars['Int']>;
	/** Keep null to leave unchanged. Will not be recomputed from the updated trades. */
	totalPrice?: InputMaybe<Scalars['Float']>;
	/** Keep null to leave unchanged. Will not be recomputed from the updated trades. */
	totalSize?: InputMaybe<Scalars['Float']>;
	/** The ID of the Trade Group to update. */
	tradeGroupId: Scalars['Int'];
	/**
	 * The trades to update with this group. Keep null to leave unchanged.
	 * If set, any trade IDs not in this list will be deleted.
	 */
	updatedTrades?: InputMaybe<Array<TradeUpdate>>;
};

/**
 * Data to patch a trade.
 * To create a new trade, leave the ID field blank and match the NewTrade interface.
 *
 * Any unspecified field will be left unchanged.
 * Setting a field to null will delete it if the field is optional.
 * Setting a required field to null is the same as leaving it unspecified.
 */
export type TradeUpdate = {
	buyerBankEntityId?: InputMaybe<Scalars['Int']>;
	buyerClientId?: InputMaybe<Scalars['Int']>;
	comments?: InputMaybe<Scalars['OptionalNonEmptySmallString']>;
	/** If provided, the ID of the trade to update. If not provided, the trade will be created. */
	id?: InputMaybe<Scalars['Int']>;
	price?: InputMaybe<Scalars['Float']>;
	sellerBankEntityId?: InputMaybe<Scalars['Int']>;
	sellerClientId?: InputMaybe<Scalars['Int']>;
	size?: InputMaybe<Scalars['Float']>;
	/** The date at which this trade happened. Keep null to leave unchanged. */
	tradedAt?: InputMaybe<Scalars['DateTime']>;
};

export type Underlying = {
	__typename?: 'Underlying';
	comments?: Maybe<Scalars['String']>;
	createdAt: Scalars['DateTime'];
	/** FIGI, The Financial Instrument Global Identifier of the underlying, also denoted as ID_BB_GLOBAL */
	figi?: Maybe<Scalars['String']>;
	id: Scalars['Int'];
	/** The International Securities Identification Number of the underlying. */
	isin?: Maybe<Scalars['String']>;
	/** Share-class-level FIGI, also denoted as ID_BB_GLOBAL_SHARE_CLASS_LEVEL */
	shareClassFigi?: Maybe<Scalars['String']>;
	/** The ticker used to identify the underlying on Square Booking */
	squareBookingTicker: Scalars['String'];
	/** A name for the stock, if any */
	stockName?: Maybe<Scalars['String']>;
	updatedAt: Scalars['DateTime'];
};

export type UnderlyingBasket = {
	__typename?: 'UnderlyingBasket';
	comments?: Maybe<Scalars['String']>;
	createdAt: Scalars['DateTime'];
	displayName?: Maybe<Scalars['String']>;
	id: Scalars['Int'];
	lastImportedAt?: Maybe<Scalars['DateTime']>;
	/** An ordered list of the underlyings in the basket. */
	underlyings?: Maybe<Array<Underlying>>;
	updatedAt: Scalars['DateTime'];
};

export enum UnderlyingGroupType {
	Basket = 'BASKET',
	Single = 'SINGLE',
}

export type User = {
	__typename?: 'User';
	active: Scalars['Boolean'];
	disabled: Scalars['Boolean'];
	displayName: Scalars['String'];
	email: Scalars['EmailAddress'];
	emailIsVerified: Scalars['Boolean'];
	id: Scalars['Int'];
	scopeTypes?: Maybe<Array<ScopeType | `${ScopeType}`>>;
	scopes?: Maybe<Array<UserScope>>;
};

export type UserMe = {
	__typename?: 'UserMe';
	user: User;
	userId: Scalars['Int'];
	/** Free-form JSON object that can be used to store settings data. */
	webFrontendSettings?: Maybe<Scalars['JSONObject']>;
};

export type UserScope = {
	__typename?: 'UserScope';
	scopeInfo?: Maybe<Scalars['String']>;
	scopeType: ScopeType | `${ScopeType}`;
	since: Scalars['DateTime'];
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
	resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
	| ResolverFn<TResult, TParent, TContext, TArgs>
	| ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
	TResult,
	TKey extends string,
	TParent,
	TContext,
	TArgs
> {
	subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
	resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
	subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
	resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
	| SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
	| SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
	TResult,
	TKey extends string,
	TParent = {},
	TContext = {},
	TArgs = {}
> =
	| ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
	| SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
	parent: TParent,
	context: TContext,
	info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
	obj: T,
	context: TContext,
	info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
	next: NextResolverFn<TResult>,
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
	Activity: ResolverTypeWrapper<Activity>;
	AwayBroker: ResolverTypeWrapper<AwayBroker>;
	AwayTrade: ResolverTypeWrapper<AwayTrade>;
	AwayTradeGroup: ResolverTypeWrapper<AwayTradeGroup>;
	AwayTradeGroupUpdate: AwayTradeGroupUpdate;
	AwayTradeUpdate: AwayTradeUpdate;
	Bank: ResolverTypeWrapper<Bank>;
	BankLegalEntity: ResolverTypeWrapper<BankLegalEntity>;
	Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
	Category: ResolverTypeWrapper<
		Omit<Category, 'knownMaturities'> & {
			knownMaturities?: Maybe<ResolversTypes['KnownMaturities']>;
		}
	>;
	CategoryGroup: ResolverTypeWrapper<CategoryGroup>;
	CategoryNotification: ResolverTypeWrapper<CategoryNotification>;
	Client: ResolverTypeWrapper<Client>;
	ClientUpdate: ClientUpdate;
	Country: ResolverTypeWrapper<Country>;
	Currency: Currency;
	CurrencyInfo: ResolverTypeWrapper<CurrencyInfo>;
	Date: ResolverTypeWrapper<Scalars['Date']>;
	DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
	DateTimeRange: ResolverTypeWrapper<DateTimeRange>;
	EmailAddress: ResolverTypeWrapper<Scalars['EmailAddress']>;
	EmailBlastList: ResolverTypeWrapper<EmailBlastList>;
	EmailGroup: ResolverTypeWrapper<EmailGroup>;
	ExportData: ResolverTypeWrapper<ExportData>;
	FirebaseCustomTokenResponse: ResolverTypeWrapper<FirebaseCustomTokenResponse>;
	FixedStrikeGroup: ResolverTypeWrapper<FixedStrikeGroup>;
	Float: ResolverTypeWrapper<Scalars['Float']>;
	Int: ResolverTypeWrapper<Scalars['Int']>;
	Interest: ResolverTypeWrapper<Interest>;
	InterestBookColumnOrder: InterestBookColumnOrder;
	InterestBookFiltering: InterestBookFiltering;
	InterestBookOrdering: InterestBookOrdering;
	InterestBookSearchParams: InterestBookSearchParams;
	InterestUpdate: InterestUpdate;
	InternalStructure: ResolverTypeWrapper<
		Omit<InternalStructure, 'maturity'> & { maturity?: Maybe<ResolversTypes['Maturity']> }
	>;
	JSONObject: ResolverTypeWrapper<Scalars['JSONObject']>;
	KnownAbsoluteMaturities: ResolverTypeWrapper<KnownAbsoluteMaturities>;
	KnownEmail: ResolverTypeWrapper<KnownEmail>;
	KnownMaturities:
		| ResolversTypes['KnownAbsoluteMaturities']
		| ResolversTypes['KnownRelativeMaturities'];
	KnownRelativeMaturities: ResolverTypeWrapper<KnownRelativeMaturities>;
	KnownUnderlyingDates: ResolverTypeWrapper<KnownUnderlyingDates>;
	LastPrint: ResolversTypes['AwayTrade'] | ResolversTypes['Trade'];
	LiveViewStructure: ResolverTypeWrapper<
		Omit<LiveViewStructure, 'lastPrints'> & {
			lastPrints?: Maybe<Array<ResolversTypes['LastPrint']>>;
		}
	>;
	Maturity: ResolversTypes['MaturityDate'] | ResolversTypes['MaturityRelative'];
	MaturityDate: ResolverTypeWrapper<MaturityDate>;
	MaturityRelative: ResolverTypeWrapper<MaturityRelative>;
	MaturityType: MaturityType;
	Mutation: ResolverTypeWrapper<{}>;
	NewAwayTrade: NewAwayTrade;
	NewAwayTradeGroup: NewAwayTradeGroup;
	NewInterest: NewInterest;
	NewPrice: NewPrice;
	NewPriceOut: NewPriceOut;
	NewScopeWithExtra: NewScopeWithExtra;
	NewTrade: NewTrade;
	NewTradeGroup: NewTradeGroup;
	NewUser: NewUser;
	NonEmptySmallString: ResolverTypeWrapper<Scalars['NonEmptySmallString']>;
	NonNegativeFloat: ResolverTypeWrapper<Scalars['NonNegativeFloat']>;
	OptionalNonEmptySmallString: ResolverTypeWrapper<Scalars['OptionalNonEmptySmallString']>;
	OptionalNonEmptyText: ResolverTypeWrapper<Scalars['OptionalNonEmptyText']>;
	OptionalPasswordString: ResolverTypeWrapper<Scalars['OptionalPasswordString']>;
	OptionalSmallString: ResolverTypeWrapper<Scalars['OptionalSmallString']>;
	OptionalUInt16: ResolverTypeWrapper<Scalars['OptionalUInt16']>;
	OrderingDirection: OrderingDirection;
	Payoff: ResolverTypeWrapper<Payoff>;
	Price: ResolverTypeWrapper<Price>;
	PriceOut: ResolverTypeWrapper<PriceOut>;
	PriceOutPrice: ResolverTypeWrapper<PriceOutPrice>;
	PriceOutSendingResult: ResolversTypes['PriceOutSent'] | ResolversTypes['PriceOutUnsent'];
	PriceOutSent: ResolverTypeWrapper<PriceOutSent>;
	PriceOutUnsent: ResolverTypeWrapper<PriceOutUnsent>;
	PriceOutUpdate: PriceOutUpdate;
	Query: ResolverTypeWrapper<{}>;
	ScopeType: ScopeType;
	SendPriceOut: SendPriceOut;
	ServerInfo: ResolverTypeWrapper<ServerInfo>;
	SquareBookingOrderBook: ResolverTypeWrapper<SquareBookingOrderBook>;
	SquareBookingStructure: ResolverTypeWrapper<SquareBookingStructure>;
	String: ResolverTypeWrapper<Scalars['String']>;
	StructureIdentifier: ResolverTypeWrapper<StructureIdentifier>;
	StructureIdentifierInput: StructureIdentifierInput;
	StructureIndexInput: StructureIndexInput;
	Trade: ResolverTypeWrapper<Trade>;
	TradeGroup: ResolverTypeWrapper<TradeGroup>;
	TradeGroupUpdate: TradeGroupUpdate;
	TradeUpdate: TradeUpdate;
	UInt16: ResolverTypeWrapper<Scalars['UInt16']>;
	UInt24: ResolverTypeWrapper<Scalars['UInt24']>;
	UInt32: ResolverTypeWrapper<Scalars['UInt32']>;
	Underlying: ResolverTypeWrapper<Underlying>;
	UnderlyingBasket: ResolverTypeWrapper<UnderlyingBasket>;
	UnderlyingGroupType: UnderlyingGroupType;
	User: ResolverTypeWrapper<User>;
	UserMe: ResolverTypeWrapper<UserMe>;
	UserScope: ResolverTypeWrapper<UserScope>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
	Activity: Activity;
	AwayBroker: AwayBroker;
	AwayTrade: AwayTrade;
	AwayTradeGroup: AwayTradeGroup;
	AwayTradeGroupUpdate: AwayTradeGroupUpdate;
	AwayTradeUpdate: AwayTradeUpdate;
	Bank: Bank;
	BankLegalEntity: BankLegalEntity;
	Boolean: Scalars['Boolean'];
	Category: Omit<Category, 'knownMaturities'> & {
		knownMaturities?: Maybe<ResolversParentTypes['KnownMaturities']>;
	};
	CategoryGroup: CategoryGroup;
	CategoryNotification: CategoryNotification;
	Client: Client;
	ClientUpdate: ClientUpdate;
	Country: Country;
	CurrencyInfo: CurrencyInfo;
	Date: Scalars['Date'];
	DateTime: Scalars['DateTime'];
	DateTimeRange: DateTimeRange;
	EmailAddress: Scalars['EmailAddress'];
	EmailBlastList: EmailBlastList;
	EmailGroup: EmailGroup;
	ExportData: ExportData;
	FirebaseCustomTokenResponse: FirebaseCustomTokenResponse;
	FixedStrikeGroup: FixedStrikeGroup;
	Float: Scalars['Float'];
	Int: Scalars['Int'];
	Interest: Interest;
	InterestBookFiltering: InterestBookFiltering;
	InterestBookOrdering: InterestBookOrdering;
	InterestBookSearchParams: InterestBookSearchParams;
	InterestUpdate: InterestUpdate;
	InternalStructure: Omit<InternalStructure, 'maturity'> & {
		maturity?: Maybe<ResolversParentTypes['Maturity']>;
	};
	JSONObject: Scalars['JSONObject'];
	KnownAbsoluteMaturities: KnownAbsoluteMaturities;
	KnownEmail: KnownEmail;
	KnownMaturities:
		| ResolversParentTypes['KnownAbsoluteMaturities']
		| ResolversParentTypes['KnownRelativeMaturities'];
	KnownRelativeMaturities: KnownRelativeMaturities;
	KnownUnderlyingDates: KnownUnderlyingDates;
	LastPrint: ResolversParentTypes['AwayTrade'] | ResolversParentTypes['Trade'];
	LiveViewStructure: Omit<LiveViewStructure, 'lastPrints'> & {
		lastPrints?: Maybe<Array<ResolversParentTypes['LastPrint']>>;
	};
	Maturity: ResolversParentTypes['MaturityDate'] | ResolversParentTypes['MaturityRelative'];
	MaturityDate: MaturityDate;
	MaturityRelative: MaturityRelative;
	Mutation: {};
	NewAwayTrade: NewAwayTrade;
	NewAwayTradeGroup: NewAwayTradeGroup;
	NewInterest: NewInterest;
	NewPrice: NewPrice;
	NewPriceOut: NewPriceOut;
	NewScopeWithExtra: NewScopeWithExtra;
	NewTrade: NewTrade;
	NewTradeGroup: NewTradeGroup;
	NewUser: NewUser;
	NonEmptySmallString: Scalars['NonEmptySmallString'];
	NonNegativeFloat: Scalars['NonNegativeFloat'];
	OptionalNonEmptySmallString: Scalars['OptionalNonEmptySmallString'];
	OptionalNonEmptyText: Scalars['OptionalNonEmptyText'];
	OptionalPasswordString: Scalars['OptionalPasswordString'];
	OptionalSmallString: Scalars['OptionalSmallString'];
	OptionalUInt16: Scalars['OptionalUInt16'];
	Payoff: Payoff;
	Price: Price;
	PriceOut: PriceOut;
	PriceOutPrice: PriceOutPrice;
	PriceOutSendingResult:
		| ResolversParentTypes['PriceOutSent']
		| ResolversParentTypes['PriceOutUnsent'];
	PriceOutSent: PriceOutSent;
	PriceOutUnsent: PriceOutUnsent;
	PriceOutUpdate: PriceOutUpdate;
	Query: {};
	SendPriceOut: SendPriceOut;
	ServerInfo: ServerInfo;
	SquareBookingOrderBook: SquareBookingOrderBook;
	SquareBookingStructure: SquareBookingStructure;
	String: Scalars['String'];
	StructureIdentifier: StructureIdentifier;
	StructureIdentifierInput: StructureIdentifierInput;
	StructureIndexInput: StructureIndexInput;
	Trade: Trade;
	TradeGroup: TradeGroup;
	TradeGroupUpdate: TradeGroupUpdate;
	TradeUpdate: TradeUpdate;
	UInt16: Scalars['UInt16'];
	UInt24: Scalars['UInt24'];
	UInt32: Scalars['UInt32'];
	Underlying: Underlying;
	UnderlyingBasket: UnderlyingBasket;
	User: User;
	UserMe: UserMe;
	UserScope: UserScope;
};

export type AuthDirectiveArgs = {
	allowGoogleBypass?: Maybe<Scalars['Boolean']>;
	loadUserScopes?: Maybe<Scalars['Boolean']>;
	minScopes?: Array<ScopeType>;
};

export type AuthDirectiveResolver<
	Result,
	Parent,
	ContextType = any,
	Args = AuthDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ConstraintDirectiveArgs = {
	contains?: Maybe<Scalars['String']>;
	endsWith?: Maybe<Scalars['String']>;
	exclusiveMax?: Maybe<Scalars['Float']>;
	exclusiveMin?: Maybe<Scalars['Float']>;
	format?: Maybe<Scalars['String']>;
	max?: Maybe<Scalars['Float']>;
	maxLength?: Maybe<Scalars['Int']>;
	min?: Maybe<Scalars['Float']>;
	minLength?: Maybe<Scalars['Int']>;
	multipleOf?: Maybe<Scalars['Float']>;
	notContains?: Maybe<Scalars['String']>;
	pattern?: Maybe<Scalars['String']>;
	startsWith?: Maybe<Scalars['String']>;
	uniqueTypeName?: Maybe<Scalars['String']>;
};

export type ConstraintDirectiveResolver<
	Result,
	Parent,
	ContextType = any,
	Args = ConstraintDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ActivityResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['Activity'] = ResolversParentTypes['Activity']
> = {
	dateTimeRange?: Resolver<ResolversTypes['DateTimeRange'], ParentType, ContextType>;
	structureIdentifiers?: Resolver<
		Maybe<Array<ResolversTypes['StructureIdentifier']>>,
		ParentType,
		ContextType
	>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwayBrokerResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['AwayBroker'] = ResolversParentTypes['AwayBroker']
> = {
	displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwayTradeResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['AwayTrade'] = ResolversParentTypes['AwayTrade']
> = {
	awayTradeGroup?: Resolver<Maybe<ResolversTypes['AwayTradeGroup']>, ParentType, ContextType>;
	buyer?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType>;
	buyerBank?: Resolver<Maybe<ResolversTypes['Bank']>, ParentType, ContextType>;
	comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
	seller?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType>;
	sellerBank?: Resolver<Maybe<ResolversTypes['Bank']>, ParentType, ContextType>;
	size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
	tradedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwayTradeGroupResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['AwayTradeGroup'] = ResolversParentTypes['AwayTradeGroup']
> = {
	awayBroker?: Resolver<Maybe<ResolversTypes['AwayBroker']>, ParentType, ContextType>;
	awayTrades?: Resolver<Maybe<Array<ResolversTypes['AwayTrade']>>, ParentType, ContextType>;
	comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	creator?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
	size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
	structure?: Resolver<Maybe<ResolversTypes['InternalStructure']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BankResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['Bank'] = ResolversParentTypes['Bank']
> = {
	clients?: Resolver<Maybe<Array<ResolversTypes['Client']>>, ParentType, ContextType>;
	displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	legalEntities?: Resolver<
		Maybe<Array<ResolversTypes['BankLegalEntity']>>,
		ParentType,
		ContextType
	>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BankLegalEntityResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['BankLegalEntity'] = ResolversParentTypes['BankLegalEntity']
> = {
	bank?: Resolver<Maybe<ResolversTypes['Bank']>, ParentType, ContextType>;
	country?: Resolver<Maybe<ResolversTypes['Country']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	legalName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category']
> = {
	canBeSentAsEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	categoryGroup?: Resolver<Maybe<ResolversTypes['CategoryGroup']>, ParentType, ContextType>;
	categoryGroupId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	disabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	formatHint?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	knownMaturities?: Resolver<Maybe<ResolversTypes['KnownMaturities']>, ParentType, ContextType>;
	knownUnderlyingDates?: Resolver<
		Maybe<Array<ResolversTypes['KnownUnderlyingDates']>>,
		ParentType,
		ContextType
	>;
	payoff?: Resolver<Maybe<ResolversTypes['Payoff']>, ParentType, ContextType>;
	payoffId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryGroupResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['CategoryGroup'] = ResolversParentTypes['CategoryGroup']
> = {
	categories?: Resolver<Maybe<Array<ResolversTypes['Category']>>, ParentType, ContextType>;
	displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryNotificationResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['CategoryNotification'] = ResolversParentTypes['CategoryNotification']
> = {
	categoryGroupId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	payoffId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	recallsCollectionPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	updatesCollectionPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['Client'] = ResolversParentTypes['Client']
> = {
	backOfficeEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	bank?: Resolver<Maybe<ResolversTypes['Bank']>, ParentType, ContextType>;
	birthDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
	comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	firstImportedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	isDisabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	middleOfficeEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	squareBookingCreatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	squareBookingUpdatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country']
> = {
	displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	shortName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyInfoResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['CurrencyInfo'] = ResolversParentTypes['CurrencyInfo']
> = {
	alphabeticCode?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
	enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
	name: 'Date';
}

export interface DateTimeScalarConfig
	extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
	name: 'DateTime';
}

export type DateTimeRangeResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['DateTimeRange'] = ResolversParentTypes['DateTimeRange']
> = {
	end?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	start?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface EmailAddressScalarConfig
	extends GraphQLScalarTypeConfig<ResolversTypes['EmailAddress'], any> {
	name: 'EmailAddress';
}

export type EmailBlastListResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['EmailBlastList'] = ResolversParentTypes['EmailBlastList']
> = {
	displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	emailGroups?: Resolver<Maybe<Array<ResolversTypes['EmailGroup']>>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailGroupResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['EmailGroup'] = ResolversParentTypes['EmailGroup']
> = {
	bank?: Resolver<Maybe<ResolversTypes['Bank']>, ParentType, ContextType>;
	createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	emails?: Resolver<Maybe<Array<ResolversTypes['KnownEmail']>>, ParentType, ContextType>;
	groupName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportDataResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['ExportData'] = ResolversParentTypes['ExportData']
> = {
	awayTradeGroups?: Resolver<
		Maybe<Array<ResolversTypes['AwayTradeGroup']>>,
		ParentType,
		ContextType,
		RequireFields<ExportDataAwayTradeGroupsArgs, 'limit'>
	>;
	interests?: Resolver<
		Maybe<Array<ResolversTypes['Interest']>>,
		ParentType,
		ContextType,
		RequireFields<ExportDataInterestsArgs, 'limit'>
	>;
	tradeGroups?: Resolver<
		Maybe<Array<ResolversTypes['TradeGroup']>>,
		ParentType,
		ContextType,
		RequireFields<ExportDataTradeGroupsArgs, 'limit'>
	>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FirebaseCustomTokenResponseResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['FirebaseCustomTokenResponse'] = ResolversParentTypes['FirebaseCustomTokenResponse']
> = {
	categoryNotifications?: Resolver<
		Maybe<Array<ResolversTypes['CategoryNotification']>>,
		ParentType,
		ContextType
	>;
	customToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	environment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	notificationPaths?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FixedStrikeGroupResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['FixedStrikeGroup'] = ResolversParentTypes['FixedStrikeGroup']
> = {
	groupId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	strikes?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InterestResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['Interest'] = ResolversParentTypes['Interest']
> = {
	ask?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
	awayBroker?: Resolver<Maybe<ResolversTypes['AwayBroker']>, ParentType, ContextType>;
	bid?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
	comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	creator?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
	disabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	initiatorBank?: Resolver<Maybe<ResolversTypes['Bank']>, ParentType, ContextType>;
	initiatorClient?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType>;
	interestDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
	isAway?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	providerMedia?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	providerSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	structure?: Resolver<Maybe<ResolversTypes['InternalStructure']>, ParentType, ContextType>;
	updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InternalStructureResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['InternalStructure'] = ResolversParentTypes['InternalStructure']
> = {
	awayTradeGroups?: Resolver<
		Maybe<Array<ResolversTypes['AwayTradeGroup']>>,
		ParentType,
		ContextType,
		RequireFields<InternalStructureAwayTradeGroupsArgs, 'beforeDate' | 'limitLatest'>
	>;
	cap?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
	category?: Resolver<Maybe<ResolversTypes['Category']>, ParentType, ContextType>;
	createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	datesOfInterest?: Resolver<
		Maybe<Array<ResolversTypes['Date']>>,
		ParentType,
		ContextType,
		RequireFields<
			InternalStructureDatesOfInterestArgs,
			'before' | 'includeDisabled' | 'includeNonPricedInterests' | 'limit'
		>
	>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	interestDates?: Resolver<
		Maybe<Array<ResolversTypes['Date']>>,
		ParentType,
		ContextType,
		RequireFields<InternalStructureInterestDatesArgs, 'before' | 'limit'>
	>;
	interests?: Resolver<
		Maybe<Array<ResolversTypes['Interest']>>,
		ParentType,
		ContextType,
		RequireFields<
			InternalStructureInterestsArgs,
			| 'beforeDate'
			| 'date'
			| 'excludeNonPriced'
			| 'excludePriced'
			| 'includeDisabled'
			| 'limitLatest'
		>
	>;
	maturity?: Resolver<Maybe<ResolversTypes['Maturity']>, ParentType, ContextType>;
	notionalCurrency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
	priceOuts?: Resolver<
		Maybe<Array<ResolversTypes['PriceOut']>>,
		ParentType,
		ContextType,
		RequireFields<InternalStructurePriceOutsArgs, 'dateTimeMidnight' | 'limitLatest'>
	>;
	squareBookingStructures?: Resolver<
		Maybe<Array<ResolversTypes['SquareBookingStructure']>>,
		ParentType,
		ContextType
	>;
	strikes?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>;
	underlyingBasket?: Resolver<Maybe<ResolversTypes['UnderlyingBasket']>, ParentType, ContextType>;
	updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonObjectScalarConfig
	extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
	name: 'JSONObject';
}

export type KnownAbsoluteMaturitiesResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['KnownAbsoluteMaturities'] = ResolversParentTypes['KnownAbsoluteMaturities']
> = {
	knownDates?: Resolver<Maybe<Array<ResolversTypes['Date']>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KnownEmailResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['KnownEmail'] = ResolversParentTypes['KnownEmail']
> = {
	clientsUsingThisAddress?: Resolver<
		Maybe<Array<ResolversTypes['Client']>>,
		ParentType,
		ContextType
	>;
	countOfClientsUsingThisAddress?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
	email?: Resolver<ResolversTypes['EmailAddress'], ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KnownMaturitiesResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['KnownMaturities'] = ResolversParentTypes['KnownMaturities']
> = {
	__resolveType: TypeResolveFn<
		'KnownAbsoluteMaturities' | 'KnownRelativeMaturities',
		ParentType,
		ContextType
	>;
};

export type KnownRelativeMaturitiesResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['KnownRelativeMaturities'] = ResolversParentTypes['KnownRelativeMaturities']
> = {
	knownMonthExpirations?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KnownUnderlyingDatesResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['KnownUnderlyingDates'] = ResolversParentTypes['KnownUnderlyingDates']
> = {
	dates?: Resolver<Maybe<Array<ResolversTypes['Date']>>, ParentType, ContextType>;
	underlyingBasketId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LastPrintResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['LastPrint'] = ResolversParentTypes['LastPrint']
> = {
	__resolveType: TypeResolveFn<'AwayTrade' | 'Trade', ParentType, ContextType>;
};

export type LiveViewStructureResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['LiveViewStructure'] = ResolversParentTypes['LiveViewStructure']
> = {
	dateTimeRange?: Resolver<ResolversTypes['DateTimeRange'], ParentType, ContextType>;
	interestedBuyers?: Resolver<Maybe<Array<ResolversTypes['Bank']>>, ParentType, ContextType>;
	interestedSellers?: Resolver<Maybe<Array<ResolversTypes['Bank']>>, ParentType, ContextType>;
	interestedWithoutWay?: Resolver<Maybe<Array<ResolversTypes['Bank']>>, ParentType, ContextType>;
	lastDayBestAskInterest?: Resolver<
		Maybe<ResolversTypes['Interest']>,
		ParentType,
		ContextType,
		RequireFields<LiveViewStructureLastDayBestAskInterestArgs, 'away'>
	>;
	lastDayBestBidInterest?: Resolver<
		Maybe<ResolversTypes['Interest']>,
		ParentType,
		ContextType,
		RequireFields<LiveViewStructureLastDayBestBidInterestArgs, 'away'>
	>;
	lastPrints?: Resolver<
		Maybe<Array<ResolversTypes['LastPrint']>>,
		ParentType,
		ContextType,
		RequireFields<LiveViewStructureLastPrintsArgs, 'count'>
	>;
	structureIdentifier?: Resolver<ResolversTypes['StructureIdentifier'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MaturityResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['Maturity'] = ResolversParentTypes['Maturity']
> = {
	__resolveType: TypeResolveFn<'MaturityDate' | 'MaturityRelative', ParentType, ContextType>;
};

export type MaturityDateResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['MaturityDate'] = ResolversParentTypes['MaturityDate']
> = {
	expirationDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MaturityRelativeResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['MaturityRelative'] = ResolversParentTypes['MaturityRelative']
> = {
	expirationMonths?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
	cleanUpFirestore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
	createAwayTradeGroup?: Resolver<
		ResolversTypes['AwayTradeGroup'],
		ParentType,
		ContextType,
		RequireFields<MutationCreateAwayTradeGroupArgs, 'newAwayTradeGroup'>
	>;
	createAwayTradeGroups?: Resolver<
		Array<Maybe<ResolversTypes['AwayTradeGroup']>>,
		ParentType,
		ContextType,
		RequireFields<MutationCreateAwayTradeGroupsArgs, 'newAwayTradeGroups'>
	>;
	createBlastList?: Resolver<
		ResolversTypes['EmailBlastList'],
		ParentType,
		ContextType,
		RequireFields<MutationCreateBlastListArgs, 'name'>
	>;
	createEmailGroup?: Resolver<
		ResolversTypes['EmailGroup'],
		ParentType,
		ContextType,
		RequireFields<MutationCreateEmailGroupArgs, 'groupName'>
	>;
	createInterest?: Resolver<
		ResolversTypes['Interest'],
		ParentType,
		ContextType,
		RequireFields<MutationCreateInterestArgs, 'newInterest'>
	>;
	createInterests?: Resolver<
		Array<Maybe<ResolversTypes['Interest']>>,
		ParentType,
		ContextType,
		RequireFields<MutationCreateInterestsArgs, 'newInterests'>
	>;
	createPriceOut?: Resolver<
		ResolversTypes['PriceOut'],
		ParentType,
		ContextType,
		RequireFields<MutationCreatePriceOutArgs, 'newPriceOut'>
	>;
	createTradeGroup?: Resolver<
		ResolversTypes['TradeGroup'],
		ParentType,
		ContextType,
		RequireFields<MutationCreateTradeGroupArgs, 'newTradeGroup'>
	>;
	createUser?: Resolver<
		ResolversTypes['User'],
		ParentType,
		ContextType,
		RequireFields<MutationCreateUserArgs, 'newUser'>
	>;
	deleteAwayTradeGroup?: Resolver<
		ResolversTypes['Boolean'],
		ParentType,
		ContextType,
		RequireFields<MutationDeleteAwayTradeGroupArgs, 'awayTradeGroupId'>
	>;
	deleteBlastList?: Resolver<
		Maybe<ResolversTypes['Boolean']>,
		ParentType,
		ContextType,
		RequireFields<MutationDeleteBlastListArgs, 'id'>
	>;
	deleteEmailGroup?: Resolver<
		Maybe<ResolversTypes['Boolean']>,
		ParentType,
		ContextType,
		RequireFields<MutationDeleteEmailGroupArgs, 'id'>
	>;
	deleteInterest?: Resolver<
		ResolversTypes['Boolean'],
		ParentType,
		ContextType,
		RequireFields<MutationDeleteInterestArgs, 'interestId'>
	>;
	deletePriceOut?: Resolver<
		ResolversTypes['Boolean'],
		ParentType,
		ContextType,
		RequireFields<MutationDeletePriceOutArgs, 'priceOutId'>
	>;
	deleteTradeGroup?: Resolver<
		ResolversTypes['Boolean'],
		ParentType,
		ContextType,
		RequireFields<MutationDeleteTradeGroupArgs, 'tradeGroupId'>
	>;
	deleteUser?: Resolver<
		Maybe<ResolversTypes['Int']>,
		ParentType,
		ContextType,
		RequireFields<MutationDeleteUserArgs, 'userId'>
	>;
	disablePastInterests?: Resolver<
		ResolversTypes['Boolean'],
		ParentType,
		ContextType,
		RequireFields<MutationDisablePastInterestsArgs, 'offsetDisableDateBy'>
	>;
	disableUser?: Resolver<
		Maybe<ResolversTypes['Int']>,
		ParentType,
		ContextType,
		RequireFields<MutationDisableUserArgs, 'userId'>
	>;
	enableUser?: Resolver<
		Maybe<ResolversTypes['Int']>,
		ParentType,
		ContextType,
		RequireFields<MutationEnableUserArgs, 'userId'>
	>;
	logIn?: Resolver<
		ResolversTypes['User'],
		ParentType,
		ContextType,
		RequireFields<MutationLogInArgs, 'email' | 'password'>
	>;
	logOut?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
	mergeDuplicateUnderlyingGroups?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
	postInterestToSquareBooking?: Resolver<
		ResolversTypes['Interest'],
		ParentType,
		ContextType,
		RequireFields<
			MutationPostInterestToSquareBookingArgs,
			'interestId' | 'legalEntityId' | 'squareBookingStructureId'
		>
	>;
	postTradeGroupToSquareBooking?: Resolver<
		ResolversTypes['TradeGroup'],
		ParentType,
		ContextType,
		RequireFields<
			MutationPostTradeGroupToSquareBookingArgs,
			'registerTradeFromSellSide' | 'tradeGroupId'
		>
	>;
	recallStructure?: Resolver<
		Maybe<ResolversTypes['Boolean']>,
		ParentType,
		ContextType,
		RequireFields<MutationRecallStructureArgs, 'structureIdentifier'>
	>;
	replaceUserScopes?: Resolver<
		Maybe<ResolversTypes['Boolean']>,
		ParentType,
		ContextType,
		RequireFields<MutationReplaceUserScopesArgs, 'newScopes'>
	>;
	requestPasswordReset?: Resolver<
		Maybe<ResolversTypes['EmailAddress']>,
		ParentType,
		ContextType,
		Partial<MutationRequestPasswordResetArgs>
	>;
	sendPriceOut?: Resolver<
		ResolversTypes['PriceOut'],
		ParentType,
		ContextType,
		RequireFields<MutationSendPriceOutArgs, 'priceOutId' | 'sending'>
	>;
	signDashboardAuth?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	signFirebaseAuth?: Resolver<
		ResolversTypes['FirebaseCustomTokenResponse'],
		ParentType,
		ContextType
	>;
	storeNewSettings?: Resolver<
		Maybe<ResolversTypes['Boolean']>,
		ParentType,
		ContextType,
		Partial<MutationStoreNewSettingsArgs>
	>;
	submitNewPassword?: Resolver<
		Maybe<ResolversTypes['Boolean']>,
		ParentType,
		ContextType,
		RequireFields<MutationSubmitNewPasswordArgs, 'email' | 'newPassword' | 'token'>
	>;
	syncBanks?: Resolver<
		Maybe<ResolversTypes['Boolean']>,
		ParentType,
		ContextType,
		RequireFields<MutationSyncBanksArgs, 'forceAll'>
	>;
	syncClients?: Resolver<
		Maybe<ResolversTypes['Boolean']>,
		ParentType,
		ContextType,
		RequireFields<MutationSyncClientsArgs, 'forceAll'>
	>;
	syncStructures?: Resolver<
		Maybe<ResolversTypes['Boolean']>,
		ParentType,
		ContextType,
		RequireFields<MutationSyncStructuresArgs, 'forceAll'>
	>;
	syncUnderlyings?: Resolver<
		Maybe<ResolversTypes['Boolean']>,
		ParentType,
		ContextType,
		RequireFields<MutationSyncUnderlyingsArgs, 'forceAll'>
	>;
	toggleInterestDisabledStatus?: Resolver<
		ResolversTypes['Interest'],
		ParentType,
		ContextType,
		RequireFields<MutationToggleInterestDisabledStatusArgs, 'disable' | 'interestId'>
	>;
	updateAwayTradeGroup?: Resolver<
		ResolversTypes['AwayTradeGroup'],
		ParentType,
		ContextType,
		RequireFields<MutationUpdateAwayTradeGroupArgs, 'update'>
	>;
	updateBankClient?: Resolver<
		ResolversTypes['Client'],
		ParentType,
		ContextType,
		RequireFields<MutationUpdateBankClientArgs, 'clientId' | 'update'>
	>;
	updateBlastList?: Resolver<
		ResolversTypes['EmailBlastList'],
		ParentType,
		ContextType,
		RequireFields<MutationUpdateBlastListArgs, 'id'>
	>;
	updateEmailGroup?: Resolver<
		ResolversTypes['EmailGroup'],
		ParentType,
		ContextType,
		RequireFields<MutationUpdateEmailGroupArgs, 'id'>
	>;
	updateInterest?: Resolver<
		ResolversTypes['Interest'],
		ParentType,
		ContextType,
		RequireFields<MutationUpdateInterestArgs, 'update'>
	>;
	updatePriceOut?: Resolver<
		ResolversTypes['PriceOut'],
		ParentType,
		ContextType,
		RequireFields<MutationUpdatePriceOutArgs, 'update'>
	>;
	updateTradeGroup?: Resolver<
		ResolversTypes['TradeGroup'],
		ParentType,
		ContextType,
		RequireFields<MutationUpdateTradeGroupArgs, 'update'>
	>;
	updateUser?: Resolver<
		Maybe<ResolversTypes['Int']>,
		ParentType,
		ContextType,
		RequireFields<MutationUpdateUserArgs, 'userId'>
	>;
};

export interface NonEmptySmallStringScalarConfig
	extends GraphQLScalarTypeConfig<ResolversTypes['NonEmptySmallString'], any> {
	name: 'NonEmptySmallString';
}

export interface NonNegativeFloatScalarConfig
	extends GraphQLScalarTypeConfig<ResolversTypes['NonNegativeFloat'], any> {
	name: 'NonNegativeFloat';
}

export interface OptionalNonEmptySmallStringScalarConfig
	extends GraphQLScalarTypeConfig<ResolversTypes['OptionalNonEmptySmallString'], any> {
	name: 'OptionalNonEmptySmallString';
}

export interface OptionalNonEmptyTextScalarConfig
	extends GraphQLScalarTypeConfig<ResolversTypes['OptionalNonEmptyText'], any> {
	name: 'OptionalNonEmptyText';
}

export interface OptionalPasswordStringScalarConfig
	extends GraphQLScalarTypeConfig<ResolversTypes['OptionalPasswordString'], any> {
	name: 'OptionalPasswordString';
}

export interface OptionalSmallStringScalarConfig
	extends GraphQLScalarTypeConfig<ResolversTypes['OptionalSmallString'], any> {
	name: 'OptionalSmallString';
}

export interface OptionalUInt16ScalarConfig
	extends GraphQLScalarTypeConfig<ResolversTypes['OptionalUInt16'], any> {
	name: 'OptionalUInt16';
}

export type PayoffResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['Payoff'] = ResolversParentTypes['Payoff']
> = {
	capEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	fixedStrikeGroup?: Resolver<Maybe<ResolversTypes['FixedStrikeGroup']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	maturityType?: Resolver<ResolversTypes['MaturityType'], ParentType, ContextType>;
	numberOfStrikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	shortDisplayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	underlyingGroupType?: Resolver<ResolversTypes['UnderlyingGroupType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['Price'] = ResolversParentTypes['Price']
> = {
	price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
	size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
	squareBookingOrderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceOutResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['PriceOut'] = ResolversParentTypes['PriceOut']
> = {
	ask?: Resolver<ResolversTypes['PriceOutPrice'], ParentType, ContextType>;
	bid?: Resolver<ResolversTypes['PriceOutPrice'], ParentType, ContextType>;
	comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	creator?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
	emailBlastList?: Resolver<Maybe<ResolversTypes['EmailBlastList']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	priceOuts?: Resolver<
		Maybe<Array<ResolversTypes['PriceOutSendingResult']>>,
		ParentType,
		ContextType,
		RequireFields<PriceOutPriceOutsArgs, 'includeUnsent'>
	>;
	structure?: Resolver<Maybe<ResolversTypes['InternalStructure']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceOutPriceResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['PriceOutPrice'] = ResolversParentTypes['PriceOutPrice']
> = {
	price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
	size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceOutSendingResultResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['PriceOutSendingResult'] = ResolversParentTypes['PriceOutSendingResult']
> = {
	__resolveType: TypeResolveFn<'PriceOutSent' | 'PriceOutUnsent', ParentType, ContextType>;
	emailGroup?: Resolver<ResolversTypes['EmailGroup'], ParentType, ContextType>;
};

export type PriceOutSentResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['PriceOutSent'] = ResolversParentTypes['PriceOutSent']
> = {
	emailGroup?: Resolver<ResolversTypes['EmailGroup'], ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	sentAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceOutUnsentResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['PriceOutUnsent'] = ResolversParentTypes['PriceOutUnsent']
> = {
	emailGroup?: Resolver<ResolversTypes['EmailGroup'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
	activityOnDate?: Resolver<
		ResolversTypes['Activity'],
		ParentType,
		ContextType,
		RequireFields<QueryActivityOnDateArgs, 'dateTimeMidnight'>
	>;
	awayBrokers?: Resolver<Maybe<Array<ResolversTypes['AwayBroker']>>, ParentType, ContextType>;
	awayTradeGroup?: Resolver<
		Maybe<ResolversTypes['AwayTradeGroup']>,
		ParentType,
		ContextType,
		RequireFields<QueryAwayTradeGroupArgs, 'id'>
	>;
	awayTradeGroups?: Resolver<
		Maybe<Array<ResolversTypes['AwayTradeGroup']>>,
		ParentType,
		ContextType,
		RequireFields<QueryAwayTradeGroupsArgs, 'limit' | 'offset' | 'searchParams'>
	>;
	bank?: Resolver<
		Maybe<ResolversTypes['Bank']>,
		ParentType,
		ContextType,
		RequireFields<QueryBankArgs, 'bankId'>
	>;
	bankLegalEntities?: Resolver<
		Maybe<Array<ResolversTypes['BankLegalEntity']>>,
		ParentType,
		ContextType
	>;
	banks?: Resolver<Maybe<Array<ResolversTypes['Bank']>>, ParentType, ContextType>;
	categories?: Resolver<
		Maybe<Array<ResolversTypes['Category']>>,
		ParentType,
		ContextType,
		RequireFields<QueryCategoriesArgs, 'excludeDisabled'>
	>;
	categoryGroups?: Resolver<Maybe<Array<ResolversTypes['CategoryGroup']>>, ParentType, ContextType>;
	client?: Resolver<
		Maybe<ResolversTypes['Client']>,
		ParentType,
		ContextType,
		RequireFields<QueryClientArgs, 'clientId'>
	>;
	clients?: Resolver<
		Maybe<Array<ResolversTypes['Client']>>,
		ParentType,
		ContextType,
		RequireFields<QueryClientsArgs, 'excludeDisabled'>
	>;
	currencies?: Resolver<Maybe<Array<ResolversTypes['CurrencyInfo']>>, ParentType, ContextType>;
	emailBlastList?: Resolver<
		ResolversTypes['EmailBlastList'],
		ParentType,
		ContextType,
		RequireFields<QueryEmailBlastListArgs, 'id'>
	>;
	emailBlastLists?: Resolver<
		Maybe<Array<ResolversTypes['EmailBlastList']>>,
		ParentType,
		ContextType
	>;
	emailGroup?: Resolver<
		ResolversTypes['EmailGroup'],
		ParentType,
		ContextType,
		RequireFields<QueryEmailGroupArgs, 'id'>
	>;
	emailGroups?: Resolver<Maybe<Array<ResolversTypes['EmailGroup']>>, ParentType, ContextType>;
	export?: Resolver<ResolversTypes['ExportData'], ParentType, ContextType>;
	interest?: Resolver<
		Maybe<ResolversTypes['Interest']>,
		ParentType,
		ContextType,
		RequireFields<QueryInterestArgs, 'id'>
	>;
	interests?: Resolver<
		Maybe<Array<ResolversTypes['Interest']>>,
		ParentType,
		ContextType,
		RequireFields<QueryInterestsArgs, 'includeDisabled' | 'limit' | 'offset' | 'searchParams'>
	>;
	internalBaseStructures?: Resolver<
		Maybe<Array<ResolversTypes['InternalStructure']>>,
		ParentType,
		ContextType
	>;
	internalStructure?: Resolver<
		Maybe<ResolversTypes['InternalStructure']>,
		ParentType,
		ContextType,
		RequireFields<QueryInternalStructureArgs, 'identifier'>
	>;
	knownEmail?: Resolver<
		ResolversTypes['KnownEmail'],
		ParentType,
		ContextType,
		RequireFields<QueryKnownEmailArgs, 'email'>
	>;
	knownEmailAddresses?: Resolver<Array<ResolversTypes['KnownEmail']>, ParentType, ContextType>;
	liveViewStructures?: Resolver<
		Maybe<Array<ResolversTypes['LiveViewStructure']>>,
		ParentType,
		ContextType,
		RequireFields<QueryLiveViewStructuresArgs, 'since'>
	>;
	maturityDateUsage?: Resolver<
		ResolversTypes['Int'],
		ParentType,
		ContextType,
		RequireFields<QueryMaturityDateUsageArgs, 'maturityDate'>
	>;
	me?: Resolver<Maybe<ResolversTypes['UserMe']>, ParentType, ContextType>;
	priceOut?: Resolver<
		Maybe<ResolversTypes['PriceOut']>,
		ParentType,
		ContextType,
		RequireFields<QueryPriceOutArgs, 'id'>
	>;
	priceOuts?: Resolver<
		Maybe<Array<ResolversTypes['PriceOut']>>,
		ParentType,
		ContextType,
		RequireFields<QueryPriceOutsArgs, 'limit' | 'offset' | 'searchParams'>
	>;
	serverInfo?: Resolver<ResolversTypes['ServerInfo'], ParentType, ContextType>;
	squareBookingStructures?: Resolver<
		Maybe<Array<ResolversTypes['SquareBookingStructure']>>,
		ParentType,
		ContextType
	>;
	structure?: Resolver<
		Maybe<ResolversTypes['InternalStructure']>,
		ParentType,
		ContextType,
		RequireFields<QueryStructureArgs, 'compositeIndex'>
	>;
	tradeGroup?: Resolver<
		Maybe<ResolversTypes['TradeGroup']>,
		ParentType,
		ContextType,
		RequireFields<QueryTradeGroupArgs, 'id'>
	>;
	tradeGroups?: Resolver<
		Maybe<Array<ResolversTypes['TradeGroup']>>,
		ParentType,
		ContextType,
		RequireFields<QueryTradeGroupsArgs, 'limit' | 'offset' | 'searchParams'>
	>;
	underlyingBasket?: Resolver<
		Maybe<ResolversTypes['UnderlyingBasket']>,
		ParentType,
		ContextType,
		RequireFields<QueryUnderlyingBasketArgs, 'id'>
	>;
	underlyingBaskets?: Resolver<
		Maybe<Array<ResolversTypes['UnderlyingBasket']>>,
		ParentType,
		ContextType
	>;
	user?: Resolver<
		Maybe<ResolversTypes['User']>,
		ParentType,
		ContextType,
		RequireFields<QueryUserArgs, 'userId'>
	>;
	users?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
};

export type ServerInfoResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['ServerInfo'] = ResolversParentTypes['ServerInfo']
> = {
	isProductionEnvironment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SquareBookingOrderBookResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['SquareBookingOrderBook'] = ResolversParentTypes['SquareBookingOrderBook']
> = {
	squareBookingId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SquareBookingStructureResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['SquareBookingStructure'] = ResolversParentTypes['SquareBookingStructure']
> = {
	createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	firstImportedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	importedName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	internalStructure?: Resolver<Maybe<ResolversTypes['InternalStructure']>, ParentType, ContextType>;
	isin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	lastImportedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	lastOrderBook?: Resolver<
		Maybe<ResolversTypes['SquareBookingOrderBook']>,
		ParentType,
		ContextType
	>;
	squareBookingCategoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	squareBookingId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	tradeGroups?: Resolver<
		Maybe<Array<ResolversTypes['TradeGroup']>>,
		ParentType,
		ContextType,
		RequireFields<SquareBookingStructureTradeGroupsArgs, 'beforeDate' | 'limitLatest'>
	>;
	updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StructureIdentifierResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['StructureIdentifier'] = ResolversParentTypes['StructureIdentifier']
> = {
	cap?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
	internalId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	internalStructure?: Resolver<Maybe<ResolversTypes['InternalStructure']>, ParentType, ContextType>;
	maturityDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
	maturityMonths?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TradeResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['Trade'] = ResolversParentTypes['Trade']
> = {
	buyer?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType>;
	buyerBankEntity?: Resolver<Maybe<ResolversTypes['BankLegalEntity']>, ParentType, ContextType>;
	comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
	seller?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType>;
	sellerBankEntity?: Resolver<Maybe<ResolversTypes['BankLegalEntity']>, ParentType, ContextType>;
	size?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
	squareBookingBuyOrderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
	squareBookingSellOrderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
	tradedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TradeGroupResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['TradeGroup'] = ResolversParentTypes['TradeGroup']
> = {
	comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	creator?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
	size?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
	squareBookingStructure?: Resolver<
		Maybe<ResolversTypes['SquareBookingStructure']>,
		ParentType,
		ContextType
	>;
	structure?: Resolver<Maybe<ResolversTypes['InternalStructure']>, ParentType, ContextType>;
	trades?: Resolver<Maybe<Array<ResolversTypes['Trade']>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UInt16ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UInt16'], any> {
	name: 'UInt16';
}

export interface UInt24ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UInt24'], any> {
	name: 'UInt24';
}

export interface UInt32ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UInt32'], any> {
	name: 'UInt32';
}

export type UnderlyingResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['Underlying'] = ResolversParentTypes['Underlying']
> = {
	comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	figi?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	isin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	shareClassFigi?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	squareBookingTicker?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	stockName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnderlyingBasketResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['UnderlyingBasket'] = ResolversParentTypes['UnderlyingBasket']
> = {
	comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	lastImportedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
	underlyings?: Resolver<Maybe<Array<ResolversTypes['Underlying']>>, ParentType, ContextType>;
	updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']
> = {
	active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	disabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	email?: Resolver<ResolversTypes['EmailAddress'], ParentType, ContextType>;
	emailIsVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	scopeTypes?: Resolver<Maybe<Array<ResolversTypes['ScopeType']>>, ParentType, ContextType>;
	scopes?: Resolver<Maybe<Array<ResolversTypes['UserScope']>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserMeResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['UserMe'] = ResolversParentTypes['UserMe']
> = {
	user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
	userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	webFrontendSettings?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserScopeResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['UserScope'] = ResolversParentTypes['UserScope']
> = {
	scopeInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	scopeType?: Resolver<ResolversTypes['ScopeType'], ParentType, ContextType>;
	since?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
	Activity?: ActivityResolvers<ContextType>;
	AwayBroker?: AwayBrokerResolvers<ContextType>;
	AwayTrade?: AwayTradeResolvers<ContextType>;
	AwayTradeGroup?: AwayTradeGroupResolvers<ContextType>;
	Bank?: BankResolvers<ContextType>;
	BankLegalEntity?: BankLegalEntityResolvers<ContextType>;
	Category?: CategoryResolvers<ContextType>;
	CategoryGroup?: CategoryGroupResolvers<ContextType>;
	CategoryNotification?: CategoryNotificationResolvers<ContextType>;
	Client?: ClientResolvers<ContextType>;
	Country?: CountryResolvers<ContextType>;
	CurrencyInfo?: CurrencyInfoResolvers<ContextType>;
	Date?: GraphQLScalarType;
	DateTime?: GraphQLScalarType;
	DateTimeRange?: DateTimeRangeResolvers<ContextType>;
	EmailAddress?: GraphQLScalarType;
	EmailBlastList?: EmailBlastListResolvers<ContextType>;
	EmailGroup?: EmailGroupResolvers<ContextType>;
	ExportData?: ExportDataResolvers<ContextType>;
	FirebaseCustomTokenResponse?: FirebaseCustomTokenResponseResolvers<ContextType>;
	FixedStrikeGroup?: FixedStrikeGroupResolvers<ContextType>;
	Interest?: InterestResolvers<ContextType>;
	InternalStructure?: InternalStructureResolvers<ContextType>;
	JSONObject?: GraphQLScalarType;
	KnownAbsoluteMaturities?: KnownAbsoluteMaturitiesResolvers<ContextType>;
	KnownEmail?: KnownEmailResolvers<ContextType>;
	KnownMaturities?: KnownMaturitiesResolvers<ContextType>;
	KnownRelativeMaturities?: KnownRelativeMaturitiesResolvers<ContextType>;
	KnownUnderlyingDates?: KnownUnderlyingDatesResolvers<ContextType>;
	LastPrint?: LastPrintResolvers<ContextType>;
	LiveViewStructure?: LiveViewStructureResolvers<ContextType>;
	Maturity?: MaturityResolvers<ContextType>;
	MaturityDate?: MaturityDateResolvers<ContextType>;
	MaturityRelative?: MaturityRelativeResolvers<ContextType>;
	Mutation?: MutationResolvers<ContextType>;
	NonEmptySmallString?: GraphQLScalarType;
	NonNegativeFloat?: GraphQLScalarType;
	OptionalNonEmptySmallString?: GraphQLScalarType;
	OptionalNonEmptyText?: GraphQLScalarType;
	OptionalPasswordString?: GraphQLScalarType;
	OptionalSmallString?: GraphQLScalarType;
	OptionalUInt16?: GraphQLScalarType;
	Payoff?: PayoffResolvers<ContextType>;
	Price?: PriceResolvers<ContextType>;
	PriceOut?: PriceOutResolvers<ContextType>;
	PriceOutPrice?: PriceOutPriceResolvers<ContextType>;
	PriceOutSendingResult?: PriceOutSendingResultResolvers<ContextType>;
	PriceOutSent?: PriceOutSentResolvers<ContextType>;
	PriceOutUnsent?: PriceOutUnsentResolvers<ContextType>;
	Query?: QueryResolvers<ContextType>;
	ServerInfo?: ServerInfoResolvers<ContextType>;
	SquareBookingOrderBook?: SquareBookingOrderBookResolvers<ContextType>;
	SquareBookingStructure?: SquareBookingStructureResolvers<ContextType>;
	StructureIdentifier?: StructureIdentifierResolvers<ContextType>;
	Trade?: TradeResolvers<ContextType>;
	TradeGroup?: TradeGroupResolvers<ContextType>;
	UInt16?: GraphQLScalarType;
	UInt24?: GraphQLScalarType;
	UInt32?: GraphQLScalarType;
	Underlying?: UnderlyingResolvers<ContextType>;
	UnderlyingBasket?: UnderlyingBasketResolvers<ContextType>;
	User?: UserResolvers<ContextType>;
	UserMe?: UserMeResolvers<ContextType>;
	UserScope?: UserScopeResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
	auth?: AuthDirectiveResolver<any, any, ContextType>;
	constraint?: ConstraintDirectiveResolver<any, any, ContextType>;
};

export type CreateBlastListMutationVariables = Exact<{
	name: Scalars['String'];
}>;

export type CreateBlastListMutation = {
	__typename?: 'Mutation';
	createBlastList: { __typename?: 'EmailBlastList'; id: number; displayName: string };
};

export type CreateEmailGroupMutationVariables = Exact<{
	groupName: Scalars['String'];
	bankId?: InputMaybe<Scalars['Int']>;
	appendToBlastListId: Scalars['Int'];
	emails?: InputMaybe<Array<Scalars['EmailAddress']> | Scalars['EmailAddress']>;
}>;

export type CreateEmailGroupMutation = {
	__typename?: 'Mutation';
	createEmailGroup: { __typename?: 'EmailGroup'; id: number };
};

export type UpdateBlastListNameMutationVariables = Exact<{
	updateBlastListId: Scalars['Int'];
	newName: Scalars['String'];
}>;

export type UpdateBlastListNameMutation = {
	__typename?: 'Mutation';
	updateBlastList: { __typename?: 'EmailBlastList'; id: number; displayName: string };
};

export type UpdateEmailGroupMutationVariables = Exact<{
	emailGroupId: Scalars['Int'];
	newGroupName?: InputMaybe<Scalars['String']>;
	newEmails?: InputMaybe<Array<Scalars['EmailAddress']> | Scalars['EmailAddress']>;
	newBankId?: InputMaybe<Scalars['Int']>;
}>;

export type UpdateEmailGroupMutation = {
	__typename?: 'Mutation';
	updateEmailGroup: {
		__typename?: 'EmailGroup';
		id: number;
		groupName: string;
		emails?: Array<{ __typename?: 'KnownEmail'; id: number; email: string }> | null;
		bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
	};
};

export type DeleteEmailGroupMutationVariables = Exact<{
	emailGroupIdToDelete: Scalars['Int'];
}>;

export type DeleteEmailGroupMutation = {
	__typename?: 'Mutation';
	deleteEmailGroup?: boolean | null;
};

export type DeleteBlastListMutationVariables = Exact<{
	blastListIdToDelete: Scalars['Int'];
}>;

export type DeleteBlastListMutation = { __typename?: 'Mutation'; deleteBlastList?: boolean | null };

export type UpdateBankClientMutationVariables = Exact<{
	clientId: Scalars['Int'];
	update: ClientUpdate;
}>;

export type UpdateBankClientMutation = {
	__typename?: 'Mutation';
	updateBankClient: {
		__typename?: 'Client';
		id: number;
		squareBookingUpdatedAt: string;
		firstName?: string | null;
		lastName?: string | null;
		email?: string | null;
		isDisabled: boolean;
		comments?: string | null;
		birthDate?: string | null;
		backOfficeEmail?: string | null;
		middleOfficeEmail?: string | null;
		bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
	};
};

export type AuthorizeDashboardAccessMutationVariables = Exact<{ [key: string]: never }>;

export type AuthorizeDashboardAccessMutation = {
	__typename?: 'Mutation';
	signDashboardAuth: string;
};

export type GenerateFirebaseCustomTokenMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateFirebaseCustomTokenMutation = {
	__typename?: 'Mutation';
	firebaseAuth: {
		__typename?: 'FirebaseCustomTokenResponse';
		customToken: string;
		categoryNotifications?: Array<{
			__typename?: 'CategoryNotification';
			categoryGroupId: number;
			payoffId: number;
			updatesCollectionPath?: string | null;
			recallsCollectionPath?: string | null;
		}> | null;
	};
};

export type CreateInterestMutationVariables = Exact<{
	newInterest: NewInterest;
}>;

export type CreateInterestMutation = {
	__typename?: 'Mutation';
	createInterest: { __typename?: 'Interest'; id: number; createdAt: string };
};

export type ToggleInterestDisabledStatusMutationVariables = Exact<{
	interestId: Scalars['Int'];
	disable: Scalars['Boolean'];
}>;

export type ToggleInterestDisabledStatusMutation = {
	__typename?: 'Mutation';
	toggleInterestDisabledStatus: { __typename?: 'Interest'; id: number };
};

export type DeleteInterestMutationVariables = Exact<{
	interestId: Scalars['Int'];
}>;

export type DeleteInterestMutation = { __typename?: 'Mutation'; deleteInterest: boolean };

export type CreateTradeGroupMutationVariables = Exact<{
	newTradeGroup: NewTradeGroup;
}>;

export type CreateTradeGroupMutation = {
	__typename?: 'Mutation';
	createTradeGroup: { __typename?: 'TradeGroup'; id: number; createdAt: string };
};

export type DeleteTradeGroupMutationVariables = Exact<{
	tradeGroupId: Scalars['Int'];
}>;

export type DeleteTradeGroupMutation = { __typename?: 'Mutation'; deleteTradeGroup: boolean };

export type CreateAwayTradeGroupMutationVariables = Exact<{
	newAwayTradeGroup: NewAwayTradeGroup;
}>;

export type CreateAwayTradeGroupMutation = {
	__typename?: 'Mutation';
	createAwayTradeGroup: { __typename?: 'AwayTradeGroup'; id: number; createdAt: string };
};

export type DeleteAwayTradeGroupMutationVariables = Exact<{
	awayTradeGroupId: Scalars['Int'];
}>;

export type DeleteAwayTradeGroupMutation = {
	__typename?: 'Mutation';
	deleteAwayTradeGroup: boolean;
};

export type CreatePriceOutMutationVariables = Exact<{
	newPriceOut: NewPriceOut;
	sending?: InputMaybe<SendPriceOut>;
}>;

export type CreatePriceOutMutation = {
	__typename?: 'Mutation';
	createPriceOut: { __typename?: 'PriceOut'; id: number };
};

export type DeletePriceOutMutationVariables = Exact<{
	priceOutId: Scalars['Int'];
}>;

export type DeletePriceOutMutation = { __typename?: 'Mutation'; deletePriceOut: boolean };

export type UpdateInterestMutationVariables = Exact<{
	update: InterestUpdate;
}>;

export type UpdateInterestMutation = {
	__typename?: 'Mutation';
	updateInterest: { __typename?: 'Interest'; id: number };
};

export type UpdateAwayTradeGroupMutationVariables = Exact<{
	update: AwayTradeGroupUpdate;
}>;

export type UpdateAwayTradeGroupMutation = {
	__typename?: 'Mutation';
	updateAwayTradeGroup: { __typename?: 'AwayTradeGroup'; id: number };
};

export type UpdateTradeGroupMutationVariables = Exact<{
	update: TradeGroupUpdate;
}>;

export type UpdateTradeGroupMutation = {
	__typename?: 'Mutation';
	updateTradeGroup: { __typename?: 'TradeGroup'; id: number };
};

export type UpdatePriceOutMutationVariables = Exact<{
	update: PriceOutUpdate;
}>;

export type UpdatePriceOutMutation = {
	__typename?: 'Mutation';
	updatePriceOut: { __typename?: 'PriceOut'; id: number };
};

export type LogInMutationVariables = Exact<{
	email: Scalars['EmailAddress'];
	password: Scalars['String'];
}>;

export type LogInMutation = {
	__typename?: 'Mutation';
	logIn: {
		__typename?: 'User';
		id: number;
		email: string;
		displayName: string;
		scopeTypes?: Array<ScopeType> | null;
	};
};

export type LogOutMutationVariables = Exact<{ [key: string]: never }>;

export type LogOutMutation = { __typename?: 'Mutation'; logOut?: boolean | null };

export type RecallStructureMutationVariables = Exact<{
	structureIdentifier: StructureIdentifierInput;
}>;

export type RecallStructureMutation = { __typename?: 'Mutation'; recallStructure?: boolean | null };

export type SubmitNewPasswordMutationVariables = Exact<{
	email: Scalars['String'];
	newPassword: Scalars['String'];
	token: Scalars['String'];
}>;

export type SubmitNewPasswordMutation = {
	__typename?: 'Mutation';
	submitNewPassword?: boolean | null;
};

export type PostTradeGroupToSquareBookingMutationVariables = Exact<{
	tradeGroupId: Scalars['Int'];
	registerTradeFromSellSide: Scalars['Boolean'];
}>;

export type PostTradeGroupToSquareBookingMutation = {
	__typename?: 'Mutation';
	postTradeGroupToSquareBooking: { __typename?: 'TradeGroup'; id: number };
};

export type PostInterestToSquareBookingMutationVariables = Exact<{
	interestId: Scalars['Int'];
	legalEntityId: Scalars['Int'];
	squareBookingStructureId: Scalars['Int'];
}>;

export type PostInterestToSquareBookingMutation = {
	__typename?: 'Mutation';
	postInterestToSquareBooking: { __typename?: 'Interest'; id: number };
};

export type SyncBanksMutationVariables = Exact<{
	forceAll: Scalars['Boolean'];
}>;

export type SyncBanksMutation = { __typename?: 'Mutation'; syncBanks?: boolean | null };

export type SyncClientsMutationVariables = Exact<{
	forceAll: Scalars['Boolean'];
}>;

export type SyncClientsMutation = { __typename?: 'Mutation'; syncClients?: boolean | null };

export type SyncUnderlyingsMutationVariables = Exact<{
	forceAll: Scalars['Boolean'];
}>;

export type SyncUnderlyingsMutation = { __typename?: 'Mutation'; syncUnderlyings?: boolean | null };

export type SyncStructuresMutationVariables = Exact<{
	forceAll: Scalars['Boolean'];
}>;

export type SyncStructuresMutation = { __typename?: 'Mutation'; syncStructures?: boolean | null };

export type CreateUserMutationVariables = Exact<{
	newUser: NewUser;
}>;

export type CreateUserMutation = {
	__typename?: 'Mutation';
	createUser: { __typename?: 'User'; id: number };
};

export type UpdateUserMutationVariables = Exact<{
	userId: Scalars['Int'];
	newDisplayName?: InputMaybe<Scalars['String']>;
	newEmail?: InputMaybe<Scalars['String']>;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation'; updateUser?: number | null };

export type RequestPasswordResetMutationVariables = Exact<{
	userIdToReset: Scalars['Int'];
}>;

export type RequestPasswordResetMutation = {
	__typename?: 'Mutation';
	requestPasswordReset?: string | null;
};

export type EnableUserMutationVariables = Exact<{
	userId: Scalars['Int'];
}>;

export type EnableUserMutation = { __typename?: 'Mutation'; enableUser?: number | null };

export type DisableUserMutationVariables = Exact<{
	userId: Scalars['Int'];
}>;

export type DisableUserMutation = { __typename?: 'Mutation'; disableUser?: number | null };

export type ReplaceUserScopesMutationVariables = Exact<{
	newScopes: Array<NewScopeWithExtra> | NewScopeWithExtra;
	userId: Scalars['Int'];
}>;

export type ReplaceUserScopesMutation = {
	__typename?: 'Mutation';
	replaceUserScopes?: boolean | null;
};

export type DeleteUserMutationVariables = Exact<{
	userId: Scalars['Int'];
}>;

export type DeleteUserMutation = { __typename?: 'Mutation'; deleteUser?: number | null };

export type UpdateSettingsMutationVariables = Exact<{
	newWebFrontendSettings?: InputMaybe<Scalars['JSONObject']>;
}>;

export type UpdateSettingsMutation = { __typename?: 'Mutation'; storeNewSettings?: boolean | null };

export type GetActivityOnDateQueryVariables = Exact<{
	dateTimeMidnight: Scalars['DateTime'];
}>;

export type GetActivityOnDateQuery = {
	__typename?: 'Query';
	activity: {
		__typename?: 'Activity';
		dateTimeRange: { __typename?: 'DateTimeRange'; start: string; end: string };
		structureIdentifiers?: Array<{
			__typename?: 'StructureIdentifier';
			internalId: number;
			maturityDate?: string | null;
			maturityMonths?: number | null;
			cap?: number | null;
			internalStructure?: {
				__typename?: 'InternalStructure';
				id: number;
				strikes?: Array<number> | null;
				notionalCurrency: Currency;
				underlyingBasket?: { __typename?: 'UnderlyingBasket'; id: number } | null;
				category?: { __typename?: 'Category'; categoryGroupId: number; payoffId: number } | null;
			} | null;
		}> | null;
	};
};

export type AllAwayBrokersQueryVariables = Exact<{ [key: string]: never }>;

export type AllAwayBrokersQuery = {
	__typename?: 'Query';
	awayBrokers?: Array<{ __typename?: 'AwayBroker'; displayName: string }> | null;
};

export type AllBanksQueryVariables = Exact<{
	includeClients?: Scalars['Boolean'];
	includeLegalEntities?: Scalars['Boolean'];
}>;

export type AllBanksQuery = {
	__typename?: 'Query';
	banks?: Array<{
		__typename?: 'Bank';
		id: number;
		displayName: string;
		clients?: Array<{
			__typename?: 'Client';
			id: number;
			isDisabled: boolean;
			firstName?: string | null;
			lastName?: string | null;
		}> | null;
		legalEntities?: Array<{
			__typename?: 'BankLegalEntity';
			id: number;
			legalName?: string | null;
			country?: { __typename?: 'Country'; shortName: string } | null;
		}> | null;
	}> | null;
};

export type GetAllCategoriesGroupedQueryVariables = Exact<{
	withKnownUnderlyingDates: Scalars['Boolean'];
	withPayoffExtraData: Scalars['Boolean'];
	withFormatHints: Scalars['Boolean'];
}>;

export type GetAllCategoriesGroupedQuery = {
	__typename?: 'Query';
	categoryGroups?: Array<{
		__typename?: 'CategoryGroup';
		id: number;
		displayName: string;
		categories?: Array<{
			__typename?: 'Category';
			categoryGroupId: number;
			payoffId: number;
			disabled: boolean;
			canBeSentAsEmail: boolean;
			formatHint?: string;
			payoff?: {
				__typename?: 'Payoff';
				id: number;
				displayName: string;
				shortDisplayName: string;
				underlyingGroupType?: UnderlyingGroupType;
				maturityType?: MaturityType;
				numberOfStrikes?: number;
				capEnabled?: boolean;
				fixedStrikeGroup?: { __typename?: 'FixedStrikeGroup'; strikes: Array<number> } | null;
			} | null;
			knownUnderlyingDates?: Array<{
				__typename?: 'KnownUnderlyingDates';
				underlyingBasketId: number;
				dates?: Array<string> | null;
			}> | null;
		}> | null;
	}> | null;
};

export type GetCategoryFormatHintsAndKnownMaturitiesQueryVariables = Exact<{
	excludeDisabled?: Scalars['Boolean'];
}>;

export type GetCategoryFormatHintsAndKnownMaturitiesQuery = {
	__typename?: 'Query';
	categories?: Array<{
		__typename?: 'Category';
		categoryGroupId: number;
		payoffId: number;
		disabled: boolean;
		formatHint: string;
		payoff?: {
			__typename?: 'Payoff';
			id: number;
			underlyingGroupType: UnderlyingGroupType;
			maturityType: MaturityType;
			numberOfStrikes: number;
			capEnabled: boolean;
			fixedStrikeGroup?: { __typename?: 'FixedStrikeGroup'; strikes: Array<number> } | null;
		} | null;
		knownUnderlyingDates?: Array<{
			__typename?: 'KnownUnderlyingDates';
			underlyingBasketId: number;
			dates?: Array<string> | null;
		}> | null;
	}> | null;
};

export type GetAllCategoriesQueryVariables = Exact<{
	excludeDisabled?: Scalars['Boolean'];
}>;

export type GetAllCategoriesQuery = {
	__typename?: 'Query';
	categories?: Array<{
		__typename?: 'Category';
		categoryGroupId: number;
		payoffId: number;
		disabled: boolean;
		formatHint: string;
		payoff?: {
			__typename?: 'Payoff';
			id: number;
			underlyingGroupType: UnderlyingGroupType;
			maturityType: MaturityType;
			numberOfStrikes: number;
			capEnabled: boolean;
			fixedStrikeGroup?: { __typename?: 'FixedStrikeGroup'; strikes: Array<number> } | null;
		} | null;
		knownUnderlyingDates?: Array<{
			__typename?: 'KnownUnderlyingDates';
			underlyingBasketId: number;
			dates?: Array<string> | null;
		}> | null;
	}> | null;
};

export type GetAllCategorySettingsQueryVariables = Exact<{
	excludeDisabled?: Scalars['Boolean'];
}>;

export type GetAllCategorySettingsQuery = {
	__typename?: 'Query';
	categories?: Array<{
		__typename?: 'Category';
		categoryGroupId: number;
		payoffId: number;
		disabled: boolean;
		categoryGroup?: { __typename?: 'CategoryGroup'; displayName: string } | null;
		payoff?: {
			__typename?: 'Payoff';
			displayName: string;
			shortDisplayName: string;
			underlyingGroupType: UnderlyingGroupType;
			maturityType: MaturityType;
			numberOfStrikes: number;
			capEnabled: boolean;
		} | null;
	}> | null;
};

export type GetAllCurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCurrenciesQuery = {
	__typename?: 'Query';
	allCurrencies?: Array<{
		__typename?: 'CurrencyInfo';
		alphabeticCode: Currency;
		enabled: boolean;
	}> | null;
};

export type GetAllBlastListsQueryVariables = Exact<{
	includeEmailGroups?: Scalars['Boolean'];
	includeEmailsInGroups?: Scalars['Boolean'];
}>;

export type GetAllBlastListsQuery = {
	__typename?: 'Query';
	emailBlastLists?: Array<{
		__typename?: 'EmailBlastList';
		id: number;
		displayName: string;
		emailGroups?: Array<{
			__typename?: 'EmailGroup';
			id: number;
			groupName: string;
			bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			emails?: Array<{ __typename?: 'KnownEmail'; id: number; email: string }> | null;
		}> | null;
	}> | null;
};

export type GetBlastListQueryVariables = Exact<{
	blastListId: Scalars['Int'];
}>;

export type GetBlastListQuery = {
	__typename?: 'Query';
	emailBlastList: {
		__typename?: 'EmailBlastList';
		id: number;
		displayName: string;
		emailGroups?: Array<{
			__typename?: 'EmailGroup';
			id: number;
			groupName: string;
			bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
		}> | null;
	};
};

export type GetEmailGroupQueryVariables = Exact<{
	emailGroupId: Scalars['Int'];
}>;

export type GetEmailGroupQuery = {
	__typename?: 'Query';
	emailGroup: {
		__typename?: 'EmailGroup';
		id: number;
		createdAt: string;
		updatedAt: string;
		groupName: string;
		bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
		emails?: Array<{
			__typename?: 'KnownEmail';
			id: number;
			email: string;
			countOfClientsUsingThisAddress?: number | null;
		}> | null;
	};
};

export type GetAllClientsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllClientsQuery = {
	__typename?: 'Query';
	clients?: Array<{
		__typename?: 'Client';
		id: number;
		squareBookingUpdatedAt: string;
		firstName?: string | null;
		lastName?: string | null;
		email?: string | null;
		isDisabled: boolean;
		comments?: string | null;
		birthDate?: string | null;
		backOfficeEmail?: string | null;
		middleOfficeEmail?: string | null;
		bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
	}> | null;
};

export type GetClientQueryVariables = Exact<{
	clientId: Scalars['Int'];
}>;

export type GetClientQuery = {
	__typename?: 'Query';
	client?: {
		__typename?: 'Client';
		id: number;
		squareBookingUpdatedAt: string;
		firstName?: string | null;
		lastName?: string | null;
		email?: string | null;
		isDisabled: boolean;
		comments?: string | null;
		birthDate?: string | null;
		backOfficeEmail?: string | null;
		middleOfficeEmail?: string | null;
		bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
	} | null;
};

export type ExportDataQueryVariables = Exact<{
	start?: InputMaybe<Scalars['DateTime']>;
	end?: InputMaybe<Scalars['DateTime']>;
}>;

export type ExportDataQuery = {
	__typename?: 'Query';
	export: {
		__typename?: 'ExportData';
		interests?: Array<{
			__typename?: 'Interest';
			id: number;
			updatedAt: string;
			interestDate: string;
			createdAt: string;
			comments?: string | null;
			isAway: boolean;
			creator?: { __typename?: 'User'; displayName: string } | null;
			structure?: {
				__typename?: 'InternalStructure';
				notionalCurrency: Currency;
				strikes?: Array<number> | null;
				cap?: number | null;
				category?: { __typename?: 'Category'; categoryGroupId: number; payoffId: number } | null;
				underlyingBasket?: {
					__typename?: 'UnderlyingBasket';
					underlyings?: Array<{ __typename?: 'Underlying'; squareBookingTicker: string }> | null;
				} | null;
				maturity?:
					| { __typename?: 'MaturityDate'; expirationDate: string }
					| { __typename?: 'MaturityRelative'; expirationMonths: number }
					| null;
			} | null;
			initiatorBank?: { __typename?: 'Bank'; displayName: string } | null;
			initiatorClient?: {
				__typename?: 'Client';
				firstName?: string | null;
				lastName?: string | null;
			} | null;
			bid?: { __typename?: 'Price'; price?: number | null; size?: number | null } | null;
			ask?: { __typename?: 'Price'; price?: number | null; size?: number | null } | null;
			awayBroker?: { __typename?: 'AwayBroker'; displayName: string } | null;
		}> | null;
		tradeGroups?: Array<{
			__typename?: 'TradeGroup';
			id: number;
			createdAt: string;
			comments?: string | null;
			creator?: { __typename?: 'User'; displayName: string } | null;
			structure?: {
				__typename?: 'InternalStructure';
				notionalCurrency: Currency;
				strikes?: Array<number> | null;
				cap?: number | null;
				category?: { __typename?: 'Category'; categoryGroupId: number; payoffId: number } | null;
				underlyingBasket?: {
					__typename?: 'UnderlyingBasket';
					underlyings?: Array<{ __typename?: 'Underlying'; squareBookingTicker: string }> | null;
				} | null;
				maturity?:
					| { __typename?: 'MaturityDate'; expirationDate: string }
					| { __typename?: 'MaturityRelative'; expirationMonths: number }
					| null;
			} | null;
			trades?: Array<{
				__typename?: 'Trade';
				tradedAt: string;
				comments?: string | null;
				price: number;
				size: number;
				buyerBankEntity?: {
					__typename?: 'BankLegalEntity';
					bank?: { __typename?: 'Bank'; displayName: string } | null;
				} | null;
				buyer?: {
					__typename?: 'Client';
					firstName?: string | null;
					lastName?: string | null;
				} | null;
				sellerBankEntity?: {
					__typename?: 'BankLegalEntity';
					bank?: { __typename?: 'Bank'; displayName: string } | null;
				} | null;
				seller?: {
					__typename?: 'Client';
					firstName?: string | null;
					lastName?: string | null;
				} | null;
			}> | null;
		}> | null;
		awayTradeGroups?: Array<{
			__typename?: 'AwayTradeGroup';
			id: number;
			createdAt: string;
			comments?: string | null;
			creator?: { __typename?: 'User'; displayName: string } | null;
			structure?: {
				__typename?: 'InternalStructure';
				notionalCurrency: Currency;
				strikes?: Array<number> | null;
				cap?: number | null;
				category?: { __typename?: 'Category'; categoryGroupId: number; payoffId: number } | null;
				underlyingBasket?: {
					__typename?: 'UnderlyingBasket';
					underlyings?: Array<{ __typename?: 'Underlying'; squareBookingTicker: string }> | null;
				} | null;
				maturity?:
					| { __typename?: 'MaturityDate'; expirationDate: string }
					| { __typename?: 'MaturityRelative'; expirationMonths: number }
					| null;
			} | null;
			awayTrades?: Array<{
				__typename?: 'AwayTrade';
				tradedAt: string;
				comments?: string | null;
				price?: number | null;
				size?: number | null;
				buyerBank?: { __typename?: 'Bank'; displayName: string } | null;
				buyer?: {
					__typename?: 'Client';
					firstName?: string | null;
					lastName?: string | null;
				} | null;
				sellerBank?: { __typename?: 'Bank'; displayName: string } | null;
				seller?: {
					__typename?: 'Client';
					firstName?: string | null;
					lastName?: string | null;
				} | null;
			}> | null;
			awayBroker?: { __typename?: 'AwayBroker'; displayName: string } | null;
		}> | null;
	};
};

export type GetInterestEditableDataQueryVariables = Exact<{
	interestId: Scalars['Int'];
}>;

export type GetInterestEditableDataQuery = {
	__typename?: 'Query';
	editableObject?: {
		__typename: 'Interest';
		id: number;
		interestDate: string;
		comments?: string | null;
		providerSource?: string | null;
		providerMedia?: string | null;
		isAway: boolean;
		structure?: {
			__typename?: 'InternalStructure';
			id: number;
			notionalCurrency: Currency;
			strikes?: Array<number> | null;
			cap?: number | null;
			category?: { __typename?: 'Category'; categoryGroupId: number; payoffId: number } | null;
			underlyingBasket?: { __typename?: 'UnderlyingBasket'; id: number } | null;
			maturity?:
				| { __typename: 'MaturityDate'; expirationDate: string }
				| { __typename: 'MaturityRelative'; expirationMonths: number }
				| null;
		} | null;
		initiatorBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
		initiatorClient?: { __typename?: 'Client'; id: number } | null;
		bid?: { __typename?: 'Price'; price?: number | null; size?: number | null } | null;
		ask?: { __typename?: 'Price'; price?: number | null; size?: number | null } | null;
		awayBroker?: { __typename?: 'AwayBroker'; id: number; displayName: string } | null;
	} | null;
};

export type GetAwayTradeGroupEditableDataQueryVariables = Exact<{
	awayTradeGroupId: Scalars['Int'];
}>;

export type GetAwayTradeGroupEditableDataQuery = {
	__typename?: 'Query';
	editableObject?: {
		__typename: 'AwayTradeGroup';
		id: number;
		price?: number | null;
		size?: number | null;
		comments?: string | null;
		structure?: {
			__typename?: 'InternalStructure';
			id: number;
			notionalCurrency: Currency;
			strikes?: Array<number> | null;
			cap?: number | null;
			category?: { __typename?: 'Category'; categoryGroupId: number; payoffId: number } | null;
			underlyingBasket?: { __typename?: 'UnderlyingBasket'; id: number } | null;
			maturity?:
				| { __typename: 'MaturityDate'; expirationDate: string }
				| { __typename: 'MaturityRelative'; expirationMonths: number }
				| null;
		} | null;
		awayTrades?: Array<{
			__typename?: 'AwayTrade';
			id: number;
			tradedAt: string;
			price?: number | null;
			size?: number | null;
			buyer?: {
				__typename?: 'Client';
				id: number;
				firstName?: string | null;
				lastName?: string | null;
			} | null;
			buyerBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			seller?: {
				__typename?: 'Client';
				id: number;
				firstName?: string | null;
				lastName?: string | null;
			} | null;
			sellerBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
		}> | null;
		awayBroker?: { __typename?: 'AwayBroker'; id: number; displayName: string } | null;
	} | null;
};

export type GetTradeGroupEditableDataQueryVariables = Exact<{
	tradeGroupId: Scalars['Int'];
}>;

export type GetTradeGroupEditableDataQuery = {
	__typename?: 'Query';
	editableObject?: {
		__typename: 'TradeGroup';
		id: number;
		price: number;
		size: number;
		comments?: string | null;
		structure?: {
			__typename?: 'InternalStructure';
			id: number;
			notionalCurrency: Currency;
			strikes?: Array<number> | null;
			cap?: number | null;
			category?: { __typename?: 'Category'; categoryGroupId: number; payoffId: number } | null;
			underlyingBasket?: { __typename?: 'UnderlyingBasket'; id: number } | null;
			maturity?:
				| { __typename: 'MaturityDate'; expirationDate: string }
				| { __typename: 'MaturityRelative'; expirationMonths: number }
				| null;
		} | null;
		trades?: Array<{
			__typename?: 'Trade';
			id: number;
			tradedAt: string;
			price: number;
			size: number;
			buyer?: {
				__typename?: 'Client';
				id: number;
				firstName?: string | null;
				lastName?: string | null;
			} | null;
			buyerBankEntity?: {
				__typename?: 'BankLegalEntity';
				id: number;
				bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			} | null;
			seller?: {
				__typename?: 'Client';
				id: number;
				firstName?: string | null;
				lastName?: string | null;
			} | null;
			sellerBankEntity?: {
				__typename?: 'BankLegalEntity';
				id: number;
				bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			} | null;
		}> | null;
		squareBookingStructure?: {
			__typename?: 'SquareBookingStructure';
			squareBookingId: number;
		} | null;
	} | null;
};

export type GetPriceOutEditableDataQueryVariables = Exact<{
	priceOutId: Scalars['Int'];
}>;

export type GetPriceOutEditableDataQuery = {
	__typename?: 'Query';
	editableObject?: {
		__typename: 'PriceOut';
		id: number;
		comments?: string | null;
		structure?: {
			__typename?: 'InternalStructure';
			id: number;
			notionalCurrency: Currency;
			strikes?: Array<number> | null;
			cap?: number | null;
			category?: { __typename?: 'Category'; categoryGroupId: number; payoffId: number } | null;
			underlyingBasket?: { __typename?: 'UnderlyingBasket'; id: number } | null;
			maturity?:
				| { __typename: 'MaturityDate'; expirationDate: string }
				| { __typename: 'MaturityRelative'; expirationMonths: number }
				| null;
		} | null;
		ask: { __typename?: 'PriceOutPrice'; price?: number | null; size?: number | null };
		bid: { __typename?: 'PriceOutPrice'; price?: number | null; size?: number | null };
	} | null;
};

export type CommonEditableStructureAttributesFragment = {
	__typename?: 'InternalStructure';
	id: number;
	notionalCurrency: Currency;
	strikes?: Array<number> | null;
	cap?: number | null;
	category?: { __typename?: 'Category'; categoryGroupId: number; payoffId: number } | null;
	underlyingBasket?: { __typename?: 'UnderlyingBasket'; id: number } | null;
	maturity?:
		| { __typename: 'MaturityDate'; expirationDate: string }
		| { __typename: 'MaturityRelative'; expirationMonths: number }
		| null;
};

export type GetInterestQueryVariables = Exact<{
	interestId: Scalars['Int'];
	withStructure?: Scalars['Boolean'];
	withSquareBookingStructures?: Scalars['Boolean'];
	withBankLegalEntities?: Scalars['Boolean'];
}>;

export type GetInterestQuery = {
	__typename?: 'Query';
	interest?: {
		__typename?: 'Interest';
		id: number;
		createdAt: string;
		updatedAt: string;
		interestDate: string;
		disabled: boolean;
		comments?: string | null;
		providerSource?: string | null;
		providerMedia?: string | null;
		isAway: boolean;
		creator?: { __typename?: 'User'; id: number; displayName: string; email: string } | null;
		initiatorBank?: {
			__typename?: 'Bank';
			id: number;
			displayName: string;
			legalEntities?: Array<{
				__typename?: 'BankLegalEntity';
				id: number;
				legalName?: string | null;
				country?: {
					__typename?: 'Country';
					id: number;
					displayName: string;
					shortName: string;
				} | null;
			}> | null;
		} | null;
		initiatorClient?: {
			__typename?: 'Client';
			id: number;
			firstName?: string | null;
			lastName?: string | null;
			email?: string | null;
			bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
		} | null;
		bid?: { __typename?: 'Price'; price?: number | null; size?: number | null } | null;
		ask?: { __typename?: 'Price'; price?: number | null; size?: number | null } | null;
		awayBroker?: { __typename?: 'AwayBroker'; id: number; displayName: string } | null;
		structure?: {
			__typename: 'InternalStructure';
			notionalCurrency: Currency;
			strikes?: Array<number> | null;
			id: number;
			cap?: number | null;
			category?: {
				__typename?: 'Category';
				categoryGroupId: number;
				payoffId: number;
				formatHint: string;
			} | null;
			underlyingBasket?: {
				__typename?: 'UnderlyingBasket';
				id: number;
				underlyings?: Array<{
					__typename?: 'Underlying';
					id: number;
					squareBookingTicker: string;
				}> | null;
			} | null;
			squareBookingStructures?: Array<{
				__typename?: 'SquareBookingStructure';
				squareBookingId: number;
				importedName: string;
				lastOrderBook?: { __typename?: 'SquareBookingOrderBook'; squareBookingId: number } | null;
			}> | null;
			maturity?:
				| { __typename: 'MaturityDate'; expirationDate: string }
				| { __typename: 'MaturityRelative'; expirationMonths: number }
				| null;
		} | null;
	} | null;
};

export type GetPriceOutQueryVariables = Exact<{
	priceOutId: Scalars['Int'];
}>;

export type GetPriceOutQuery = {
	__typename?: 'Query';
	priceOut?: {
		__typename?: 'PriceOut';
		id: number;
		createdAt: string;
		comments?: string | null;
		creator?: { __typename?: 'User'; id: number; email: string; displayName: string } | null;
		ask: { __typename?: 'PriceOutPrice'; price?: number | null; size?: number | null };
		bid: { __typename?: 'PriceOutPrice'; price?: number | null; size?: number | null };
		priceOuts?: Array<
			| {
					__typename: 'PriceOutSent';
					id: number;
					sentAt: string;
					emailGroup: {
						__typename?: 'EmailGroup';
						id: number;
						groupName: string;
						bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
					};
			  }
			| {
					__typename: 'PriceOutUnsent';
					emailGroup: {
						__typename?: 'EmailGroup';
						id: number;
						groupName: string;
						bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
					};
			  }
		> | null;
		emailBlastList?: { __typename?: 'EmailBlastList'; id: number; displayName: string } | null;
	} | null;
};

export type GetTradeGroupQueryVariables = Exact<{
	tradeGroupId: Scalars['Int'];
}>;

export type GetTradeGroupQuery = {
	__typename?: 'Query';
	tradeGroup?: {
		__typename?: 'TradeGroup';
		id: number;
		createdAt: string;
		price: number;
		size: number;
		comments?: string | null;
		creator?: { __typename?: 'User'; email: string; displayName: string } | null;
		squareBookingStructure?: {
			__typename?: 'SquareBookingStructure';
			squareBookingId: number;
			importedName: string;
			lastOrderBook?: { __typename?: 'SquareBookingOrderBook'; squareBookingId: number } | null;
		} | null;
		trades?: Array<{
			__typename?: 'Trade';
			tradedAt: string;
			price: number;
			size: number;
			squareBookingBuyOrderId?: number | null;
			squareBookingSellOrderId?: number | null;
			buyerBankEntity?: { __typename?: 'BankLegalEntity'; legalName?: string | null } | null;
			sellerBankEntity?: { __typename?: 'BankLegalEntity'; legalName?: string | null } | null;
		}> | null;
	} | null;
};

export type SearchInterestsQueryVariables = Exact<{
	searchParams: InterestBookSearchParams;
	includeDisabled?: Scalars['Boolean'];
	offset?: Scalars['Int'];
	limit?: InputMaybe<Scalars['Int']>;
}>;

export type SearchInterestsQuery = {
	__typename?: 'Query';
	interests?: Array<{
		__typename: 'Interest';
		id: number;
		createdAt: string;
		updatedAt: string;
		interestDate: string;
		disabled: boolean;
		comments?: string | null;
		isAway: boolean;
		creator?: { __typename?: 'User'; id: number; displayName: string } | null;
		structure?: {
			__typename?: 'InternalStructure';
			id: number;
			cap?: number | null;
			strikes?: Array<number> | null;
			notionalCurrency: Currency;
			maturity?:
				| { __typename: 'MaturityDate'; expirationDate: string }
				| { __typename: 'MaturityRelative'; expirationMonths: number }
				| null;
			underlyingBasket?: {
				__typename?: 'UnderlyingBasket';
				id: number;
				displayName?: string | null;
				underlyings?: Array<{
					__typename?: 'Underlying';
					id: number;
					squareBookingTicker: string;
					stockName?: string | null;
				}> | null;
			} | null;
			category?: {
				__typename?: 'Category';
				categoryGroupId: number;
				payoffId: number;
				formatHint: string;
			} | null;
		} | null;
		bid?: { __typename?: 'Price'; price?: number | null; size?: number | null } | null;
		ask?: { __typename?: 'Price'; price?: number | null; size?: number | null } | null;
		initiatorBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
		initiatorClient?: {
			__typename?: 'Client';
			id: number;
			firstName?: string | null;
			lastName?: string | null;
		} | null;
		awayBroker?: { __typename?: 'AwayBroker'; id: number; displayName: string } | null;
	}> | null;
};

export type SearchTradeGroupsQueryVariables = Exact<{
	searchParams: InterestBookSearchParams;
	offset?: Scalars['Int'];
	limit?: InputMaybe<Scalars['Int']>;
}>;

export type SearchTradeGroupsQuery = {
	__typename?: 'Query';
	tradeGroups?: Array<{
		__typename: 'TradeGroup';
		id: number;
		createdAt: string;
		price: number;
		size: number;
		comments?: string | null;
		creator?: { __typename?: 'User'; id: number; displayName: string } | null;
		structure?: {
			__typename?: 'InternalStructure';
			id: number;
			cap?: number | null;
			strikes?: Array<number> | null;
			notionalCurrency: Currency;
			maturity?:
				| { __typename: 'MaturityDate'; expirationDate: string }
				| { __typename: 'MaturityRelative'; expirationMonths: number }
				| null;
			underlyingBasket?: {
				__typename?: 'UnderlyingBasket';
				id: number;
				displayName?: string | null;
				underlyings?: Array<{
					__typename?: 'Underlying';
					id: number;
					squareBookingTicker: string;
					stockName?: string | null;
				}> | null;
			} | null;
			category?: {
				__typename?: 'Category';
				categoryGroupId: number;
				payoffId: number;
				formatHint: string;
			} | null;
		} | null;
		trades?: Array<{
			__typename?: 'Trade';
			id: number;
			tradedAt: string;
			buyerBankEntity?: {
				__typename?: 'BankLegalEntity';
				id: number;
				legalName?: string | null;
				bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			} | null;
			sellerBankEntity?: {
				__typename?: 'BankLegalEntity';
				id: number;
				legalName?: string | null;
				bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			} | null;
		}> | null;
	}> | null;
};

export type SearchAwayTradeGroupsQueryVariables = Exact<{
	searchParams: InterestBookSearchParams;
	offset?: Scalars['Int'];
	limit?: InputMaybe<Scalars['Int']>;
}>;

export type SearchAwayTradeGroupsQuery = {
	__typename?: 'Query';
	awayTradeGroups?: Array<{
		__typename: 'AwayTradeGroup';
		id: number;
		createdAt: string;
		price?: number | null;
		size?: number | null;
		comments?: string | null;
		creator?: { __typename?: 'User'; id: number; displayName: string } | null;
		structure?: {
			__typename?: 'InternalStructure';
			id: number;
			cap?: number | null;
			strikes?: Array<number> | null;
			notionalCurrency: Currency;
			maturity?:
				| { __typename: 'MaturityDate'; expirationDate: string }
				| { __typename: 'MaturityRelative'; expirationMonths: number }
				| null;
			underlyingBasket?: {
				__typename?: 'UnderlyingBasket';
				id: number;
				displayName?: string | null;
				underlyings?: Array<{
					__typename?: 'Underlying';
					id: number;
					squareBookingTicker: string;
					stockName?: string | null;
				}> | null;
			} | null;
			category?: {
				__typename?: 'Category';
				categoryGroupId: number;
				payoffId: number;
				formatHint: string;
			} | null;
		} | null;
		awayTrades?: Array<{
			__typename?: 'AwayTrade';
			id: number;
			tradedAt: string;
			price?: number | null;
			buyerBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			sellerBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
		}> | null;
		awayBroker?: { __typename?: 'AwayBroker'; id: number; displayName: string } | null;
	}> | null;
};

export type SearchPriceOutsQueryVariables = Exact<{
	searchParams: InterestBookSearchParams;
	includeUnsent?: Scalars['Boolean'];
	offset?: Scalars['Int'];
	limit?: InputMaybe<Scalars['Int']>;
}>;

export type SearchPriceOutsQuery = {
	__typename?: 'Query';
	priceOuts?: Array<{
		__typename: 'PriceOut';
		id: number;
		createdAt: string;
		comments?: string | null;
		creator?: { __typename?: 'User'; id: number; displayName: string } | null;
		structure?: {
			__typename?: 'InternalStructure';
			id: number;
			cap?: number | null;
			strikes?: Array<number> | null;
			notionalCurrency: Currency;
			maturity?:
				| { __typename: 'MaturityDate'; expirationDate: string }
				| { __typename: 'MaturityRelative'; expirationMonths: number }
				| null;
			underlyingBasket?: {
				__typename?: 'UnderlyingBasket';
				id: number;
				displayName?: string | null;
				underlyings?: Array<{
					__typename?: 'Underlying';
					id: number;
					squareBookingTicker: string;
					stockName?: string | null;
				}> | null;
			} | null;
			category?: {
				__typename?: 'Category';
				categoryGroupId: number;
				payoffId: number;
				formatHint: string;
			} | null;
		} | null;
		ask: { __typename?: 'PriceOutPrice'; price?: number | null; size?: number | null };
		bid: { __typename?: 'PriceOutPrice'; price?: number | null; size?: number | null };
		emailBlastList?: { __typename?: 'EmailBlastList'; id: number; displayName: string } | null;
		priceOuts?: Array<
			| {
					__typename: 'PriceOutSent';
					emailGroup: {
						__typename?: 'EmailGroup';
						id: number;
						createdAt: string;
						updatedAt: string;
						groupName: string;
						bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
					};
			  }
			| {
					__typename: 'PriceOutUnsent';
					emailGroup: {
						__typename?: 'EmailGroup';
						id: number;
						createdAt: string;
						updatedAt: string;
						groupName: string;
						bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
					};
			  }
		> | null;
	}> | null;
};

export type InterestBookStructureFragment = {
	__typename?: 'InternalStructure';
	id: number;
	cap?: number | null;
	strikes?: Array<number> | null;
	notionalCurrency: Currency;
	maturity?:
		| { __typename: 'MaturityDate'; expirationDate: string }
		| { __typename: 'MaturityRelative'; expirationMonths: number }
		| null;
	underlyingBasket?: {
		__typename?: 'UnderlyingBasket';
		id: number;
		displayName?: string | null;
		underlyings?: Array<{
			__typename?: 'Underlying';
			id: number;
			squareBookingTicker: string;
			stockName?: string | null;
		}> | null;
	} | null;
	category?: {
		__typename?: 'Category';
		categoryGroupId: number;
		payoffId: number;
		formatHint: string;
	} | null;
};

export type GetLiveViewQueryVariables = Exact<{
	nbOfLastPrints: Scalars['Int'];
	since?: InputMaybe<Scalars['DateTime']>;
}>;

export type GetLiveViewQuery = {
	__typename?: 'Query';
	structures?: Array<{
		__typename?: 'LiveViewStructure';
		dateTimeRange: { __typename?: 'DateTimeRange'; start: string; end: string };
		structureIdentifier: {
			__typename?: 'StructureIdentifier';
			internalId: number;
			maturityDate?: string | null;
			maturityMonths?: number | null;
			cap?: number | null;
			internalStructure?: {
				__typename?: 'InternalStructure';
				id: number;
				strikes?: Array<number> | null;
				notionalCurrency: Currency;
				underlyingBasket?: {
					__typename?: 'UnderlyingBasket';
					id: number;
					underlyings?: Array<{
						__typename?: 'Underlying';
						id: number;
						squareBookingTicker: string;
					}> | null;
				} | null;
				category?: {
					__typename?: 'Category';
					categoryGroupId: number;
					payoffId: number;
					formatHint: string;
					categoryGroup?: { __typename?: 'CategoryGroup'; id: number; displayName: string } | null;
					payoff?: {
						__typename?: 'Payoff';
						id: number;
						shortDisplayName: string;
						numberOfStrikes: number;
						capEnabled: boolean;
					} | null;
				} | null;
			} | null;
		};
		interestedBuyers?: Array<{ __typename?: 'Bank'; id: number; displayName: string }> | null;
		interestedSellers?: Array<{ __typename?: 'Bank'; id: number; displayName: string }> | null;
		interestedWithoutWay?: Array<{ __typename?: 'Bank'; id: number; displayName: string }> | null;
		lastDayBestAskInterest?: {
			__typename?: 'Interest';
			id: number;
			interestDate: string;
			isAway: boolean;
			ask?: { __typename?: 'Price'; price?: number | null } | null;
			initiatorBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			initiatorClient?: {
				__typename?: 'Client';
				id: number;
				firstName?: string | null;
				lastName?: string | null;
			} | null;
		} | null;
		lastDayBestAwayAsk?: {
			__typename?: 'Interest';
			id: number;
			interestDate: string;
			isAway: boolean;
			ask?: { __typename?: 'Price'; price?: number | null } | null;
			awayBroker?: { __typename?: 'AwayBroker'; id: number; displayName: string } | null;
			initiatorBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			initiatorClient?: {
				__typename?: 'Client';
				id: number;
				firstName?: string | null;
				lastName?: string | null;
			} | null;
		} | null;
		lastDayBestBidInterest?: {
			__typename?: 'Interest';
			id: number;
			interestDate: string;
			isAway: boolean;
			bid?: { __typename?: 'Price'; price?: number | null } | null;
			initiatorBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			initiatorClient?: {
				__typename?: 'Client';
				id: number;
				firstName?: string | null;
				lastName?: string | null;
			} | null;
		} | null;
		lastDayBestAwayBid?: {
			__typename?: 'Interest';
			id: number;
			interestDate: string;
			isAway: boolean;
			bid?: { __typename?: 'Price'; price?: number | null } | null;
			awayBroker?: { __typename?: 'AwayBroker'; id: number; displayName: string } | null;
			initiatorBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			initiatorClient?: {
				__typename?: 'Client';
				id: number;
				firstName?: string | null;
				lastName?: string | null;
			} | null;
		} | null;
		lastPrints?: Array<
			| {
					__typename: 'AwayTrade';
					id: number;
					tradedAt: string;
					comments?: string | null;
					awayPrice?: number | null;
					awaySize?: number | null;
					buyerBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
					sellerBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			  }
			| {
					__typename: 'Trade';
					id: number;
					tradedAt: string;
					comments?: string | null;
					price: number;
					size: number;
					buyerBankEntity?: {
						__typename?: 'BankLegalEntity';
						id: number;
						bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
					} | null;
					sellerBankEntity?: {
						__typename?: 'BankLegalEntity';
						id: number;
						bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
					} | null;
			  }
		> | null;
	}> | null;
};

export type LiveViewInterestFragmentFragment = {
	__typename?: 'Interest';
	id: number;
	interestDate: string;
	isAway: boolean;
	initiatorBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
	initiatorClient?: {
		__typename?: 'Client';
		id: number;
		firstName?: string | null;
		lastName?: string | null;
	} | null;
};

export type GetServerInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetServerInfoQuery = {
	__typename?: 'Query';
	serverInfo: {
		__typename?: 'ServerInfo';
		version?: string | null;
		isProductionEnvironment: boolean;
	};
};

export type StructureIdFragment = {
	__typename: 'InternalStructure';
	id: number;
	cap?: number | null;
	maturity?:
		| { __typename: 'MaturityDate'; expirationDate: string }
		| { __typename: 'MaturityRelative'; expirationMonths: number }
		| null;
};

export type StructureMainPartsFragment = {
	__typename: 'InternalStructure';
	createdAt: string;
	updatedAt: string;
	notionalCurrency: Currency;
	strikes?: Array<number> | null;
	id: number;
	cap?: number | null;
	category?: {
		__typename?: 'Category';
		categoryGroupId: number;
		payoffId: number;
		formatHint: string;
		categoryGroup?: { __typename?: 'CategoryGroup'; id: number; displayName: string } | null;
		payoff?: { __typename?: 'Payoff'; id: number; displayName: string } | null;
	} | null;
	underlyingBasket?: {
		__typename?: 'UnderlyingBasket';
		id: number;
		underlyings?: Array<{
			__typename?: 'Underlying';
			id: number;
			squareBookingTicker: string;
			stockName?: string | null;
		}> | null;
	} | null;
	maturity?:
		| { __typename: 'MaturityDate'; expirationDate: string }
		| { __typename: 'MaturityRelative'; expirationMonths: number }
		| null;
};

export type StructureFromIndexQueryVariables = Exact<{
	compositeIndex: StructureIndexInput;
}>;

export type StructureFromIndexQuery = {
	__typename?: 'Query';
	structure?: {
		__typename: 'InternalStructure';
		createdAt: string;
		updatedAt: string;
		notionalCurrency: Currency;
		strikes?: Array<number> | null;
		id: number;
		cap?: number | null;
		category?: {
			__typename?: 'Category';
			categoryGroupId: number;
			payoffId: number;
			formatHint: string;
			categoryGroup?: { __typename?: 'CategoryGroup'; id: number; displayName: string } | null;
			payoff?: { __typename?: 'Payoff'; id: number; displayName: string } | null;
		} | null;
		underlyingBasket?: {
			__typename?: 'UnderlyingBasket';
			id: number;
			underlyings?: Array<{
				__typename?: 'Underlying';
				id: number;
				squareBookingTicker: string;
				stockName?: string | null;
			}> | null;
		} | null;
		maturity?:
			| { __typename: 'MaturityDate'; expirationDate: string }
			| { __typename: 'MaturityRelative'; expirationMonths: number }
			| null;
	} | null;
};

export type StructureFromIdentifierQueryVariables = Exact<{
	identifier: StructureIdentifierInput;
}>;

export type StructureFromIdentifierQuery = {
	__typename?: 'Query';
	internalStructure?: {
		__typename: 'InternalStructure';
		createdAt: string;
		updatedAt: string;
		notionalCurrency: Currency;
		strikes?: Array<number> | null;
		id: number;
		cap?: number | null;
		category?: {
			__typename?: 'Category';
			categoryGroupId: number;
			payoffId: number;
			formatHint: string;
			categoryGroup?: { __typename?: 'CategoryGroup'; id: number; displayName: string } | null;
			payoff?: { __typename?: 'Payoff'; id: number; displayName: string } | null;
		} | null;
		underlyingBasket?: {
			__typename?: 'UnderlyingBasket';
			id: number;
			underlyings?: Array<{
				__typename?: 'Underlying';
				id: number;
				squareBookingTicker: string;
				stockName?: string | null;
			}> | null;
		} | null;
		maturity?:
			| { __typename: 'MaturityDate'; expirationDate: string }
			| { __typename: 'MaturityRelative'; expirationMonths: number }
			| null;
	} | null;
};

export type StructureTradesFromIndexQueryVariables = Exact<{
	compositeIndex: StructureIndexInput;
	limitLatest: Scalars['Int'];
	beforeDate?: InputMaybe<Scalars['Date']>;
}>;

export type StructureTradesFromIndexQuery = {
	__typename?: 'Query';
	structure?: {
		__typename: 'InternalStructure';
		id: number;
		cap?: number | null;
		awayTradeGroups?: Array<{
			__typename: 'AwayTradeGroup';
			id: number;
			createdAt: string;
			price?: number | null;
			size?: number | null;
			comments?: string | null;
			creator?: { __typename?: 'User'; id: number; disabled: boolean; displayName: string } | null;
			awayBroker?: { __typename?: 'AwayBroker'; id: number; displayName: string } | null;
			awayTrades?: Array<{
				__typename?: 'AwayTrade';
				id: number;
				tradedAt: string;
				comments?: string | null;
				price?: number | null;
				size?: number | null;
				buyer?: {
					__typename?: 'Client';
					id: number;
					firstName?: string | null;
					lastName?: string | null;
				} | null;
				buyerBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
				seller?: {
					__typename?: 'Client';
					id: number;
					firstName?: string | null;
					lastName?: string | null;
				} | null;
				sellerBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			}> | null;
		}> | null;
		squareBookingStructures?: Array<{
			__typename?: 'SquareBookingStructure';
			squareBookingId: number;
			tradeGroups?: Array<{
				__typename: 'TradeGroup';
				id: number;
				createdAt: string;
				price: number;
				size: number;
				comments?: string | null;
				creator?: {
					__typename?: 'User';
					id: number;
					disabled: boolean;
					displayName: string;
				} | null;
				trades?: Array<{
					__typename?: 'Trade';
					tradedAt: string;
					comments?: string | null;
					price: number;
					size: number;
					buyer?: {
						__typename?: 'Client';
						id: number;
						firstName?: string | null;
						lastName?: string | null;
					} | null;
					buyerBankEntity?: {
						__typename?: 'BankLegalEntity';
						id: number;
						legalName?: string | null;
						country?: { __typename?: 'Country'; displayName: string; shortName: string } | null;
						bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
					} | null;
					seller?: {
						__typename?: 'Client';
						id: number;
						firstName?: string | null;
						lastName?: string | null;
					} | null;
					sellerBankEntity?: {
						__typename?: 'BankLegalEntity';
						id: number;
						legalName?: string | null;
						country?: { __typename?: 'Country'; displayName: string; shortName: string } | null;
						bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
					} | null;
				}> | null;
			}> | null;
		}> | null;
		maturity?:
			| { __typename: 'MaturityDate'; expirationDate: string }
			| { __typename: 'MaturityRelative'; expirationMonths: number }
			| null;
	} | null;
};

export type StructureInterestsFromIndexQueryVariables = Exact<{
	compositeIndex: StructureIndexInput;
	limitLatest: Scalars['Int'];
	beforeDate?: InputMaybe<Scalars['Date']>;
	includeDisabled?: Scalars['Boolean'];
}>;

export type StructureInterestsFromIndexQuery = {
	__typename?: 'Query';
	structure?: {
		__typename: 'InternalStructure';
		id: number;
		cap?: number | null;
		interests?: Array<{
			__typename?: 'Interest';
			id: number;
			createdAt: string;
			updatedAt: string;
			interestDate: string;
			comments?: string | null;
			isAway: boolean;
			creator?: { __typename?: 'User'; id: number; disabled: boolean; displayName: string } | null;
			initiatorBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			initiatorClient?: {
				__typename?: 'Client';
				id: number;
				firstName?: string | null;
				lastName?: string | null;
			} | null;
			bid?: { __typename?: 'Price'; price?: number | null; size?: number | null } | null;
			ask?: { __typename?: 'Price'; price?: number | null; size?: number | null } | null;
			awayBroker?: { __typename?: 'AwayBroker'; id: number; displayName: string } | null;
		}> | null;
		maturity?:
			| { __typename: 'MaturityDate'; expirationDate: string }
			| { __typename: 'MaturityRelative'; expirationMonths: number }
			| null;
	} | null;
};

export type InternalStructureInterestsQueryVariables = Exact<{
	identifier: StructureIdentifierInput;
	limitLatest: Scalars['Int'];
	date: Scalars['Date'];
	dateTimeMidnight: Scalars['DateTime'];
	includeUnsentPriceOuts?: Scalars['Boolean'];
}>;

export type InternalStructureInterestsQuery = {
	__typename?: 'Query';
	internalStructure?: {
		__typename: 'InternalStructure';
		id: number;
		cap?: number | null;
		pricedInterests?: Array<{
			__typename?: 'Interest';
			id: number;
			interestDate: string;
			isAway: boolean;
			initiatorBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			bid?: { __typename?: 'Price'; price?: number | null; size?: number | null } | null;
			ask?: { __typename?: 'Price'; price?: number | null; size?: number | null } | null;
			awayBroker?: { __typename?: 'AwayBroker'; id: number; displayName: string } | null;
		}> | null;
		nonPricedInterests?: Array<{
			__typename?: 'Interest';
			id: number;
			interestDate: string;
			isAway: boolean;
			initiatorBank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
			bid?: { __typename?: 'Price'; price?: number | null; size?: number | null } | null;
			ask?: { __typename?: 'Price'; price?: number | null; size?: number | null } | null;
			awayBroker?: { __typename?: 'AwayBroker'; id: number; displayName: string } | null;
		}> | null;
		priceOuts?: Array<{
			__typename?: 'PriceOut';
			id: number;
			createdAt: string;
			comments?: string | null;
			ask: { __typename?: 'PriceOutPrice'; price?: number | null; size?: number | null };
			bid: { __typename?: 'PriceOutPrice'; price?: number | null; size?: number | null };
			priceOuts?: Array<
				| {
						__typename: 'PriceOutSent';
						id: number;
						sentAt: string;
						emailGroup: {
							__typename?: 'EmailGroup';
							id: number;
							groupName: string;
							bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
						};
				  }
				| {
						__typename: 'PriceOutUnsent';
						emailGroup: {
							__typename?: 'EmailGroup';
							id: number;
							groupName: string;
							bank?: { __typename?: 'Bank'; id: number; displayName: string } | null;
						};
				  }
			> | null;
		}> | null;
		maturity?:
			| { __typename: 'MaturityDate'; expirationDate: string }
			| { __typename: 'MaturityRelative'; expirationMonths: number }
			| null;
	} | null;
};

export type InternalStructureInterestDatesQueryVariables = Exact<{
	identifier: StructureIdentifierInput;
	before?: InputMaybe<Scalars['Date']>;
}>;

export type InternalStructureInterestDatesQuery = {
	__typename?: 'Query';
	internalStructure?: {
		__typename: 'InternalStructure';
		datesOfInterest?: Array<string> | null;
		id: number;
		cap?: number | null;
		maturity?:
			| { __typename: 'MaturityDate'; expirationDate: string }
			| { __typename: 'MaturityRelative'; expirationMonths: number }
			| null;
	} | null;
};

export type SquareBookingStructuresFromIndexQueryVariables = Exact<{
	compositeIndex: StructureIndexInput;
}>;

export type SquareBookingStructuresFromIndexQuery = {
	__typename?: 'Query';
	structure?: {
		__typename: 'InternalStructure';
		id: number;
		cap?: number | null;
		squareBookingStructures?: Array<{
			__typename?: 'SquareBookingStructure';
			squareBookingId: number;
			createdAt: string;
			updatedAt: string;
			firstImportedAt: string;
			lastImportedAt: string;
			importedName: string;
			lastOrderBook?: { __typename?: 'SquareBookingOrderBook'; squareBookingId: number } | null;
		}> | null;
		maturity?:
			| { __typename: 'MaturityDate'; expirationDate: string }
			| { __typename: 'MaturityRelative'; expirationMonths: number }
			| null;
	} | null;
};

export type UnderlyingBasketQueryVariables = Exact<{
	underlyingBasketId: Scalars['Int'];
}>;

export type UnderlyingBasketQuery = {
	__typename?: 'Query';
	underlyingBasket?: {
		__typename?: 'UnderlyingBasket';
		id: number;
		displayName?: string | null;
		comments?: string | null;
		createdAt: string;
		updatedAt: string;
		lastImportedAt?: string | null;
		underlyings?: Array<{
			__typename?: 'Underlying';
			id: number;
			squareBookingTicker: string;
			stockName?: string | null;
			comments?: string | null;
			isin?: string | null;
		}> | null;
	} | null;
};

export type GetAllUnderlyingBasketsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUnderlyingBasketsQuery = {
	__typename?: 'Query';
	allUnderlyingBaskets?: Array<{
		__typename?: 'UnderlyingBasket';
		id: number;
		underlyings?: Array<{
			__typename?: 'Underlying';
			id: number;
			squareBookingTicker: string;
			stockName?: string | null;
		}> | null;
	}> | null;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
	__typename?: 'Query';
	me?: {
		__typename?: 'UserMe';
		userId: number;
		user: {
			__typename?: 'User';
			id: number;
			email: string;
			displayName: string;
			scopeTypes?: Array<ScopeType> | null;
		};
	} | null;
};

export type GetCurrentUserSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserSettingsQuery = {
	__typename?: 'Query';
	currentUser?: {
		__typename?: 'UserMe';
		userId: number;
		webFrontendSettings?: Record<string | number, unknown> | null;
	} | null;
};

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUsersQuery = {
	__typename?: 'Query';
	allUsers?: Array<{
		__typename?: 'User';
		id: number;
		active: boolean;
		email: string;
		emailIsVerified: boolean;
		displayName: string;
		scopeTypes?: Array<ScopeType> | null;
	}> | null;
};

export type GetUserQueryVariables = Exact<{
	userId: Scalars['Int'];
}>;

export type GetUserQuery = {
	__typename?: 'Query';
	user?: {
		__typename?: 'User';
		id: number;
		disabled: boolean;
		email: string;
		emailIsVerified: boolean;
		displayName: string;
		scopes?: Array<{
			__typename?: 'UserScope';
			scopeType: ScopeType;
			scopeInfo?: string | null;
			since: string;
		}> | null;
	} | null;
};

export type ValidateMaturityDateUsageQueryVariables = Exact<{
	maturityDate: Scalars['Date'];
}>;

export type ValidateMaturityDateUsageQuery = { __typename?: 'Query'; maturityDateUsage: number };

export const CommonEditableStructureAttributesFragmentDoc = gql`
	fragment CommonEditableStructureAttributes on InternalStructure {
		id
		category {
			categoryGroupId
			payoffId
		}
		underlyingBasket {
			id
		}
		notionalCurrency
		maturity {
			__typename
			... on MaturityDate {
				expirationDate
			}
			... on MaturityRelative {
				expirationMonths
			}
		}
		strikes
		cap
	}
`;
export const InterestBookStructureFragmentDoc = gql`
	fragment InterestBookStructure on InternalStructure {
		id
		maturity {
			__typename
			... on MaturityDate {
				expirationDate
			}
			... on MaturityRelative {
				expirationMonths
			}
		}
		cap
		strikes
		notionalCurrency
		underlyingBasket {
			id
			displayName
			underlyings {
				id
				squareBookingTicker
				stockName
			}
		}
		category {
			categoryGroupId
			payoffId
			formatHint
		}
	}
`;
export const LiveViewInterestFragmentFragmentDoc = gql`
	fragment LiveViewInterestFragment on Interest {
		id
		interestDate
		isAway
		initiatorBank {
			id
			displayName
		}
		initiatorClient {
			id
			firstName
			lastName
		}
	}
`;
export const StructureIdFragmentDoc = gql`
	fragment StructureId on InternalStructure {
		__typename
		id
		maturity {
			__typename
			... on MaturityDate {
				expirationDate
			}
			... on MaturityRelative {
				expirationMonths
			}
		}
		cap
	}
`;
export const StructureMainPartsFragmentDoc = gql`
	fragment StructureMainParts on InternalStructure {
		...StructureId
		createdAt
		updatedAt
		category {
			categoryGroupId
			payoffId
			categoryGroup {
				id
				displayName
			}
			payoff {
				id
				displayName
			}
			formatHint
		}
		underlyingBasket {
			id
			underlyings {
				id
				squareBookingTicker
				stockName
			}
		}
		notionalCurrency
		strikes
	}
	${StructureIdFragmentDoc}
`;
export const CreateBlastListDoc = gql`
	mutation CreateBlastList($name: String!) {
		createBlastList(name: $name) {
			id
			displayName
		}
	}
`;
export const CreateEmailGroupDoc = gql`
	mutation CreateEmailGroup(
		$groupName: String!
		$bankId: Int
		$appendToBlastListId: Int!
		$emails: [EmailAddress!]
	) {
		createEmailGroup(
			groupName: $groupName
			bankId: $bankId
			appendToBlastListId: $appendToBlastListId
			emails: $emails
		) {
			id
		}
	}
`;
export const UpdateBlastListNameDoc = gql`
	mutation UpdateBlastListName($updateBlastListId: Int!, $newName: String!) {
		updateBlastList(id: $updateBlastListId, newName: $newName) {
			id
			displayName
		}
	}
`;
export const UpdateEmailGroupDoc = gql`
	mutation UpdateEmailGroup(
		$emailGroupId: Int!
		$newGroupName: String
		$newEmails: [EmailAddress!]
		$newBankId: Int
	) {
		updateEmailGroup(
			id: $emailGroupId
			newGroupName: $newGroupName
			newEmails: $newEmails
			newBankId: $newBankId
		) {
			id
			groupName
			emails {
				id
				email
			}
			bank {
				id
				displayName
			}
		}
	}
`;
export const DeleteEmailGroupDoc = gql`
	mutation DeleteEmailGroup($emailGroupIdToDelete: Int!) {
		deleteEmailGroup(id: $emailGroupIdToDelete)
	}
`;
export const DeleteBlastListDoc = gql`
	mutation DeleteBlastList($blastListIdToDelete: Int!) {
		deleteBlastList(id: $blastListIdToDelete)
	}
`;
export const UpdateBankClientDoc = gql`
	mutation UpdateBankClient($clientId: Int!, $update: ClientUpdate!) {
		updateBankClient(clientId: $clientId, update: $update) {
			id
			squareBookingUpdatedAt
			firstName
			lastName
			email
			isDisabled
			bank {
				id
				displayName
			}
			comments
			birthDate
			backOfficeEmail
			middleOfficeEmail
		}
	}
`;
export const AuthorizeDashboardAccessDoc = gql`
	mutation AuthorizeDashboardAccess {
		signDashboardAuth
	}
`;
export const GenerateFirebaseCustomTokenDoc = gql`
	mutation GenerateFirebaseCustomToken {
		firebaseAuth: signFirebaseAuth {
			customToken
			categoryNotifications {
				categoryGroupId
				payoffId
				updatesCollectionPath
				recallsCollectionPath
			}
		}
	}
`;
export const CreateInterestDoc = gql`
	mutation CreateInterest($newInterest: NewInterest!) {
		createInterest(newInterest: $newInterest) {
			id
			createdAt
		}
	}
`;
export const ToggleInterestDisabledStatusDoc = gql`
	mutation ToggleInterestDisabledStatus($interestId: Int!, $disable: Boolean!) {
		toggleInterestDisabledStatus(interestId: $interestId, disable: $disable) {
			id
		}
	}
`;
export const DeleteInterestDoc = gql`
	mutation DeleteInterest($interestId: Int!) {
		deleteInterest(interestId: $interestId)
	}
`;
export const CreateTradeGroupDoc = gql`
	mutation CreateTradeGroup($newTradeGroup: NewTradeGroup!) {
		createTradeGroup(newTradeGroup: $newTradeGroup) {
			id
			createdAt
		}
	}
`;
export const DeleteTradeGroupDoc = gql`
	mutation DeleteTradeGroup($tradeGroupId: Int!) {
		deleteTradeGroup(tradeGroupId: $tradeGroupId)
	}
`;
export const CreateAwayTradeGroupDoc = gql`
	mutation CreateAwayTradeGroup($newAwayTradeGroup: NewAwayTradeGroup!) {
		createAwayTradeGroup(newAwayTradeGroup: $newAwayTradeGroup) {
			id
			createdAt
		}
	}
`;
export const DeleteAwayTradeGroupDoc = gql`
	mutation DeleteAwayTradeGroup($awayTradeGroupId: Int!) {
		deleteAwayTradeGroup(awayTradeGroupId: $awayTradeGroupId)
	}
`;
export const CreatePriceOutDoc = gql`
	mutation CreatePriceOut($newPriceOut: NewPriceOut!, $sending: SendPriceOut) {
		createPriceOut(newPriceOut: $newPriceOut, sending: $sending) {
			id
		}
	}
`;
export const DeletePriceOutDoc = gql`
	mutation DeletePriceOut($priceOutId: Int!) {
		deletePriceOut(priceOutId: $priceOutId)
	}
`;
export const UpdateInterestDoc = gql`
	mutation UpdateInterest($update: InterestUpdate!) {
		updateInterest(update: $update) {
			id
		}
	}
`;
export const UpdateAwayTradeGroupDoc = gql`
	mutation UpdateAwayTradeGroup($update: AwayTradeGroupUpdate!) {
		updateAwayTradeGroup(update: $update) {
			id
		}
	}
`;
export const UpdateTradeGroupDoc = gql`
	mutation UpdateTradeGroup($update: TradeGroupUpdate!) {
		updateTradeGroup(update: $update) {
			id
		}
	}
`;
export const UpdatePriceOutDoc = gql`
	mutation UpdatePriceOut($update: PriceOutUpdate!) {
		updatePriceOut(update: $update) {
			id
		}
	}
`;
export const LogInDoc = gql`
	mutation LogIn($email: EmailAddress!, $password: String!) {
		logIn(email: $email, password: $password) {
			id
			email
			displayName
			scopeTypes
		}
	}
`;
export const LogOutDoc = gql`
	mutation LogOut {
		logOut
	}
`;
export const RecallStructureDoc = gql`
	mutation RecallStructure($structureIdentifier: StructureIdentifierInput!) {
		recallStructure(structureIdentifier: $structureIdentifier)
	}
`;
export const SubmitNewPasswordDoc = gql`
	mutation SubmitNewPassword($email: String!, $newPassword: String!, $token: String!) {
		submitNewPassword(email: $email, newPassword: $newPassword, token: $token)
	}
`;
export const PostTradeGroupToSquareBookingDoc = gql`
	mutation PostTradeGroupToSquareBooking(
		$tradeGroupId: Int!
		$registerTradeFromSellSide: Boolean!
	) {
		postTradeGroupToSquareBooking(
			tradeGroupId: $tradeGroupId
			registerTradeFromSellSide: $registerTradeFromSellSide
		) {
			id
		}
	}
`;
export const PostInterestToSquareBookingDoc = gql`
	mutation PostInterestToSquareBooking(
		$interestId: Int!
		$legalEntityId: Int!
		$squareBookingStructureId: Int!
	) {
		postInterestToSquareBooking(
			interestId: $interestId
			legalEntityId: $legalEntityId
			squareBookingStructureId: $squareBookingStructureId
		) {
			id
		}
	}
`;
export const SyncBanksDoc = gql`
	mutation SyncBanks($forceAll: Boolean!) {
		syncBanks(forceAll: $forceAll)
	}
`;
export const SyncClientsDoc = gql`
	mutation SyncClients($forceAll: Boolean!) {
		syncClients(forceAll: $forceAll)
	}
`;
export const SyncUnderlyingsDoc = gql`
	mutation SyncUnderlyings($forceAll: Boolean!) {
		syncUnderlyings(forceAll: $forceAll)
	}
`;
export const SyncStructuresDoc = gql`
	mutation SyncStructures($forceAll: Boolean!) {
		syncStructures(forceAll: $forceAll)
	}
`;
export const CreateUserDoc = gql`
	mutation CreateUser($newUser: NewUser!) {
		createUser(newUser: $newUser) {
			id
		}
	}
`;
export const UpdateUserDoc = gql`
	mutation UpdateUser($userId: Int!, $newDisplayName: String, $newEmail: String) {
		updateUser(userId: $userId, newDisplayName: $newDisplayName, newEmail: $newEmail)
	}
`;
export const RequestPasswordResetDoc = gql`
	mutation RequestPasswordReset($userIdToReset: Int!) {
		requestPasswordReset(userIdToReset: $userIdToReset)
	}
`;
export const EnableUserDoc = gql`
	mutation EnableUser($userId: Int!) {
		enableUser(userId: $userId)
	}
`;
export const DisableUserDoc = gql`
	mutation DisableUser($userId: Int!) {
		disableUser(userId: $userId)
	}
`;
export const ReplaceUserScopesDoc = gql`
	mutation ReplaceUserScopes($newScopes: [NewScopeWithExtra!]!, $userId: Int!) {
		replaceUserScopes(newScopes: $newScopes, userId: $userId)
	}
`;
export const DeleteUserDoc = gql`
	mutation DeleteUser($userId: Int!) {
		deleteUser(userId: $userId)
	}
`;
export const UpdateSettingsDoc = gql`
	mutation UpdateSettings($newWebFrontendSettings: JSONObject) {
		storeNewSettings(newWebFrontendSettings: $newWebFrontendSettings)
	}
`;
export const GetActivityOnDateDoc = gql`
	query GetActivityOnDate($dateTimeMidnight: DateTime!) {
		activity: activityOnDate(dateTimeMidnight: $dateTimeMidnight) {
			dateTimeRange {
				start
				end
			}
			structureIdentifiers {
				internalId
				maturityDate
				maturityMonths
				cap
				internalStructure {
					id
					strikes
					notionalCurrency
					underlyingBasket {
						id
					}
					category {
						categoryGroupId
						payoffId
					}
				}
			}
		}
	}
`;
export const AllAwayBrokersDoc = gql`
	query AllAwayBrokers {
		awayBrokers {
			displayName
		}
	}
`;
export const AllBanksDoc = gql`
	query AllBanks($includeClients: Boolean! = false, $includeLegalEntities: Boolean! = false) {
		banks {
			id
			displayName
			clients @include(if: $includeClients) {
				id
				isDisabled
				firstName
				lastName
			}
			legalEntities @include(if: $includeLegalEntities) {
				id
				legalName
				country {
					shortName
				}
			}
		}
	}
`;
export const GetAllCategoriesGroupedDoc = gql`
	query GetAllCategoriesGrouped(
		$withKnownUnderlyingDates: Boolean!
		$withPayoffExtraData: Boolean!
		$withFormatHints: Boolean!
	) {
		categoryGroups {
			id
			displayName
			categories {
				categoryGroupId
				payoffId
				payoff {
					id
					displayName
					shortDisplayName
					underlyingGroupType @include(if: $withPayoffExtraData)
					maturityType @include(if: $withPayoffExtraData)
					numberOfStrikes @include(if: $withPayoffExtraData)
					capEnabled @include(if: $withPayoffExtraData)
					fixedStrikeGroup @include(if: $withPayoffExtraData) {
						strikes
					}
				}
				disabled
				canBeSentAsEmail
				formatHint @include(if: $withFormatHints)
				knownUnderlyingDates @include(if: $withKnownUnderlyingDates) {
					underlyingBasketId
					dates
				}
			}
		}
	}
`;
export const GetCategoryFormatHintsAndKnownMaturitiesDoc = gql`
	query GetCategoryFormatHintsAndKnownMaturities($excludeDisabled: Boolean! = false) {
		categories(excludeDisabled: $excludeDisabled) {
			categoryGroupId
			payoffId
			payoff {
				id
				underlyingGroupType
				maturityType
				numberOfStrikes
				capEnabled
				fixedStrikeGroup {
					strikes
				}
			}
			disabled
			formatHint
			knownUnderlyingDates {
				underlyingBasketId
				dates
			}
		}
	}
`;
export const GetAllCategoriesDoc = gql`
	query GetAllCategories($excludeDisabled: Boolean! = false) {
		categories(excludeDisabled: $excludeDisabled) {
			categoryGroupId
			payoffId
			payoff {
				id
				underlyingGroupType
				maturityType
				numberOfStrikes
				capEnabled
				fixedStrikeGroup {
					strikes
				}
			}
			disabled
			formatHint
			knownUnderlyingDates {
				underlyingBasketId
				dates
			}
		}
	}
`;
export const GetAllCategorySettingsDoc = gql`
	query GetAllCategorySettings($excludeDisabled: Boolean! = false) {
		categories(excludeDisabled: $excludeDisabled) {
			categoryGroupId
			payoffId
			disabled
			categoryGroup {
				displayName
			}
			payoff {
				displayName
				shortDisplayName
				underlyingGroupType
				maturityType
				numberOfStrikes
				capEnabled
			}
		}
	}
`;
export const GetAllCurrenciesDoc = gql`
	query GetAllCurrencies {
		allCurrencies: currencies {
			alphabeticCode
			enabled
		}
	}
`;
export const GetAllBlastListsDoc = gql`
	query GetAllBlastLists(
		$includeEmailGroups: Boolean! = false
		$includeEmailsInGroups: Boolean! = false
	) {
		emailBlastLists {
			id
			displayName
			emailGroups @include(if: $includeEmailGroups) {
				id
				groupName
				bank {
					id
					displayName
				}
				emails @include(if: $includeEmailsInGroups) {
					id
					email
				}
			}
		}
	}
`;
export const GetBlastListDoc = gql`
	query GetBlastList($blastListId: Int!) {
		emailBlastList(id: $blastListId) {
			id
			displayName
			emailGroups {
				id
				groupName
				bank {
					id
					displayName
				}
			}
		}
	}
`;
export const GetEmailGroupDoc = gql`
	query GetEmailGroup($emailGroupId: Int!) {
		emailGroup(id: $emailGroupId) {
			id
			createdAt
			updatedAt
			groupName
			bank {
				id
				displayName
			}
			emails {
				id
				email
				countOfClientsUsingThisAddress
			}
		}
	}
`;
export const GetAllClientsDoc = gql`
	query GetAllClients {
		clients {
			id
			squareBookingUpdatedAt
			firstName
			lastName
			email
			isDisabled
			bank {
				id
				displayName
			}
			comments
			birthDate
			backOfficeEmail
			middleOfficeEmail
		}
	}
`;
export const GetClientDoc = gql`
	query GetClient($clientId: Int!) {
		client(clientId: $clientId) {
			id
			squareBookingUpdatedAt
			firstName
			lastName
			email
			isDisabled
			bank {
				id
				displayName
			}
			comments
			birthDate
			backOfficeEmail
			middleOfficeEmail
		}
	}
`;
export const ExportDataDoc = gql`
	query ExportData($start: DateTime, $end: DateTime) {
		export {
			interests(start: $start, end: $end) {
				id
				updatedAt
				interestDate
				createdAt
				creator {
					displayName
				}
				structure {
					category {
						categoryGroupId
						payoffId
					}
					underlyingBasket {
						underlyings {
							squareBookingTicker
						}
					}
					notionalCurrency
					maturity {
						... on MaturityDate {
							expirationDate
						}
						... on MaturityRelative {
							expirationMonths
						}
					}
					strikes
					cap
				}
				comments
				initiatorBank {
					displayName
				}
				initiatorClient {
					firstName
					lastName
				}
				bid {
					price
					size
				}
				ask {
					price
					size
				}
				isAway
				awayBroker {
					displayName
				}
			}
			tradeGroups(start: $start, end: $end) {
				id
				createdAt
				creator {
					displayName
				}
				structure {
					category {
						categoryGroupId
						payoffId
					}
					underlyingBasket {
						underlyings {
							squareBookingTicker
						}
					}
					notionalCurrency
					maturity {
						... on MaturityDate {
							expirationDate
						}
						... on MaturityRelative {
							expirationMonths
						}
					}
					strikes
					cap
				}
				comments
				trades {
					tradedAt
					comments
					price
					size
					buyerBankEntity {
						bank {
							displayName
						}
					}
					buyer {
						firstName
						lastName
					}
					sellerBankEntity {
						bank {
							displayName
						}
					}
					seller {
						firstName
						lastName
					}
				}
			}
			awayTradeGroups(start: $start, end: $end) {
				id
				createdAt
				creator {
					displayName
				}
				structure {
					category {
						categoryGroupId
						payoffId
					}
					underlyingBasket {
						underlyings {
							squareBookingTicker
						}
					}
					notionalCurrency
					maturity {
						... on MaturityDate {
							expirationDate
						}
						... on MaturityRelative {
							expirationMonths
						}
					}
					strikes
					cap
				}
				comments
				awayTrades {
					tradedAt
					comments
					price
					size
					buyerBank {
						displayName
					}
					buyer {
						firstName
						lastName
					}
					sellerBank {
						displayName
					}
					seller {
						firstName
						lastName
					}
				}
				awayBroker {
					displayName
				}
			}
		}
	}
`;
export const GetInterestEditableDataDoc = gql`
	query GetInterestEditableData($interestId: Int!) {
		editableObject: interest(id: $interestId) {
			__typename
			id
			structure {
				...CommonEditableStructureAttributes
			}
			interestDate
			comments
			providerSource
			providerMedia
			initiatorBank {
				id
				displayName
			}
			initiatorClient {
				id
			}
			bid {
				price
				size
			}
			ask {
				price
				size
			}
			isAway
			awayBroker {
				id
				displayName
			}
		}
	}
	${CommonEditableStructureAttributesFragmentDoc}
`;
export const GetAwayTradeGroupEditableDataDoc = gql`
	query GetAwayTradeGroupEditableData($awayTradeGroupId: Int!) {
		editableObject: awayTradeGroup(id: $awayTradeGroupId) {
			__typename
			id
			structure {
				...CommonEditableStructureAttributes
			}
			price
			size
			comments
			awayTrades {
				id
				tradedAt
				buyer {
					id
					firstName
					lastName
				}
				buyerBank {
					id
					displayName
				}
				seller {
					id
					firstName
					lastName
				}
				sellerBank {
					id
					displayName
				}
				price
				size
			}
			awayBroker {
				id
				displayName
			}
		}
	}
	${CommonEditableStructureAttributesFragmentDoc}
`;
export const GetTradeGroupEditableDataDoc = gql`
	query GetTradeGroupEditableData($tradeGroupId: Int!) {
		editableObject: tradeGroup(id: $tradeGroupId) {
			__typename
			id
			structure {
				...CommonEditableStructureAttributes
			}
			price
			size
			comments
			trades {
				id
				tradedAt
				buyer {
					id
					firstName
					lastName
				}
				buyerBankEntity {
					id
					bank {
						id
						displayName
					}
				}
				seller {
					id
					firstName
					lastName
				}
				sellerBankEntity {
					id
					bank {
						id
						displayName
					}
				}
				price
				size
			}
			squareBookingStructure {
				squareBookingId
			}
		}
	}
	${CommonEditableStructureAttributesFragmentDoc}
`;
export const GetPriceOutEditableDataDoc = gql`
	query GetPriceOutEditableData($priceOutId: Int!) {
		editableObject: priceOut(id: $priceOutId) {
			__typename
			id
			structure {
				...CommonEditableStructureAttributes
			}
			ask {
				price
				size
			}
			bid {
				price
				size
			}
			comments
		}
	}
	${CommonEditableStructureAttributesFragmentDoc}
`;
export const GetInterestDoc = gql`
	query GetInterest(
		$interestId: Int!
		$withStructure: Boolean! = false
		$withSquareBookingStructures: Boolean! = false
		$withBankLegalEntities: Boolean! = false
	) {
		interest(id: $interestId) {
			id
			createdAt
			updatedAt
			interestDate
			creator {
				id
				displayName
				email
			}
			disabled
			comments
			providerSource
			providerMedia
			initiatorBank {
				id
				displayName
				legalEntities @include(if: $withBankLegalEntities) {
					id
					legalName
					country {
						id
						displayName
						shortName
					}
				}
			}
			initiatorClient {
				id
				firstName
				lastName
				email
				bank {
					id
					displayName
				}
			}
			bid {
				price
				size
			}
			ask {
				price
				size
			}
			isAway
			awayBroker {
				id
				displayName
			}
			structure @include(if: $withStructure) {
				...StructureId
				category {
					categoryGroupId
					payoffId
					formatHint
				}
				underlyingBasket {
					id
					underlyings {
						id
						squareBookingTicker
					}
				}
				notionalCurrency
				strikes
				squareBookingStructures @include(if: $withSquareBookingStructures) {
					squareBookingId
					importedName
					lastOrderBook {
						squareBookingId
					}
				}
			}
		}
	}
	${StructureIdFragmentDoc}
`;
export const GetPriceOutDoc = gql`
	query GetPriceOut($priceOutId: Int!) {
		priceOut(id: $priceOutId) {
			id
			createdAt
			creator {
				id
				email
				displayName
			}
			ask {
				price
				size
			}
			bid {
				price
				size
			}
			comments
			priceOuts(includeUnsent: true) {
				__typename
				emailGroup {
					id
					groupName
					bank {
						id
						displayName
					}
				}
				... on PriceOutSent {
					id
					sentAt
				}
			}
			emailBlastList {
				id
				displayName
			}
		}
	}
`;
export const GetTradeGroupDoc = gql`
	query GetTradeGroup($tradeGroupId: Int!) {
		tradeGroup(id: $tradeGroupId) {
			id
			createdAt
			creator {
				email
				displayName
			}
			squareBookingStructure {
				squareBookingId
				importedName
				lastOrderBook {
					squareBookingId
				}
			}
			price
			size
			comments
			trades {
				tradedAt
				price
				size
				buyerBankEntity {
					legalName
				}
				sellerBankEntity {
					legalName
				}
				squareBookingBuyOrderId
				squareBookingSellOrderId
			}
		}
	}
`;
export const SearchInterestsDoc = gql`
	query SearchInterests(
		$searchParams: InterestBookSearchParams!
		$includeDisabled: Boolean! = false
		$offset: Int! = 0
		$limit: Int
	) {
		interests(
			searchParams: $searchParams
			includeDisabled: $includeDisabled
			offset: $offset
			limit: $limit
		) {
			__typename
			id
			createdAt
			updatedAt
			interestDate
			creator {
				id
				displayName
			}
			disabled
			structure {
				...InterestBookStructure
			}
			bid {
				price
				size
			}
			ask {
				price
				size
			}
			comments
			initiatorBank {
				id
				displayName
			}
			initiatorClient {
				id
				firstName
				lastName
			}
			isAway
			awayBroker {
				id
				displayName
			}
		}
	}
	${InterestBookStructureFragmentDoc}
`;
export const SearchTradeGroupsDoc = gql`
	query SearchTradeGroups(
		$searchParams: InterestBookSearchParams!
		$offset: Int! = 0
		$limit: Int
	) {
		tradeGroups(searchParams: $searchParams, offset: $offset, limit: $limit) {
			__typename
			id
			createdAt
			creator {
				id
				displayName
			}
			price
			size
			comments
			structure {
				...InterestBookStructure
			}
			trades {
				id
				tradedAt
				buyerBankEntity {
					id
					legalName
					bank {
						id
						displayName
					}
				}
				sellerBankEntity {
					id
					legalName
					bank {
						id
						displayName
					}
				}
			}
		}
	}
	${InterestBookStructureFragmentDoc}
`;
export const SearchAwayTradeGroupsDoc = gql`
	query SearchAwayTradeGroups(
		$searchParams: InterestBookSearchParams!
		$offset: Int! = 0
		$limit: Int
	) {
		awayTradeGroups(searchParams: $searchParams, offset: $offset, limit: $limit) {
			__typename
			id
			createdAt
			creator {
				id
				displayName
			}
			price
			size
			comments
			structure {
				...InterestBookStructure
			}
			awayTrades {
				id
				tradedAt
				price
				buyerBank {
					id
					displayName
				}
				sellerBank {
					id
					displayName
				}
			}
			awayBroker {
				id
				displayName
			}
		}
	}
	${InterestBookStructureFragmentDoc}
`;
export const SearchPriceOutsDoc = gql`
	query SearchPriceOuts(
		$searchParams: InterestBookSearchParams!
		$includeUnsent: Boolean! = false
		$offset: Int! = 0
		$limit: Int
	) {
		priceOuts(searchParams: $searchParams, offset: $offset, limit: $limit) {
			__typename
			id
			createdAt
			creator {
				id
				displayName
			}
			comments
			structure {
				...InterestBookStructure
			}
			ask {
				price
				size
			}
			bid {
				price
				size
			}
			emailBlastList {
				id
				displayName
			}
			priceOuts(includeUnsent: $includeUnsent) {
				__typename
				emailGroup {
					id
					createdAt
					updatedAt
					groupName
					bank {
						id
						displayName
					}
				}
			}
		}
	}
	${InterestBookStructureFragmentDoc}
`;
export const GetLiveViewDoc = gql`
	query GetLiveView($nbOfLastPrints: Int!, $since: DateTime = null) {
		structures: liveViewStructures(since: $since) {
			dateTimeRange {
				start
				end
			}
			structureIdentifier {
				internalId
				maturityDate
				maturityMonths
				cap
				internalStructure {
					id
					underlyingBasket {
						id
						underlyings {
							id
							squareBookingTicker
						}
					}
					strikes
					notionalCurrency
					category {
						categoryGroupId
						payoffId
						formatHint
						categoryGroup {
							id
							displayName
						}
						payoff {
							id
							shortDisplayName
							numberOfStrikes
							capEnabled
						}
					}
				}
			}
			interestedBuyers {
				id
				displayName
			}
			interestedSellers {
				id
				displayName
			}
			interestedWithoutWay {
				id
				displayName
			}
			lastDayBestAskInterest(away: false) {
				...LiveViewInterestFragment
				ask {
					price
				}
			}
			lastDayBestAwayAsk: lastDayBestAskInterest(away: true) {
				...LiveViewInterestFragment
				ask {
					price
				}
				awayBroker {
					id
					displayName
				}
			}
			lastDayBestBidInterest(away: false) {
				...LiveViewInterestFragment
				bid {
					price
				}
			}
			lastDayBestAwayBid: lastDayBestBidInterest(away: true) {
				...LiveViewInterestFragment
				bid {
					price
				}
				awayBroker {
					id
					displayName
				}
			}
			lastPrints(count: $nbOfLastPrints) {
				... on Trade {
					__typename
					id
					tradedAt
					comments
					price
					size
					buyerBankEntity {
						id
						bank {
							id
							displayName
						}
					}
					sellerBankEntity {
						id
						bank {
							id
							displayName
						}
					}
				}
				... on AwayTrade {
					__typename
					id
					tradedAt
					comments
					awayPrice: price
					awaySize: size
					buyerBank {
						id
						displayName
					}
					sellerBank {
						id
						displayName
					}
				}
			}
		}
	}
	${LiveViewInterestFragmentFragmentDoc}
`;
export const GetServerInfoDoc = gql`
	query GetServerInfo {
		serverInfo {
			version
			isProductionEnvironment
		}
	}
`;
export const StructureFromIndexDoc = gql`
	query StructureFromIndex($compositeIndex: StructureIndexInput!) {
		structure(compositeIndex: $compositeIndex) {
			...StructureMainParts
		}
	}
	${StructureMainPartsFragmentDoc}
`;
export const StructureFromIdentifierDoc = gql`
	query StructureFromIdentifier($identifier: StructureIdentifierInput!) {
		internalStructure(identifier: $identifier) {
			...StructureMainParts
		}
	}
	${StructureMainPartsFragmentDoc}
`;
export const StructureTradesFromIndexDoc = gql`
	query StructureTradesFromIndex(
		$compositeIndex: StructureIndexInput!
		$limitLatest: Int!
		$beforeDate: Date
	) {
		structure(compositeIndex: $compositeIndex) {
			...StructureId
			awayTradeGroups(limitLatest: $limitLatest, beforeDate: $beforeDate) {
				__typename
				id
				createdAt
				creator {
					id
					disabled
					displayName
				}
				price
				size
				comments
				awayBroker {
					id
					displayName
				}
				awayTrades {
					id
					tradedAt
					comments
					buyer {
						id
						firstName
						lastName
					}
					buyerBank {
						id
						displayName
					}
					seller {
						id
						firstName
						lastName
					}
					sellerBank {
						id
						displayName
					}
					price
					size
				}
			}
			squareBookingStructures {
				squareBookingId
				tradeGroups {
					__typename
					id
					createdAt
					creator {
						id
						disabled
						displayName
					}
					price
					size
					comments
					trades {
						tradedAt
						comments
						price
						size
						buyer {
							id
							firstName
							lastName
						}
						buyerBankEntity {
							id
							legalName
							country {
								displayName
								shortName
							}
							bank {
								id
								displayName
							}
						}
						seller {
							id
							firstName
							lastName
						}
						sellerBankEntity {
							id
							legalName
							country {
								displayName
								shortName
							}
							bank {
								id
								displayName
							}
						}
					}
				}
			}
		}
	}
	${StructureIdFragmentDoc}
`;
export const StructureInterestsFromIndexDoc = gql`
	query StructureInterestsFromIndex(
		$compositeIndex: StructureIndexInput!
		$limitLatest: Int!
		$beforeDate: Date
		$includeDisabled: Boolean! = false
	) {
		structure(compositeIndex: $compositeIndex) {
			...StructureId
			interests(
				limitLatest: $limitLatest
				beforeDate: $beforeDate
				includeDisabled: $includeDisabled
			) {
				id
				createdAt
				updatedAt
				interestDate
				creator {
					id
					disabled
					displayName
				}
				comments
				initiatorBank {
					id
					displayName
				}
				initiatorClient {
					id
					firstName
					lastName
				}
				bid {
					price
					size
				}
				ask {
					price
					size
				}
				isAway
				awayBroker {
					id
					displayName
				}
			}
		}
	}
	${StructureIdFragmentDoc}
`;
export const InternalStructureInterestsDoc = gql`
	query InternalStructureInterests(
		$identifier: StructureIdentifierInput!
		$limitLatest: Int!
		$date: Date!
		$dateTimeMidnight: DateTime!
		$includeUnsentPriceOuts: Boolean! = false
	) {
		internalStructure(identifier: $identifier) {
			...StructureId
			pricedInterests: interests(
				limitLatest: $limitLatest
				date: $date
				includeDisabled: false
				excludeNonPriced: true
			) {
				id
				interestDate
				initiatorBank {
					id
					displayName
				}
				bid {
					price
					size
				}
				ask {
					price
					size
				}
				isAway
				awayBroker {
					id
					displayName
				}
			}
			nonPricedInterests: interests(
				limitLatest: $limitLatest
				beforeDate: $date
				includeDisabled: false
				excludePriced: true
			) {
				id
				interestDate
				initiatorBank {
					id
					displayName
				}
				bid {
					price
					size
				}
				ask {
					price
					size
				}
				isAway
				awayBroker {
					id
					displayName
				}
			}
			priceOuts(limitLatest: $limitLatest, dateTimeMidnight: $dateTimeMidnight) {
				id
				createdAt
				ask {
					price
					size
				}
				bid {
					price
					size
				}
				comments
				priceOuts(includeUnsent: $includeUnsentPriceOuts) {
					__typename
					emailGroup {
						id
						groupName
						bank {
							id
							displayName
						}
					}
					... on PriceOutSent {
						id
						sentAt
					}
				}
			}
		}
	}
	${StructureIdFragmentDoc}
`;
export const InternalStructureInterestDatesDoc = gql`
	query InternalStructureInterestDates($identifier: StructureIdentifierInput!, $before: Date) {
		internalStructure(identifier: $identifier) {
			...StructureId
			datesOfInterest(before: $before, includeNonPricedInterests: true)
		}
	}
	${StructureIdFragmentDoc}
`;
export const SquareBookingStructuresFromIndexDoc = gql`
	query SquareBookingStructuresFromIndex($compositeIndex: StructureIndexInput!) {
		structure(compositeIndex: $compositeIndex) {
			...StructureId
			squareBookingStructures {
				squareBookingId
				createdAt
				updatedAt
				firstImportedAt
				lastImportedAt
				importedName
				lastOrderBook {
					squareBookingId
				}
			}
		}
	}
	${StructureIdFragmentDoc}
`;
export const UnderlyingBasketDoc = gql`
	query UnderlyingBasket($underlyingBasketId: Int!) {
		underlyingBasket(id: $underlyingBasketId) {
			id
			displayName
			comments
			createdAt
			updatedAt
			lastImportedAt
			underlyings {
				id
				squareBookingTicker
				stockName
				comments
				isin
			}
		}
	}
`;
export const GetAllUnderlyingBasketsDoc = gql`
	query GetAllUnderlyingBaskets {
		allUnderlyingBaskets: underlyingBaskets {
			id
			underlyings {
				id
				squareBookingTicker
				stockName
			}
		}
	}
`;
export const CurrentUserDoc = gql`
	query CurrentUser {
		me {
			userId
			user {
				id
				email
				displayName
				scopeTypes
			}
		}
	}
`;
export const GetCurrentUserSettingsDoc = gql`
	query GetCurrentUserSettings {
		currentUser: me {
			userId
			webFrontendSettings
		}
	}
`;
export const GetAllUsersDoc = gql`
	query GetAllUsers {
		allUsers: users {
			id
			active
			email
			emailIsVerified
			displayName
			scopeTypes
		}
	}
`;
export const GetUserDoc = gql`
	query GetUser($userId: Int!) {
		user(userId: $userId) {
			id
			disabled
			email
			emailIsVerified
			displayName
			scopes {
				scopeType
				scopeInfo
				since
			}
		}
	}
`;
export const ValidateMaturityDateUsageDoc = gql`
	query ValidateMaturityDateUsage($maturityDate: Date!) {
		maturityDateUsage(maturityDate: $maturityDate)
	}
`;
export const CreateBlastList = (
	options: Omit<MutationOptions<any, CreateBlastListMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<CreateBlastListMutation, CreateBlastListMutationVariables>({
		mutation: CreateBlastListDoc,
		...options,
	});
	return m;
};
export const CreateEmailGroup = (
	options: Omit<MutationOptions<any, CreateEmailGroupMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<CreateEmailGroupMutation, CreateEmailGroupMutationVariables>({
		mutation: CreateEmailGroupDoc,
		...options,
	});
	return m;
};
export const UpdateBlastListName = (
	options: Omit<MutationOptions<any, UpdateBlastListNameMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<UpdateBlastListNameMutation, UpdateBlastListNameMutationVariables>({
		mutation: UpdateBlastListNameDoc,
		...options,
	});
	return m;
};
export const UpdateEmailGroup = (
	options: Omit<MutationOptions<any, UpdateEmailGroupMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<UpdateEmailGroupMutation, UpdateEmailGroupMutationVariables>({
		mutation: UpdateEmailGroupDoc,
		...options,
	});
	return m;
};
export const DeleteEmailGroup = (
	options: Omit<MutationOptions<any, DeleteEmailGroupMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<DeleteEmailGroupMutation, DeleteEmailGroupMutationVariables>({
		mutation: DeleteEmailGroupDoc,
		...options,
	});
	return m;
};
export const DeleteBlastList = (
	options: Omit<MutationOptions<any, DeleteBlastListMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<DeleteBlastListMutation, DeleteBlastListMutationVariables>({
		mutation: DeleteBlastListDoc,
		...options,
	});
	return m;
};
export const UpdateBankClient = (
	options: Omit<MutationOptions<any, UpdateBankClientMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<UpdateBankClientMutation, UpdateBankClientMutationVariables>({
		mutation: UpdateBankClientDoc,
		...options,
	});
	return m;
};
export const AuthorizeDashboardAccess = (
	options: Omit<MutationOptions<any, AuthorizeDashboardAccessMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<
		AuthorizeDashboardAccessMutation,
		AuthorizeDashboardAccessMutationVariables
	>({
		mutation: AuthorizeDashboardAccessDoc,
		...options,
	});
	return m;
};
export const GenerateFirebaseCustomToken = (
	options: Omit<MutationOptions<any, GenerateFirebaseCustomTokenMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<
		GenerateFirebaseCustomTokenMutation,
		GenerateFirebaseCustomTokenMutationVariables
	>({
		mutation: GenerateFirebaseCustomTokenDoc,
		...options,
	});
	return m;
};
export const CreateInterest = (
	options: Omit<MutationOptions<any, CreateInterestMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<CreateInterestMutation, CreateInterestMutationVariables>({
		mutation: CreateInterestDoc,
		...options,
	});
	return m;
};
export const ToggleInterestDisabledStatus = (
	options: Omit<MutationOptions<any, ToggleInterestDisabledStatusMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<
		ToggleInterestDisabledStatusMutation,
		ToggleInterestDisabledStatusMutationVariables
	>({
		mutation: ToggleInterestDisabledStatusDoc,
		...options,
	});
	return m;
};
export const DeleteInterest = (
	options: Omit<MutationOptions<any, DeleteInterestMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<DeleteInterestMutation, DeleteInterestMutationVariables>({
		mutation: DeleteInterestDoc,
		...options,
	});
	return m;
};
export const CreateTradeGroup = (
	options: Omit<MutationOptions<any, CreateTradeGroupMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<CreateTradeGroupMutation, CreateTradeGroupMutationVariables>({
		mutation: CreateTradeGroupDoc,
		...options,
	});
	return m;
};
export const DeleteTradeGroup = (
	options: Omit<MutationOptions<any, DeleteTradeGroupMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<DeleteTradeGroupMutation, DeleteTradeGroupMutationVariables>({
		mutation: DeleteTradeGroupDoc,
		...options,
	});
	return m;
};
export const CreateAwayTradeGroup = (
	options: Omit<MutationOptions<any, CreateAwayTradeGroupMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<CreateAwayTradeGroupMutation, CreateAwayTradeGroupMutationVariables>({
		mutation: CreateAwayTradeGroupDoc,
		...options,
	});
	return m;
};
export const DeleteAwayTradeGroup = (
	options: Omit<MutationOptions<any, DeleteAwayTradeGroupMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<DeleteAwayTradeGroupMutation, DeleteAwayTradeGroupMutationVariables>({
		mutation: DeleteAwayTradeGroupDoc,
		...options,
	});
	return m;
};
export const CreatePriceOut = (
	options: Omit<MutationOptions<any, CreatePriceOutMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<CreatePriceOutMutation, CreatePriceOutMutationVariables>({
		mutation: CreatePriceOutDoc,
		...options,
	});
	return m;
};
export const DeletePriceOut = (
	options: Omit<MutationOptions<any, DeletePriceOutMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<DeletePriceOutMutation, DeletePriceOutMutationVariables>({
		mutation: DeletePriceOutDoc,
		...options,
	});
	return m;
};
export const UpdateInterest = (
	options: Omit<MutationOptions<any, UpdateInterestMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<UpdateInterestMutation, UpdateInterestMutationVariables>({
		mutation: UpdateInterestDoc,
		...options,
	});
	return m;
};
export const UpdateAwayTradeGroup = (
	options: Omit<MutationOptions<any, UpdateAwayTradeGroupMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<UpdateAwayTradeGroupMutation, UpdateAwayTradeGroupMutationVariables>({
		mutation: UpdateAwayTradeGroupDoc,
		...options,
	});
	return m;
};
export const UpdateTradeGroup = (
	options: Omit<MutationOptions<any, UpdateTradeGroupMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<UpdateTradeGroupMutation, UpdateTradeGroupMutationVariables>({
		mutation: UpdateTradeGroupDoc,
		...options,
	});
	return m;
};
export const UpdatePriceOut = (
	options: Omit<MutationOptions<any, UpdatePriceOutMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<UpdatePriceOutMutation, UpdatePriceOutMutationVariables>({
		mutation: UpdatePriceOutDoc,
		...options,
	});
	return m;
};
export const LogIn = (options: Omit<MutationOptions<any, LogInMutationVariables>, 'mutation'>) => {
	const m = client.mutate<LogInMutation, LogInMutationVariables>({
		mutation: LogInDoc,
		...options,
	});
	return m;
};
export const LogOut = (
	options: Omit<MutationOptions<any, LogOutMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<LogOutMutation, LogOutMutationVariables>({
		mutation: LogOutDoc,
		...options,
	});
	return m;
};
export const RecallStructure = (
	options: Omit<MutationOptions<any, RecallStructureMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<RecallStructureMutation, RecallStructureMutationVariables>({
		mutation: RecallStructureDoc,
		...options,
	});
	return m;
};
export const SubmitNewPassword = (
	options: Omit<MutationOptions<any, SubmitNewPasswordMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<SubmitNewPasswordMutation, SubmitNewPasswordMutationVariables>({
		mutation: SubmitNewPasswordDoc,
		...options,
	});
	return m;
};
export const PostTradeGroupToSquareBooking = (
	options: Omit<MutationOptions<any, PostTradeGroupToSquareBookingMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<
		PostTradeGroupToSquareBookingMutation,
		PostTradeGroupToSquareBookingMutationVariables
	>({
		mutation: PostTradeGroupToSquareBookingDoc,
		...options,
	});
	return m;
};
export const PostInterestToSquareBooking = (
	options: Omit<MutationOptions<any, PostInterestToSquareBookingMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<
		PostInterestToSquareBookingMutation,
		PostInterestToSquareBookingMutationVariables
	>({
		mutation: PostInterestToSquareBookingDoc,
		...options,
	});
	return m;
};
export const SyncBanks = (
	options: Omit<MutationOptions<any, SyncBanksMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<SyncBanksMutation, SyncBanksMutationVariables>({
		mutation: SyncBanksDoc,
		...options,
	});
	return m;
};
export const SyncClients = (
	options: Omit<MutationOptions<any, SyncClientsMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<SyncClientsMutation, SyncClientsMutationVariables>({
		mutation: SyncClientsDoc,
		...options,
	});
	return m;
};
export const SyncUnderlyings = (
	options: Omit<MutationOptions<any, SyncUnderlyingsMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<SyncUnderlyingsMutation, SyncUnderlyingsMutationVariables>({
		mutation: SyncUnderlyingsDoc,
		...options,
	});
	return m;
};
export const SyncStructures = (
	options: Omit<MutationOptions<any, SyncStructuresMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<SyncStructuresMutation, SyncStructuresMutationVariables>({
		mutation: SyncStructuresDoc,
		...options,
	});
	return m;
};
export const CreateUser = (
	options: Omit<MutationOptions<any, CreateUserMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<CreateUserMutation, CreateUserMutationVariables>({
		mutation: CreateUserDoc,
		...options,
	});
	return m;
};
export const UpdateUser = (
	options: Omit<MutationOptions<any, UpdateUserMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
		mutation: UpdateUserDoc,
		...options,
	});
	return m;
};
export const RequestPasswordReset = (
	options: Omit<MutationOptions<any, RequestPasswordResetMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>({
		mutation: RequestPasswordResetDoc,
		...options,
	});
	return m;
};
export const EnableUser = (
	options: Omit<MutationOptions<any, EnableUserMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<EnableUserMutation, EnableUserMutationVariables>({
		mutation: EnableUserDoc,
		...options,
	});
	return m;
};
export const DisableUser = (
	options: Omit<MutationOptions<any, DisableUserMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<DisableUserMutation, DisableUserMutationVariables>({
		mutation: DisableUserDoc,
		...options,
	});
	return m;
};
export const ReplaceUserScopes = (
	options: Omit<MutationOptions<any, ReplaceUserScopesMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<ReplaceUserScopesMutation, ReplaceUserScopesMutationVariables>({
		mutation: ReplaceUserScopesDoc,
		...options,
	});
	return m;
};
export const DeleteUser = (
	options: Omit<MutationOptions<any, DeleteUserMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<DeleteUserMutation, DeleteUserMutationVariables>({
		mutation: DeleteUserDoc,
		...options,
	});
	return m;
};
export const UpdateSettings = (
	options: Omit<MutationOptions<any, UpdateSettingsMutationVariables>, 'mutation'>
) => {
	const m = client.mutate<UpdateSettingsMutation, UpdateSettingsMutationVariables>({
		mutation: UpdateSettingsDoc,
		...options,
	});
	return m;
};
export const GetActivityOnDate = (
	options: Omit<WatchQueryOptions<GetActivityOnDateQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetActivityOnDateQuery> & {
		query: ObservableQuery<GetActivityOnDateQuery, GetActivityOnDateQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetActivityOnDateDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetActivityOnDateQuery> & {
			query: ObservableQuery<GetActivityOnDateQuery, GetActivityOnDateQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetActivityOnDate = (
	options: Omit<QueryOptions<GetActivityOnDateQueryVariables>, 'query'>
) => {
	return client.query<GetActivityOnDateQuery>({ query: GetActivityOnDateDoc, ...options });
};

export const AllAwayBrokers = (
	options: Omit<WatchQueryOptions<AllAwayBrokersQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<AllAwayBrokersQuery> & {
		query: ObservableQuery<AllAwayBrokersQuery, AllAwayBrokersQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: AllAwayBrokersDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<AllAwayBrokersQuery> & {
			query: ObservableQuery<AllAwayBrokersQuery, AllAwayBrokersQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncAllAwayBrokers = (
	options: Omit<QueryOptions<AllAwayBrokersQueryVariables>, 'query'>
) => {
	return client.query<AllAwayBrokersQuery>({ query: AllAwayBrokersDoc, ...options });
};

export const AllBanks = (
	options: Omit<WatchQueryOptions<AllBanksQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<AllBanksQuery> & {
		query: ObservableQuery<AllBanksQuery, AllBanksQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: AllBanksDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<AllBanksQuery> & {
			query: ObservableQuery<AllBanksQuery, AllBanksQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncAllBanks = (options: Omit<QueryOptions<AllBanksQueryVariables>, 'query'>) => {
	return client.query<AllBanksQuery>({ query: AllBanksDoc, ...options });
};

export const GetAllCategoriesGrouped = (
	options: Omit<WatchQueryOptions<GetAllCategoriesGroupedQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetAllCategoriesGroupedQuery> & {
		query: ObservableQuery<GetAllCategoriesGroupedQuery, GetAllCategoriesGroupedQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetAllCategoriesGroupedDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetAllCategoriesGroupedQuery> & {
			query: ObservableQuery<GetAllCategoriesGroupedQuery, GetAllCategoriesGroupedQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetAllCategoriesGrouped = (
	options: Omit<QueryOptions<GetAllCategoriesGroupedQueryVariables>, 'query'>
) => {
	return client.query<GetAllCategoriesGroupedQuery>({
		query: GetAllCategoriesGroupedDoc,
		...options,
	});
};

export const GetCategoryFormatHintsAndKnownMaturities = (
	options: Omit<WatchQueryOptions<GetCategoryFormatHintsAndKnownMaturitiesQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetCategoryFormatHintsAndKnownMaturitiesQuery> & {
		query: ObservableQuery<
			GetCategoryFormatHintsAndKnownMaturitiesQuery,
			GetCategoryFormatHintsAndKnownMaturitiesQueryVariables
		>;
	}
> => {
	const q = client.watchQuery({
		query: GetCategoryFormatHintsAndKnownMaturitiesDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetCategoryFormatHintsAndKnownMaturitiesQuery> & {
			query: ObservableQuery<
				GetCategoryFormatHintsAndKnownMaturitiesQuery,
				GetCategoryFormatHintsAndKnownMaturitiesQueryVariables
			>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetCategoryFormatHintsAndKnownMaturities = (
	options: Omit<QueryOptions<GetCategoryFormatHintsAndKnownMaturitiesQueryVariables>, 'query'>
) => {
	return client.query<GetCategoryFormatHintsAndKnownMaturitiesQuery>({
		query: GetCategoryFormatHintsAndKnownMaturitiesDoc,
		...options,
	});
};

export const GetAllCategories = (
	options: Omit<WatchQueryOptions<GetAllCategoriesQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetAllCategoriesQuery> & {
		query: ObservableQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetAllCategoriesDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetAllCategoriesQuery> & {
			query: ObservableQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetAllCategories = (
	options: Omit<QueryOptions<GetAllCategoriesQueryVariables>, 'query'>
) => {
	return client.query<GetAllCategoriesQuery>({ query: GetAllCategoriesDoc, ...options });
};

export const GetAllCategorySettings = (
	options: Omit<WatchQueryOptions<GetAllCategorySettingsQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetAllCategorySettingsQuery> & {
		query: ObservableQuery<GetAllCategorySettingsQuery, GetAllCategorySettingsQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetAllCategorySettingsDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetAllCategorySettingsQuery> & {
			query: ObservableQuery<GetAllCategorySettingsQuery, GetAllCategorySettingsQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetAllCategorySettings = (
	options: Omit<QueryOptions<GetAllCategorySettingsQueryVariables>, 'query'>
) => {
	return client.query<GetAllCategorySettingsQuery>({
		query: GetAllCategorySettingsDoc,
		...options,
	});
};

export const GetAllCurrencies = (
	options: Omit<WatchQueryOptions<GetAllCurrenciesQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetAllCurrenciesQuery> & {
		query: ObservableQuery<GetAllCurrenciesQuery, GetAllCurrenciesQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetAllCurrenciesDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetAllCurrenciesQuery> & {
			query: ObservableQuery<GetAllCurrenciesQuery, GetAllCurrenciesQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetAllCurrencies = (
	options: Omit<QueryOptions<GetAllCurrenciesQueryVariables>, 'query'>
) => {
	return client.query<GetAllCurrenciesQuery>({ query: GetAllCurrenciesDoc, ...options });
};

export const GetAllBlastLists = (
	options: Omit<WatchQueryOptions<GetAllBlastListsQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetAllBlastListsQuery> & {
		query: ObservableQuery<GetAllBlastListsQuery, GetAllBlastListsQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetAllBlastListsDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetAllBlastListsQuery> & {
			query: ObservableQuery<GetAllBlastListsQuery, GetAllBlastListsQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetAllBlastLists = (
	options: Omit<QueryOptions<GetAllBlastListsQueryVariables>, 'query'>
) => {
	return client.query<GetAllBlastListsQuery>({ query: GetAllBlastListsDoc, ...options });
};

export const GetBlastList = (
	options: Omit<WatchQueryOptions<GetBlastListQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetBlastListQuery> & {
		query: ObservableQuery<GetBlastListQuery, GetBlastListQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetBlastListDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetBlastListQuery> & {
			query: ObservableQuery<GetBlastListQuery, GetBlastListQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetBlastList = (
	options: Omit<QueryOptions<GetBlastListQueryVariables>, 'query'>
) => {
	return client.query<GetBlastListQuery>({ query: GetBlastListDoc, ...options });
};

export const GetEmailGroup = (
	options: Omit<WatchQueryOptions<GetEmailGroupQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetEmailGroupQuery> & {
		query: ObservableQuery<GetEmailGroupQuery, GetEmailGroupQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetEmailGroupDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetEmailGroupQuery> & {
			query: ObservableQuery<GetEmailGroupQuery, GetEmailGroupQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetEmailGroup = (
	options: Omit<QueryOptions<GetEmailGroupQueryVariables>, 'query'>
) => {
	return client.query<GetEmailGroupQuery>({ query: GetEmailGroupDoc, ...options });
};

export const GetAllClients = (
	options: Omit<WatchQueryOptions<GetAllClientsQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetAllClientsQuery> & {
		query: ObservableQuery<GetAllClientsQuery, GetAllClientsQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetAllClientsDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetAllClientsQuery> & {
			query: ObservableQuery<GetAllClientsQuery, GetAllClientsQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetAllClients = (
	options: Omit<QueryOptions<GetAllClientsQueryVariables>, 'query'>
) => {
	return client.query<GetAllClientsQuery>({ query: GetAllClientsDoc, ...options });
};

export const GetClient = (
	options: Omit<WatchQueryOptions<GetClientQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetClientQuery> & {
		query: ObservableQuery<GetClientQuery, GetClientQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetClientDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetClientQuery> & {
			query: ObservableQuery<GetClientQuery, GetClientQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetClient = (options: Omit<QueryOptions<GetClientQueryVariables>, 'query'>) => {
	return client.query<GetClientQuery>({ query: GetClientDoc, ...options });
};

export const ExportData = (
	options: Omit<WatchQueryOptions<ExportDataQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<ExportDataQuery> & {
		query: ObservableQuery<ExportDataQuery, ExportDataQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: ExportDataDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<ExportDataQuery> & {
			query: ObservableQuery<ExportDataQuery, ExportDataQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncExportData = (options: Omit<QueryOptions<ExportDataQueryVariables>, 'query'>) => {
	return client.query<ExportDataQuery>({ query: ExportDataDoc, ...options });
};

export const GetInterestEditableData = (
	options: Omit<WatchQueryOptions<GetInterestEditableDataQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetInterestEditableDataQuery> & {
		query: ObservableQuery<GetInterestEditableDataQuery, GetInterestEditableDataQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetInterestEditableDataDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetInterestEditableDataQuery> & {
			query: ObservableQuery<GetInterestEditableDataQuery, GetInterestEditableDataQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetInterestEditableData = (
	options: Omit<QueryOptions<GetInterestEditableDataQueryVariables>, 'query'>
) => {
	return client.query<GetInterestEditableDataQuery>({
		query: GetInterestEditableDataDoc,
		...options,
	});
};

export const GetAwayTradeGroupEditableData = (
	options: Omit<WatchQueryOptions<GetAwayTradeGroupEditableDataQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetAwayTradeGroupEditableDataQuery> & {
		query: ObservableQuery<
			GetAwayTradeGroupEditableDataQuery,
			GetAwayTradeGroupEditableDataQueryVariables
		>;
	}
> => {
	const q = client.watchQuery({
		query: GetAwayTradeGroupEditableDataDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetAwayTradeGroupEditableDataQuery> & {
			query: ObservableQuery<
				GetAwayTradeGroupEditableDataQuery,
				GetAwayTradeGroupEditableDataQueryVariables
			>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetAwayTradeGroupEditableData = (
	options: Omit<QueryOptions<GetAwayTradeGroupEditableDataQueryVariables>, 'query'>
) => {
	return client.query<GetAwayTradeGroupEditableDataQuery>({
		query: GetAwayTradeGroupEditableDataDoc,
		...options,
	});
};

export const GetTradeGroupEditableData = (
	options: Omit<WatchQueryOptions<GetTradeGroupEditableDataQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetTradeGroupEditableDataQuery> & {
		query: ObservableQuery<GetTradeGroupEditableDataQuery, GetTradeGroupEditableDataQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetTradeGroupEditableDataDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetTradeGroupEditableDataQuery> & {
			query: ObservableQuery<
				GetTradeGroupEditableDataQuery,
				GetTradeGroupEditableDataQueryVariables
			>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetTradeGroupEditableData = (
	options: Omit<QueryOptions<GetTradeGroupEditableDataQueryVariables>, 'query'>
) => {
	return client.query<GetTradeGroupEditableDataQuery>({
		query: GetTradeGroupEditableDataDoc,
		...options,
	});
};

export const GetPriceOutEditableData = (
	options: Omit<WatchQueryOptions<GetPriceOutEditableDataQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetPriceOutEditableDataQuery> & {
		query: ObservableQuery<GetPriceOutEditableDataQuery, GetPriceOutEditableDataQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetPriceOutEditableDataDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetPriceOutEditableDataQuery> & {
			query: ObservableQuery<GetPriceOutEditableDataQuery, GetPriceOutEditableDataQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetPriceOutEditableData = (
	options: Omit<QueryOptions<GetPriceOutEditableDataQueryVariables>, 'query'>
) => {
	return client.query<GetPriceOutEditableDataQuery>({
		query: GetPriceOutEditableDataDoc,
		...options,
	});
};

export const GetInterest = (
	options: Omit<WatchQueryOptions<GetInterestQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetInterestQuery> & {
		query: ObservableQuery<GetInterestQuery, GetInterestQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetInterestDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetInterestQuery> & {
			query: ObservableQuery<GetInterestQuery, GetInterestQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetInterest = (
	options: Omit<QueryOptions<GetInterestQueryVariables>, 'query'>
) => {
	return client.query<GetInterestQuery>({ query: GetInterestDoc, ...options });
};

export const GetPriceOut = (
	options: Omit<WatchQueryOptions<GetPriceOutQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetPriceOutQuery> & {
		query: ObservableQuery<GetPriceOutQuery, GetPriceOutQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetPriceOutDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetPriceOutQuery> & {
			query: ObservableQuery<GetPriceOutQuery, GetPriceOutQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetPriceOut = (
	options: Omit<QueryOptions<GetPriceOutQueryVariables>, 'query'>
) => {
	return client.query<GetPriceOutQuery>({ query: GetPriceOutDoc, ...options });
};

export const GetTradeGroup = (
	options: Omit<WatchQueryOptions<GetTradeGroupQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetTradeGroupQuery> & {
		query: ObservableQuery<GetTradeGroupQuery, GetTradeGroupQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetTradeGroupDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetTradeGroupQuery> & {
			query: ObservableQuery<GetTradeGroupQuery, GetTradeGroupQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetTradeGroup = (
	options: Omit<QueryOptions<GetTradeGroupQueryVariables>, 'query'>
) => {
	return client.query<GetTradeGroupQuery>({ query: GetTradeGroupDoc, ...options });
};

export const SearchInterests = (
	options: Omit<WatchQueryOptions<SearchInterestsQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<SearchInterestsQuery> & {
		query: ObservableQuery<SearchInterestsQuery, SearchInterestsQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: SearchInterestsDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<SearchInterestsQuery> & {
			query: ObservableQuery<SearchInterestsQuery, SearchInterestsQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncSearchInterests = (
	options: Omit<QueryOptions<SearchInterestsQueryVariables>, 'query'>
) => {
	return client.query<SearchInterestsQuery>({ query: SearchInterestsDoc, ...options });
};

export const SearchTradeGroups = (
	options: Omit<WatchQueryOptions<SearchTradeGroupsQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<SearchTradeGroupsQuery> & {
		query: ObservableQuery<SearchTradeGroupsQuery, SearchTradeGroupsQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: SearchTradeGroupsDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<SearchTradeGroupsQuery> & {
			query: ObservableQuery<SearchTradeGroupsQuery, SearchTradeGroupsQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncSearchTradeGroups = (
	options: Omit<QueryOptions<SearchTradeGroupsQueryVariables>, 'query'>
) => {
	return client.query<SearchTradeGroupsQuery>({ query: SearchTradeGroupsDoc, ...options });
};

export const SearchAwayTradeGroups = (
	options: Omit<WatchQueryOptions<SearchAwayTradeGroupsQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<SearchAwayTradeGroupsQuery> & {
		query: ObservableQuery<SearchAwayTradeGroupsQuery, SearchAwayTradeGroupsQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: SearchAwayTradeGroupsDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<SearchAwayTradeGroupsQuery> & {
			query: ObservableQuery<SearchAwayTradeGroupsQuery, SearchAwayTradeGroupsQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncSearchAwayTradeGroups = (
	options: Omit<QueryOptions<SearchAwayTradeGroupsQueryVariables>, 'query'>
) => {
	return client.query<SearchAwayTradeGroupsQuery>({ query: SearchAwayTradeGroupsDoc, ...options });
};

export const SearchPriceOuts = (
	options: Omit<WatchQueryOptions<SearchPriceOutsQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<SearchPriceOutsQuery> & {
		query: ObservableQuery<SearchPriceOutsQuery, SearchPriceOutsQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: SearchPriceOutsDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<SearchPriceOutsQuery> & {
			query: ObservableQuery<SearchPriceOutsQuery, SearchPriceOutsQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncSearchPriceOuts = (
	options: Omit<QueryOptions<SearchPriceOutsQueryVariables>, 'query'>
) => {
	return client.query<SearchPriceOutsQuery>({ query: SearchPriceOutsDoc, ...options });
};

export const GetLiveView = (
	options: Omit<WatchQueryOptions<GetLiveViewQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetLiveViewQuery> & {
		query: ObservableQuery<GetLiveViewQuery, GetLiveViewQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetLiveViewDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetLiveViewQuery> & {
			query: ObservableQuery<GetLiveViewQuery, GetLiveViewQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetLiveView = (
	options: Omit<QueryOptions<GetLiveViewQueryVariables>, 'query'>
) => {
	return client.query<GetLiveViewQuery>({ query: GetLiveViewDoc, ...options });
};

export const GetServerInfo = (
	options: Omit<WatchQueryOptions<GetServerInfoQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetServerInfoQuery> & {
		query: ObservableQuery<GetServerInfoQuery, GetServerInfoQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetServerInfoDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetServerInfoQuery> & {
			query: ObservableQuery<GetServerInfoQuery, GetServerInfoQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetServerInfo = (
	options: Omit<QueryOptions<GetServerInfoQueryVariables>, 'query'>
) => {
	return client.query<GetServerInfoQuery>({ query: GetServerInfoDoc, ...options });
};

export const StructureFromIndex = (
	options: Omit<WatchQueryOptions<StructureFromIndexQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<StructureFromIndexQuery> & {
		query: ObservableQuery<StructureFromIndexQuery, StructureFromIndexQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: StructureFromIndexDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<StructureFromIndexQuery> & {
			query: ObservableQuery<StructureFromIndexQuery, StructureFromIndexQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncStructureFromIndex = (
	options: Omit<QueryOptions<StructureFromIndexQueryVariables>, 'query'>
) => {
	return client.query<StructureFromIndexQuery>({ query: StructureFromIndexDoc, ...options });
};

export const StructureFromIdentifier = (
	options: Omit<WatchQueryOptions<StructureFromIdentifierQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<StructureFromIdentifierQuery> & {
		query: ObservableQuery<StructureFromIdentifierQuery, StructureFromIdentifierQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: StructureFromIdentifierDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<StructureFromIdentifierQuery> & {
			query: ObservableQuery<StructureFromIdentifierQuery, StructureFromIdentifierQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncStructureFromIdentifier = (
	options: Omit<QueryOptions<StructureFromIdentifierQueryVariables>, 'query'>
) => {
	return client.query<StructureFromIdentifierQuery>({
		query: StructureFromIdentifierDoc,
		...options,
	});
};

export const StructureTradesFromIndex = (
	options: Omit<WatchQueryOptions<StructureTradesFromIndexQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<StructureTradesFromIndexQuery> & {
		query: ObservableQuery<StructureTradesFromIndexQuery, StructureTradesFromIndexQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: StructureTradesFromIndexDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<StructureTradesFromIndexQuery> & {
			query: ObservableQuery<StructureTradesFromIndexQuery, StructureTradesFromIndexQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncStructureTradesFromIndex = (
	options: Omit<QueryOptions<StructureTradesFromIndexQueryVariables>, 'query'>
) => {
	return client.query<StructureTradesFromIndexQuery>({
		query: StructureTradesFromIndexDoc,
		...options,
	});
};

export const StructureInterestsFromIndex = (
	options: Omit<WatchQueryOptions<StructureInterestsFromIndexQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<StructureInterestsFromIndexQuery> & {
		query: ObservableQuery<
			StructureInterestsFromIndexQuery,
			StructureInterestsFromIndexQueryVariables
		>;
	}
> => {
	const q = client.watchQuery({
		query: StructureInterestsFromIndexDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<StructureInterestsFromIndexQuery> & {
			query: ObservableQuery<
				StructureInterestsFromIndexQuery,
				StructureInterestsFromIndexQueryVariables
			>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncStructureInterestsFromIndex = (
	options: Omit<QueryOptions<StructureInterestsFromIndexQueryVariables>, 'query'>
) => {
	return client.query<StructureInterestsFromIndexQuery>({
		query: StructureInterestsFromIndexDoc,
		...options,
	});
};

export const InternalStructureInterests = (
	options: Omit<WatchQueryOptions<InternalStructureInterestsQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<InternalStructureInterestsQuery> & {
		query: ObservableQuery<
			InternalStructureInterestsQuery,
			InternalStructureInterestsQueryVariables
		>;
	}
> => {
	const q = client.watchQuery({
		query: InternalStructureInterestsDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<InternalStructureInterestsQuery> & {
			query: ObservableQuery<
				InternalStructureInterestsQuery,
				InternalStructureInterestsQueryVariables
			>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncInternalStructureInterests = (
	options: Omit<QueryOptions<InternalStructureInterestsQueryVariables>, 'query'>
) => {
	return client.query<InternalStructureInterestsQuery>({
		query: InternalStructureInterestsDoc,
		...options,
	});
};

export const InternalStructureInterestDates = (
	options: Omit<WatchQueryOptions<InternalStructureInterestDatesQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<InternalStructureInterestDatesQuery> & {
		query: ObservableQuery<
			InternalStructureInterestDatesQuery,
			InternalStructureInterestDatesQueryVariables
		>;
	}
> => {
	const q = client.watchQuery({
		query: InternalStructureInterestDatesDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<InternalStructureInterestDatesQuery> & {
			query: ObservableQuery<
				InternalStructureInterestDatesQuery,
				InternalStructureInterestDatesQueryVariables
			>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncInternalStructureInterestDates = (
	options: Omit<QueryOptions<InternalStructureInterestDatesQueryVariables>, 'query'>
) => {
	return client.query<InternalStructureInterestDatesQuery>({
		query: InternalStructureInterestDatesDoc,
		...options,
	});
};

export const SquareBookingStructuresFromIndex = (
	options: Omit<WatchQueryOptions<SquareBookingStructuresFromIndexQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<SquareBookingStructuresFromIndexQuery> & {
		query: ObservableQuery<
			SquareBookingStructuresFromIndexQuery,
			SquareBookingStructuresFromIndexQueryVariables
		>;
	}
> => {
	const q = client.watchQuery({
		query: SquareBookingStructuresFromIndexDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<SquareBookingStructuresFromIndexQuery> & {
			query: ObservableQuery<
				SquareBookingStructuresFromIndexQuery,
				SquareBookingStructuresFromIndexQueryVariables
			>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncSquareBookingStructuresFromIndex = (
	options: Omit<QueryOptions<SquareBookingStructuresFromIndexQueryVariables>, 'query'>
) => {
	return client.query<SquareBookingStructuresFromIndexQuery>({
		query: SquareBookingStructuresFromIndexDoc,
		...options,
	});
};

export const UnderlyingBasket = (
	options: Omit<WatchQueryOptions<UnderlyingBasketQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<UnderlyingBasketQuery> & {
		query: ObservableQuery<UnderlyingBasketQuery, UnderlyingBasketQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: UnderlyingBasketDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<UnderlyingBasketQuery> & {
			query: ObservableQuery<UnderlyingBasketQuery, UnderlyingBasketQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncUnderlyingBasket = (
	options: Omit<QueryOptions<UnderlyingBasketQueryVariables>, 'query'>
) => {
	return client.query<UnderlyingBasketQuery>({ query: UnderlyingBasketDoc, ...options });
};

export const GetAllUnderlyingBaskets = (
	options: Omit<WatchQueryOptions<GetAllUnderlyingBasketsQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetAllUnderlyingBasketsQuery> & {
		query: ObservableQuery<GetAllUnderlyingBasketsQuery, GetAllUnderlyingBasketsQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetAllUnderlyingBasketsDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetAllUnderlyingBasketsQuery> & {
			query: ObservableQuery<GetAllUnderlyingBasketsQuery, GetAllUnderlyingBasketsQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetAllUnderlyingBaskets = (
	options: Omit<QueryOptions<GetAllUnderlyingBasketsQueryVariables>, 'query'>
) => {
	return client.query<GetAllUnderlyingBasketsQuery>({
		query: GetAllUnderlyingBasketsDoc,
		...options,
	});
};

export const CurrentUser = (
	options: Omit<WatchQueryOptions<CurrentUserQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<CurrentUserQuery> & {
		query: ObservableQuery<CurrentUserQuery, CurrentUserQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: CurrentUserDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<CurrentUserQuery> & {
			query: ObservableQuery<CurrentUserQuery, CurrentUserQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncCurrentUser = (
	options: Omit<QueryOptions<CurrentUserQueryVariables>, 'query'>
) => {
	return client.query<CurrentUserQuery>({ query: CurrentUserDoc, ...options });
};

export const GetCurrentUserSettings = (
	options: Omit<WatchQueryOptions<GetCurrentUserSettingsQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetCurrentUserSettingsQuery> & {
		query: ObservableQuery<GetCurrentUserSettingsQuery, GetCurrentUserSettingsQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetCurrentUserSettingsDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetCurrentUserSettingsQuery> & {
			query: ObservableQuery<GetCurrentUserSettingsQuery, GetCurrentUserSettingsQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetCurrentUserSettings = (
	options: Omit<QueryOptions<GetCurrentUserSettingsQueryVariables>, 'query'>
) => {
	return client.query<GetCurrentUserSettingsQuery>({
		query: GetCurrentUserSettingsDoc,
		...options,
	});
};

export const GetAllUsers = (
	options: Omit<WatchQueryOptions<GetAllUsersQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetAllUsersQuery> & {
		query: ObservableQuery<GetAllUsersQuery, GetAllUsersQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetAllUsersDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetAllUsersQuery> & {
			query: ObservableQuery<GetAllUsersQuery, GetAllUsersQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetAllUsers = (
	options: Omit<QueryOptions<GetAllUsersQueryVariables>, 'query'>
) => {
	return client.query<GetAllUsersQuery>({ query: GetAllUsersDoc, ...options });
};

export const GetUser = (
	options: Omit<WatchQueryOptions<GetUserQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<GetUserQuery> & {
		query: ObservableQuery<GetUserQuery, GetUserQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: GetUserDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<GetUserQuery> & {
			query: ObservableQuery<GetUserQuery, GetUserQueryVariables>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncGetUser = (options: Omit<QueryOptions<GetUserQueryVariables>, 'query'>) => {
	return client.query<GetUserQuery>({ query: GetUserDoc, ...options });
};

export const ValidateMaturityDateUsage = (
	options: Omit<WatchQueryOptions<ValidateMaturityDateUsageQueryVariables>, 'query'>
): Readable<
	ApolloQueryResult<ValidateMaturityDateUsageQuery> & {
		query: ObservableQuery<ValidateMaturityDateUsageQuery, ValidateMaturityDateUsageQueryVariables>;
	}
> => {
	const q = client.watchQuery({
		query: ValidateMaturityDateUsageDoc,
		...options,
	});
	var result = readable<
		ApolloQueryResult<ValidateMaturityDateUsageQuery> & {
			query: ObservableQuery<
				ValidateMaturityDateUsageQuery,
				ValidateMaturityDateUsageQueryVariables
			>;
		}
	>({ data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q }, (set) => {
		q.subscribe((v: any) => {
			set({ ...v, query: q });
		});
	});
	return result;
};

export const AsyncValidateMaturityDateUsage = (
	options: Omit<QueryOptions<ValidateMaturityDateUsageQueryVariables>, 'query'>
) => {
	return client.query<ValidateMaturityDateUsageQuery>({
		query: ValidateMaturityDateUsageDoc,
		...options,
	});
};
